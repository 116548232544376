import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// declare global {
//   interface Window {
//     Trustpilot: any;
//   }
// }
@Component({
  selector: 'diagnostics-emirates-test',
  templateUrl: './emirates-test.component.html',
  styleUrls: ['./emirates-test.component.scss']
})
export class EmiratesTestComponent implements OnInit, AfterContentInit {

  test_and_profile: any;
  index_view: any;
  view_more = true;
  searchText: any;
  customerTestArray: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Covid Tests"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    this.test_and_profile = [
      {
        title: 'Approved Coronavirus PCR Test (Travel Test) Nationwide',
        url: 'emirates-Urgent-PCR-Travel-Test',
        type: 'In Clinic Test',
        eta: '24 hrs',
        bookingID: 29652167,
        price: '80.10',
        about: ['Need RT-PCR Travel Test within 24 hours? Book this test now.', 'Get your result within 24 hours of your appointment time.', 'Results available for download from our website too, once ready.',]
      },
      {
        title: 'Urgent PCR Travel Test - same day results',
        url: 'emirates-Urgent-PCR-Travel-Test-same-day-results',
        type: 'In Clinic Test',
        eta: '24 hrs',
        bookingID: 29652157,
        price: '89.00',
        about: ['Need RT-PCR Travel Test on the same day? Book this test now.', 'Results will be released the same day by midnight (24:00 hours).', 'Get your result on the same day, by midnight.', 'Results available for download from our website too, once ready.']
      },
      {
        title: 'Approved Antigen Test Nationwide',
        url: 'emirates-Covid-19-Antigen-LFT-Test',
        type: 'In Clinic Test',
        bookingID: 29652183,
        eta: '2 hrs',
        price: '44.10',
        about: ['This test is required for international travel in some countries.', 'Book your test', 'Visit clinic for sample collection', 'Includes Medical certificate – emailed within 2 hours'],
      }
    ]

    this.customerTestArray = this.test_and_profile;
  }


  ngAfterContentInit() {
    // setTimeout(() => {
    //   const trustboxRef = document.getElementById('trustbox');
    //   if (window.Trustpilot) {
    //     window.Trustpilot.loadFromElement(trustboxRef);
    //   }
    // }, 500)
  }

  viewmore(index) {
    this.index_view = index;
    this.view_more = false;
  }

  viewless(index) {
    this.view_more = true;
    this.index_view = null;
  }


  bookNow(data) {
    let p = data.bookingID;
    window.open(`${'https://conceptodiagnostics.as.me/schedule.php?appointmentType=' + p}`)
  }

  search(input) {
    if (input !== "") {
      input = input.toLowerCase();
      let array = this.test_and_profile.filter(x => x.title.toLowerCase().includes(input));
      this.customerTestArray = array;
    }
    else {
      this.customerTestArray = this.test_and_profile;
    }
  }

}
