import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Carousel } from 'primeng/carousel';
import { AppConstants } from '../app.constants';
import { GeneralService } from '../general.service';

@Component({
  selector: 'wellness-wellness-home',
  templateUrl: './wellness-home.component.html',
  styleUrls: ['./wellness-home.component.scss'],
  providers: [MessageService]

})
export class WellnessHomeComponent implements OnInit {
  // pageYoffset = 0;
  // tslint:disable-next-line:variable-name
  wellness_details: any = [];
  showCard: any;
  superchargeIngred: any = [];
  showPostCartAdding = false;
  nameOfItemAddedToCart: string;
  char = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  wellness_products: any[];
  cartProducts: any;
  page_loader: boolean = false;


  constructor(private router: Router, private generalService: GeneralService, private messageService: MessageService) {
    Carousel.prototype.onTouchMove = () => { };
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Wellness"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    this.wellness_details = [
      {
        id: 0,
        title: 'Mindfully crafted Premium Essential Nutrition for a healthier you',
        description: ['Because there is no substitute to a good health and a sound immunity.', 'Because you should have better control over your health.', 'Because your health is important to us.'],
        button: 'EXPLORE',
        image: '../../assets/images/wellness/wellness-banner.png'
      },
      {
        id: 1,
        title: 'Our Wellness Products',
        description: ['Multivitamins consciously crafted to deliver highly potent toxin free nutrients with precision into your body. The ingredients for the products are sustainably sourced.'],
        button: 'EXPLORE',
        image: [
          {
            name: 'Wellness Queen',
            picture: '../../assets/images/wellness/WQ.png'
          },
          {
            name: 'Wellness King',
            picture: '../../assets/images/wellness/WK.png'
          },
          {
            name: 'Advanced Immunity',
            picture: '../../assets/images/wellness/AI.png'
          }
        ]
      },
      // {
      //   id: 2,
      //   title: 'Vital Body Tests',
      //   description: 'Our vital body test helps you identify health issues and achieve your fitness and well-being goals. Our mission is to make it easy and convenient to keep track of your health and to proactively check and manage your well-being with ease.',
      //   button: 'EXPLORE',
      //   image:'../../assets/images/wellness/vital-banner.png'
      // }
    ];

    this.superchargeIngred = [
      {
        name: 'Curcumin-95',
        class: 'Ingredone',
        image: '../../assets/images/wellness/curcumine-95.jpg',
        contents: ['Boosts immunity, reduces inflammation, anti-viral properties', 'Complementary treatment for PCOS', 'Helps in the treatment of Endometriosis', 'Reduces severity of Pre-Menstrual Syndrome']
      },
      {
        name: 'Ashwagandha',
        class: 'Ingredtwo',
        image: '../../assets/images/wellness/ashwagandha.jpg',
        contents: ['Improves cell-mediated immunity', 'Protect against cellular damage', 'Increase white blood cells', 'Anti-stress effect']
      },
      {
        name: 'Giloy',
        class: 'Ingrethree',
        image: '../../assets/images/wellness/giloy.jpg',
        contents: ['Helps in Body’s natural defense mechanisms', 'Improves Digestion', 'Treats ailment in Respiratory System']
      },
      {
        name: 'Holy Basil',
        class: 'Ingredfour',
        image: '../../assets/images/wellness/holybasil.jpg',
        contents: ['Enhanced Immune response', 'Antidepressant and anti-anxiety properties', 'Lowers Blood sugar']
      },
      {
        name: 'Coenzyme-Q10',
        class: 'Ingredfive',
        image: '../../assets/images/wellness/coenzyme.jpg',
        contents: ['Improves fertility, improves immunity, improves exercise performance', 'Reduce and treat heart conditions', 'Prevents Migraine', 'Help lower blood pressure']
      },
      {
        name: 'Lycopene',
        class: 'Ingredsix',
        image: '../../assets/images/wellness/lycopene.jpg',
        contents: ['Cardiovascular disease prevention', 'Slow down growth of breast cancer', 'Protection against damaging effects of the sun']
      },
    ]

    this.wellness_products = [
      {
        name: 'Wellness Queen',
        picture: '../../assets/images/wellness/WQ.png'
      },
      {
        name: 'Wellness King',
        picture: '../../assets/images/wellness/WK.png'
      },
      {
        name: 'Advanced Immunity',
        picture: '../../assets/images/wellness/AI.png'
      }
    ]

    this.getCartDetailsByUser()
  }

  superCharge(index) {
    if (this.showCard === index) {
      this.showCard = undefined;
    } else {

      this.showCard = index;
    }
  }

  hidecard(index){
    this.showCard=false;
  }

  learnMore(type) {
    if (type === 'Wellness Queen') {
      this.router.navigate(['wellness/multivitamin'], { queryParams: { multivitamin: 'Wellness-Queen' } });
    }
    if (type === 'Wellness King') {
      this.router.navigate(['wellness/multivitamin'], { queryParams: { multivitamin: 'Wellness-King' } });
    }
    if (type === 'Advanced Immunity') {
      this.router.navigate(['wellness/multivitamin'], { queryParams: { multivitamin: 'Advanced-Immunity' } });
    }
    if (type === 'Vital Tests') {
      this.router.navigate(['wellness/multivitamin'], { queryParams: { multivitamin: 'vital-tests' } });
    }
  }

  buyNow(type) {
    // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Added to Cart' });
    this.generalService.getProductByName(type).subscribe((res) => {
      let obj = {};
      let existing_user = this.generalService.getCookie('coc_user')
      let temp_user = this.generalService.getCookie('coc_temp_user')
      if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
        this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) + 1, 2)
      } else {
        this.generalService.setCookie('coc_cart_details', 1, 2)
      }

      if (existing_user) {
        obj = {
          id: 0,
          product_id: res[0].id,
          quantity: 1,
          sub_total: res[0].price,
          stage: 'added to cart',
          created_at: new Date(),
          temp_user_id: '',
          user_id: existing_user,
        }
      } else if (!existing_user && temp_user) {
        obj = {
          id: 0,
          product_id: res[0].id,
          quantity: 1,
          sub_total: res[0].price,
          stage: 'added to cart',
          created_at: new Date(),
          temp_user_id: temp_user,
          user_id: '',
        }
      } else {
        let temp_id = this.randomString(16, this.char);
        this.generalService.setCookie('coc_temp_user', temp_id, 2)
        obj = {
          id: 0,
          product_id: res[0].id,
          quantity: 1,
          sub_total: res[0].price,
          stage: 'added to cart',
          created_at: new Date(),
          temp_user_id: temp_id,
          user_id: '',
        }
      }
      if (AppConstants.GA4 === true) {
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "add_to_cart",
          ecommerce: {
            items: [
              {
                item_id: res[0].id,
                item_name: res[0].title,
                affiliation: "Concepto Wellness Store",
                coupon: "",
                currency: res[0].currency,
                discount: 0,
                index: 0,
                item_brand: "Concepto",
                item_category: res[0].productCategory.category,
                item_category2: "",
                item_category3: "",
                item_category4: "",
                item_category5: "",
                item_list_id: res[0].id,
                item_list_name: res[0].title,
                item_variant: "",
                location_id: "",
                price: res[0].price,
                quantity: 1
              }
            ]
          }
        });
      }
      this.generalService.addToCart(obj).subscribe((res) => {
        this.generalService.setCookie('coc_active_cart', true, 2);
        // window.location.reload();
        this.showPostCartAdding = true;
        this.nameOfItemAddedToCart = type;
        console.log(this.nameOfItemAddedToCart, "product name")
        this.getCartDetailsByUser()
        if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
          this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) + 1, 2)
        } else {
          this.generalService.setCookie('coc_cart_details', 1, 2)
        }
      })
    })
  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  explore(type) {
    if (type === 'Our Wellness Products') {
      document.getElementById(`multivitamins-view`).scrollIntoView({ behavior: "smooth" })
    }
    if (type === 'Vital Body Tests') {
      document.getElementById(`vital-body-test-view`).scrollIntoView({ behavior: "smooth" })
    }
    if (type === 'Mindfully crafted Premium Essential Nutrition for a healthier you') {
      this.router.navigate(['wellness/products'])
    }
  }

  getdata() {
    this.showPostCartAdding = false;
  }

  getCartDetailsByUser() {
    this.page_loader = true;
    let user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (user) {
      let type = 'email';
      this.generalService.getCartByUser(type, user).subscribe(res => {
        this.cartProducts = res;
        let wkCount = 0;
        let wqCount = 0;
        let aiCount = 0;
        this.cartProducts.forEach(element => {
          this.wellness_products.forEach(el => {
            if (element.stage === "added to cart") {
              if (element.product.name === el.name) {
                el["cart_details"] = element
              }
              if (element.product.name === "Wellness Queen") {
                wqCount = wqCount + 1;
              }
              if (element.product.name === "Wellness King") {
                wkCount = wkCount + 1;
              }
              if (element.product.name === "Advanced Immunity") {
                aiCount = aiCount + 1;
              }
            }
          })
        });
        if (wqCount === 0) {
          this.wellness_products[0].cart_details = false
        }
        if (wkCount === 0) {
          this.wellness_products[1].cart_details = false
        }
        if (aiCount === 0) {
          this.wellness_products[2].cart_details = false
        }
        this.page_loader = false;
      })
    } else if (temp_user && !user) {
      let type = 'temp';
      this.generalService.getCartByUser(type, temp_user).subscribe(res => {
        this.cartProducts = res
        let wkCount = 0;
        let wqCount = 0;
        let aiCount = 0;
        this.cartProducts.forEach(element => {
          this.wellness_products.forEach(el => {
            if (element.stage === "added to cart") {
              if (element.product.name === el.name) {
                el["cart_details"] = element
              }
              if (element.product.name === "Wellness Queen") {
                wqCount = wqCount + 1;
              }
              if (element.product.name === "Wellness King") {
                wkCount = wkCount + 1;
              }
              if (element.product.name === "Advanced Immunity") {
                aiCount = aiCount + 1;
              }
            }
          })
        });
        if (wqCount === 0) {
          this.wellness_products[0].cart_details = false
        }
        if (wkCount === 0) {
          this.wellness_products[1].cart_details = false
        }
        if (aiCount === 0) {
          this.wellness_products[2].cart_details = false
        }
        this.page_loader = false;
      })
    }else{
      this.page_loader = false;
    }
  }

  addItem(id) {
    this.page_loader = true;
    this.cartProducts.forEach(element => {
      if (id === element.id) {
        if (element.quantity > 9) {
          this.messageService.add({ severity: 'error', detail: 'Maximum order limit reached for this item' });
          this.page_loader = false;
        } else {
          element.quantity = element.quantity + 1
          this.updateCart(element)
        }
      }
    })
  }

  deleteItem(id) {
    this.page_loader = true;
    this.cartProducts.forEach(element => {
      if (id === element.id && element.quantity > 1) {
        element.quantity = element.quantity - 1
        this.updateCart(element)
      }
    })
  }

  removeItem(product, id) {
    this.page_loader = true;
    product.stage = "removed";
    this.updateCart(product)
    this.wellness_products[id].cart_details = false
    // setTimeout(() => {
    //   if (this.cartProducts.length === 0) {
    //     this.generalService.clearCartCookie()
    //     window.location.reload()
    //   }
    // }, 1000)
  }

  updateCart(element) {
    this.page_loader = true;
    let body = {
      id: element.id,
      product_id: element.product_id,
      user_id: element.user_id,
      quantity: element.quantity,
      sub_total: element.sub_total,
      stage: element.stage,
      temp_user_id: element.temp_user_id,
      created_at: element.created_at
    }
    this.generalService.cartUpdate(body).subscribe(res => {
      this.getCartDetailsByUser()
      this.page_loader = false
    })
  }

}
