import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aesthetics-page',
  templateUrl: './aesthetics-page.component.html',
  styleUrls: ['./aesthetics-page.component.scss']
})
export class AestheticsPageComponent implements OnInit {
  glamBoxImage: any = [];
  sectionTwoData: any = [];
  sectionThreeData: any = [];
  sectionFiveData: any = [];
  sectionSvnData: any = [];
  sectionEightData: any = [];
  sectionNineData: any = [];
  profilesList: any = [];
  sectionFourData: any = [];
  constructor(private router: Router,) {


    this.sectionTwoData = [{
      "title": "Why",
      "package": "Concepto Aesthetics?",
      "description": "Concepto Aesthetics is led by qualified nurses with decades of experience working with NHS and private sectors. They are well-versed in the science behind these procedures and their application."
    }];

    this.sectionThreeData = [
      {
        "image": "../../assets/images/aesth/why-aesthetics-1.png",
        "title": "Treatments by highly qualified experienced nurses"
      },
      {
        "image": "../../assets/images/aesth/why-aesthetics-2.png",
        "title": "Affordable Service"
      },
      {
        "image": "../../assets/images/aesth/why-aesthetics-3.png",
        "title": "High quality products"
      }
    ];

    this.sectionFiveData = [
      {
        "title": "Fillers",
        "image": "../../assets/images/aesth/fillers.png",
        "basic_details": [
          {
            "name": "Lips",
            "price": "195.00",
            "link": "53184492"
          },
          {
            "name": "Cheeks",
            "price": "350.00",
            "link": "53184505"
          },
          {
            "name": "Jawline",
            "price": "300.00",
            "link": "53184516"
          },
          {
            "name": "Smokers' lines",
            "price": "150.00",
            "link": "53184523"
          },
          {
            "name": "Rhinoplasty",
            "price": "300.00",
            "link": "53184536"
          }
        ],
        "advance_details": [
          {
            "name": "Non-surgical rhinoplasty",
            "price": "499.00"
          }
        ],
        "area_include": "Fill and reduce wrinkles for regained definition and volume.",
        "btn_name": "Book Now"
      },
      {
        "title": "Anti-wrinkle",
        "image": "../../assets/images/aesth/wrinkle.png",
        "basic_details": [
          {
            "name": "3 areas (forehead lines, frown lines and crow's feet)",
            "price": "200.00",
            "link": "52998436"
          },
          {
            "name": "Brow lift",
            "price": "100.00",
            "link": "53184389"
          },
          {
            "name": "Slimming of the nose",
            "price": "100.00",
            "link": "53184402"
          },
          {
            "name": "Relaxing Gummy Lines",
            "price": "100.00",
            "link": "53184415"
          },
          {
            "name": "Plasma neckline, Deep necklines",
            "price": "300.00",
            "link": "53184428"
          }, {
            "name": "Face lifting and slimming",
            "price": "350.00",
            "link": "53184441"
          },
          {
            "name": "Excess sweating for armpits",
            "price": "300.00",
            "link": "53184464"
          }
        ],
        "advance_details": [
          {
            "name": "Lower Face",
            "price": "299.00"
          },
          {
            "name": "Excess sweating for armpits",
            "price": "349.00"
          }
        ],
        "area_include": "Smoothen your skin, for an even, natural and youthful look.",
        "btn_name": "Book Now"
      },
      {
        "title": "Beauty Treatments",
        "image": "../../assets/images/aesth/beauty.png",
        "basic_details": [
          {
            "name": "Lash Lift and Tint",
            "price": "45.00",
            "link": "55038588"
          },
          {
            "name": "HD Brows",
            "price": "30.00",
            "link": "55038593"
          },
          {
            "name": "Brow Lamination (Brow sculpting)",
            "price": "45.00",
            "link": "55038602"
          },
          {
            "name": "Skin Brightening Facial",
            "price": "30.00",
            "link": "55038608"
          },
          {
            "name": "Skin Brightening Facial with blackhead extraction",
            "price": "45.00",
            "link": "55038615"
          }
        ],
        "area_include": "Look stunning with our refreshing and rejuvenating beauty treatments.",
        "btn_name": "Book Now"
      }

    ];

    this.sectionSvnData = [
      {
        "title": "Experience",
        "image": "../../assets/images/aesth/experience.png",
        "description": "Concepto Aesthetics is owned by Concepto Diagnostics Ltd., a trusted and diverse British healthcare brand with a decade of experience in serving the preventative healthcare needs of customers across Diagnostics, Wellness, Genomics and Nutrition."
      },
      {
        "title": "Award-Winning",
        "image": "../../assets/images/aesth/award-winning.png",
        "description": "It is an award-winning, employee-owned company that provides experienced, reliable and affordable care for thousands of patients at its CQC registered nationwide clinics. Concepto has an in-house UKAS accredited diagnostics lab and state-of-the-art Genomics lab."
      }

    ];

    this.sectionFourData = [{
      "title": "Our",
      "package": "Offerings",
      "description": "Our services are designed to create subtle, smooth results while respecting the natural contours of your face. Our experienced practitioners treat wrinkles and restore facial volume in a comprehensive approach that maintains your uniqueness."
    }];

    this.sectionNineData = [
      {
        "image": "../../assets/images/aesth/elina.png",
        "name": "Eileen Flatley",
        "description": "Registered with the NMC for more than 25 years as a Nurse and Midwife. Extensive experience working in the NHS and private sectors. Registered CQC manager."
      },
      {
        "image": "../../assets/images/aesth/sha.png",
        "name": "Shannaz Wahid",
        "description": "A Senior Mental Health Practitioner (28 years' experience), offering Aesthetic services for the last 3 years."
      },
      {
        "image": "../../assets/images/aesth/Sally.png",
        "name": "Sally",
        "description": "Registered with the NMC for more than 25 years as a Nurse and Midwife. Extensive experience working in the NHS."
      }
    ];

    this.sectionEightData = [{
      "title": "Why to Choose",
      "package": "US?",
      "description": "Unlike most aesthetic medicine clinics, Concepto prioritizes patient smiles over total number of units injected. Wherever possible, we price per area to reduce any confusion over anticipated treatment costs. All treatments costs are discussed during your consultation, so there are never any surprises."
    }]


  }


  ngOnInit(): void {


    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if (menuitem[i]["outerText"] === "Aesthetics") {
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)

  }

  enquireNow(type) {
    window.scrollTo(0, 0)
    if (type === 'Fillers') {
      setTimeout(() => {
        this.router.navigate(['aesthetics/offerings'], { queryParams: { product: 'Fillers' } })
      }, 400);
    } else if (type === 'Anti-wrinkle') {
      setTimeout(() => {
        this.router.navigate(['aesthetics/offerings'], { queryParams: { product: 'Anti-wrinkle' } });
      }, 400);
    }else if (type === 'Beauty Treatments') {
      setTimeout(() => {
        this.router.navigate(['aesthetics/offerings'], { queryParams: { product: 'Beauty-Treatments' } });
      }, 400);
    }
  }
}
