import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import * as moment from 'moment';
import { sha256 } from 'js-sha256';
import { MessageService } from 'primeng/api';
import { AppConstants } from '../app.constants';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  card_products: any[];
  addressess: any[];
  display = false;
  city: string;
  no_of_items: any;
  total_price: any;
  total_amount: any;
  vat: any;
  vatpercent = '0';
  promocode = '';
  cartDiscount = '0.00';
  delivery_charges = '0.00';
  promocodesList = [{
    'code': 'blackfriday20',
    'discount': '20'
  }]
  isNotValidPromoCode = false;
  userdata: any = []

  newaddress!: FormGroup;
  submitted = false;
  submittedGuestCheckout = false;
  openNewAddresstab = false;
  enableEdit: any;

  editFullName: any;
  editAddress_line_1: any;
  editCity: any;
  editAddress_line_2: any;
  editCountry: any;
  editZipcode: any;
  isUserLogedIn = false;
  timeout: any = null;
  page_loader = false;
  invalidZipcode = false;
  discountPercentage = 0;
  showInvalidDeliveryAddress = false;
  total_price_display = '0.00';
  emptyzipcode = true;
  removedProducts: any[];
  showLoginPopup = false;
  guestcheckoutPopUp = false;
  registerForm!: FormGroup;
  emailAlreadyExist = false;

  invalidname: boolean = false;
  invalidAddOne: boolean = false;
  invalidAddTwo: boolean = false;
  invalidcity: boolean = false;
  invalidcountry: boolean = false;
  constructor(private generalService: GeneralService, private router: Router, private formBuilder: FormBuilder, private messageService: MessageService) {
    this.registerForm = this.formBuilder.group({
      email: [{ value: '', disabled: false }, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      fullname: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.pattern("^[a-zA-Z.]+([a-zA-Z. ]+)*$")]],
      phoneNumber: [{ value: '', disabled: false }, [Validators.required, Validators.pattern("^[0-9]{10,13}$")]],
      addressLineOne: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.pattern("^[a-zA-Z0-9\s/!,.@#$%^&*()]+([a-zA-Z0-9\s/!,.@#$%^&*() ]+)*$")]],
      addressLineTwo: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.pattern("^[a-zA-Z0-9\s/!,.@#$%^&*()]+([a-zA-Z0-9\s/!,.@#$%^&*() ]+)*$")]],
      city: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.pattern("^[a-zA-Z]+([a-zA-Z ]+)*$")]],
      country: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.pattern("^[a-zA-Z]+([a-zA-Z ]+)*$")]],
      zipcode: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1),]],
    });
  }

  ngOnInit(): void {
    let cartClass = document.getElementsByClassName("cartToggle");
    let cartFlex = document.getElementsByClassName("cartToggleFlex");
    cartFlex[0].classList.add("justify-content-center");
    cartClass[0].classList.add("d-none");
    cartClass[1].classList.add("d-none");
    this.getuserdetails();
    this.getCartDetailsByUser()

    this.newaddress = this.formBuilder.group({
      fullname: ['', [Validators.required, Validators.pattern("^[a-zA-Z.]+([a-zA-Z. ]+)*$")]],
      address_line_1: ['', [Validators.required, Validators.minLength(4), Validators.pattern("^[a-zA-Z0-9\s/!,.@#$%^&*()]+([a-zA-Z0-9\s/!,.@#$%^&*() ]+)*$")]],
      city: ['', [Validators.required, Validators.pattern("^[a-zA-Z]+([a-zA-Z ]+)*$")]],
      address_line_2: ['', [Validators.required, Validators.minLength(4), Validators.pattern("^[a-zA-Z0-9\s/!,.@#$%^&*()]+([a-zA-Z0-9\s/!,.@#$%^&*() ]+)*$")]],
      country: ['', [Validators.required, Validators.pattern("^[a-zA-Z]+([a-zA-Z ]+)*$")]],
      zipcode: ['', [Validators.required, Validators.minLength(4)]],

    });
  }

  openAddresspanel() {
    this.display = true;
  }

  getCartDetailsByUser() {
    let user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (user) {
      let type = 'email';
      this.generalService.getCartByUser(type, user).subscribe(res => {
        let response = res
        this.card_products = [];
        this.removedProducts = []
        let items_no = 0;
        response.forEach(element => {
          element.product.extra_description_one = JSON.parse(element.product.extra_description_one)[0].detail;
          if (element.stage === "added to cart") {
            items_no = items_no + element.quantity;
            element.product.pictures = JSON.parse(element.product.pictures).image[0].split(':')[1]
            let temp = this.card_products.find(x => x.product_id === element.product_id);
            let indexs = this.card_products.findIndex(x => x.product_id === element.product_id)
            if (temp === undefined) {
              this.card_products.push(element)
            }
            else {
              if (indexs >= 0)
                this.card_products[indexs].quantity = this.card_products[indexs].quantity + element.quantity;
              element.stage = "removed";
              this.removedProducts.push(element)
            }
          }
        })
        if (this.removedProducts.length > 0) {
          this.card_products.forEach(element => {
            this.checkforDuplicate(element)
          })
          this.removedProducts.forEach(element => {
            this.checkforDuplicate(element)
          })
          this.getCartDetailsByUser()
        }

        this.no_of_items = items_no;
        this.totalPrice()
      },
        err => {
          this.totalPrice()
        })
    } else if (temp_user && !user) {
      let type = 'temp';
      this.generalService.getCartByUser(type, temp_user).subscribe(res => {
        this.card_products = [];
        this.removedProducts = []
        let items_no = 0;
        res.forEach(element => {
          element.product.extra_description_one = JSON.parse(element.product.extra_description_one)[0].detail;
          if (element.stage === "added to cart") {
            items_no = items_no + element.quantity;
            element.product.pictures = JSON.parse(element.product.pictures).image[0].split(':')[1]
            let temp = this.card_products.find(x => x.product_id === element.product_id);
            let indexs = this.card_products.findIndex(x => x.product_id === element.product_id)
            if (temp === undefined) {
              this.card_products.push(element)
            }
            else {
              if (indexs >= 0)
                this.card_products[indexs].quantity = this.card_products[indexs].quantity + element.quantity;
              element.stage = "removed";
              this.removedProducts.push(element)
            }
          }
        })
        if (this.removedProducts.length > 0) {
          this.card_products.forEach(element => {
            this.checkforDuplicate(element)
          })
          this.removedProducts.forEach(element => {
            this.checkforDuplicate(element)
          })
          this.getCartDetailsByUser()
        }
        this.no_of_items = items_no;
        this.totalPrice()
      },
        err => {
          this.totalPrice()
        })
    } else {
      this.totalPrice()
    }

    setTimeout(() => {
      if ((user || temp_user) && this.card_products.length > 0) {

        let productarr = []
        this.card_products.forEach((element, i) => {
          let obj = {
            item_id: element.product.id,
            item_name: element.product.name,
            affiliation: "Concepto Wellness Store",
            coupon: "",
            currency: element.product.currency,
            discount: this.cartDiscount,
            index: i,
            item_brand: "Concepto",
            item_category: "Multivitamins",
            item_category2: "",
            item_category3: "",
            item_category4: "",
            item_category5: "",
            item_list_id: element.product.id,
            item_list_name: element.product.name,
            item_variant: "",
            location_id: "",
            price: element.product.price,
            quantity: element.quantity
          }
          productarr.push(obj)
          if (i === this.card_products.length - 1) {
            if (AppConstants.GA4 === true) {
              window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
              window.dataLayer.push({
                event: "view_cart",
                ecommerce: {
                  currency: "GBP",
                  value: this.total_amount,
                  items: productarr
                }
              });
            }
          }
        });
      }

    }, 3000);

  }

  checkforDuplicate(element) {
    let body = {
      id: element.id,
      product_id: element.product_id,
      user_id: element.user_id,
      quantity: element.quantity,
      sub_total: element.sub_total,
      stage: element.stage,
      temp_user_id: element.temp_user_id,
      created_at: element.created_at
    }
    this.generalService.cartUpdate(body).subscribe(res => {
      this.page_loader = false
    })
  }


  addItem(id) {
    this.card_products.forEach(element => {
      if (id === element.id) {
        if (element.quantity > 9) {
          this.messageService.add({ severity: 'error', detail: 'Maximum order limit reached for this item' });
        } else {
          if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
            this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) + 1, 2)
          } else {
            this.generalService.setCookie('coc_cart_details', 1, 2)
          }
          element.quantity = element.quantity + 1
          this.updateCart(element)
        }
      }
    })
  }

  deleteItem(id) {
    this.card_products.forEach(element => {
      if (id === element.id && element.quantity > 1) {
        element.quantity = element.quantity - 1
        this.updateCart(element)
        if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
          this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) - 1, 2)
        } else {
          this.generalService.setCookie('coc_cart_details', 1, 2)
        }
      }
    })
  }

  removeItem(product) {
    // this.card_products.splice(id, 1);
    product.stage = "removed";
    this.updateCart(product)
    setTimeout(() => {
      if (this.card_products.length === 0) {
        this.generalService.clearCartCookie()
        window.location.reload()
      }
    }, 1000)

    // this.no_of_items = this.card_products.length;
    // this.totalPrice();

  }

  totalPrice() {
    this.page_loader = true;
    if (this.no_of_items > 0) {
      this.total_price = 0;
      this.total_amount = 0;
      this.vat = 0;
      this.card_products.forEach(element => {
        this.total_price = this.total_price + (element.product.price * element.quantity);
      })
      this.total_price_display = this.total_price.toFixed(2)
      let tempVat = parseFloat(`0.${this.vatpercent}`);
      this.vat = (this.total_price * tempVat).toFixed(2)
      let vatValue = parseFloat(this.vat)
      this.total_amount = this.total_price + vatValue;
      let totalBill = this.total_amount
      this.total_amount = totalBill - (this.total_amount * this.discountPercentage);
      let discountedAmount = ((totalBill - this.total_amount)).toFixed(2)
      this.cartDiscount = parseFloat(discountedAmount).toFixed(2);
      this.total_amount = this.total_amount.toFixed(2)

    } else {
      this.vat = '0.00';
      this.total_price = '0.00';
      this.total_amount = '0.00';
      this.total_price_display = '0.00',
        this.cartDiscount = '0.00'

    }
    this.page_loader = false;
  }

  updateCart(element) {
    this.page_loader = true;
    let body = {
      id: element.id,
      product_id: element.product_id,
      user_id: element.user_id,
      quantity: element.quantity,
      sub_total: element.sub_total,
      stage: element.stage,
      temp_user_id: element.temp_user_id,
      created_at: element.created_at
    }
    this.generalService.cartUpdate(body).subscribe(res => {
      this.getCartDetailsByUser()
      this.page_loader = false
    })
  }

  // Manage Addresssss

  get f(): { [key: string]: AbstractControl } {
    return this.newaddress.controls;
  }

  getuserdetails() {
    let data = this.generalService.getCookie('coc_user')
    if (data) {
      this.generalService.userByEmail(data).subscribe(res => {
        if (res) {
          this.userdata = res;
          this.addressess = JSON.parse(this.userdata.other_address)
          this.isUserLogedIn = true;
          if (this.addressess !== undefined) {
            if (this.userdata.zipcode === "") {
              this.makeDefault(0)
            }
          }
        }
      })
    }
  }

  // Delete Address
  deleteAddress(index, object) {
    if (object.default) {
      this.addressess.splice(index, 1);
      console.log(this.addressess)
      this.makeDefault(0)
    }
    else {
      this.addressess.splice(index, 1);
      this.userdata.other_address = JSON.stringify(this.addressess)
      this.enableEdit = false;
      this.updateUserData();
    }

  }

  // Default Address
  makeDefault(id) {
    this.addressess.forEach((element, i) => {
      if (i === id) {
        element.default = true;
        this.userdata.city = element.city;
        this.userdata.country = element.country;
        this.userdata.address_line_2 = element.address_line_2;
        this.userdata.address_line_1 = element.address_line_1;
        this.userdata.zipcode = element.zipcode;

      } else {
        element.default = false;
      }
    })

    this.userdata.other_address = JSON.stringify(this.addressess)
    this.updateUserData();
  }


  // ADD NEW ADDRESS
  addnewAddress() {
    this.submitted = true;
    let otherAddress = JSON.parse(this.userdata.other_address)
    if (this.newaddress.invalid) {
      return;
    } else {
      if (this.invalidZipcode === false) {
        let defaults = false;
        if (this.userdata.other_address === null || this.userdata.other_address.length === 0) {
          defaults = true
          otherAddress = [
            {
              name: this.newaddress.controls.fullname.value,
              address_line_1: this.newaddress.controls.address_line_1.value,
              city: this.newaddress.controls.city.value,
              zipcode: this.newaddress.controls.zipcode.value,
              address_line_2: this.newaddress.controls.address_line_2.value,
              country: this.newaddress.controls.country.value,
              default: defaults
            }
          ]
        }
        else {
          defaults = false
          otherAddress.push(
            {
              name: this.newaddress.controls.fullname.value,
              address_line_1: this.newaddress.controls.address_line_1.value,
              city: this.newaddress.controls.city.value,
              zipcode: this.newaddress.controls.zipcode.value,
              address_line_2: this.newaddress.controls.address_line_2.value,
              country: this.newaddress.controls.country.value,
              default: defaults
            }
          )
        }

        this.userdata.other_address = JSON.stringify(otherAddress)
        this.updateUserData()
      }


    }
  }

  // Edit Addresssss
  editAddress(index, object) {
    this.enableEdit = index;
    this.editFullName = object.name;
    this.editAddress_line_1 = object.address_line_1;
    this.editCity = object.city;
    this.editAddress_line_2 = object.address_line_2;
    this.editCountry = object.country;
    this.editZipcode = object.zipcode;
  }


  // UPDATE USER DATA API
  updateUserData() {
    this.generalService.updateUser(this.userdata).subscribe(res => {
      this.openNewAddresstab = false;
      this.newaddress.reset()
      this.submitted = false;
      this.getuserdetails()
    })
  }

  openNewAdress() {
    this.openNewAddresstab = true;
    setTimeout(() => {
      document.getElementById(`addNewAddressForm`).scrollIntoView({ behavior: "smooth" })
    }, 500)
  }

  cancelNewAddress() {
    this.openNewAddresstab = false;
  }

  saveEditAddress(id, object) {
    let validatorOne = new RegExp("^[a-zA-Z.]+([a-zA-Z. ]+)*$");
    let validatorTwo = new RegExp("^[a-zA-Z0-9\s/!,.@#$%^&*()]+([a-zA-Z0-9\s/!,.@#$%^&*() ]+)*$");
    let validatorThree = new RegExp("^[a-zA-Z]+([a-zA-Z ]+)*$");
    if (!validatorOne.test(this.editFullName)) {
      this.invalidname = true
    }
    if (!validatorTwo.test(this.editAddress_line_1) || this.editAddress_line_1.length < 3) {
      this.invalidAddOne = true;
    }
    if (!validatorTwo.test(this.editAddress_line_2) || this.editAddress_line_2.length < 3) {
      this.invalidAddTwo = true
    }
    if (!validatorThree.test(this.editCity)) {
      this.invalidcity = true;
    }
    if (!validatorThree.test(this.editCountry)) {
      this.invalidcountry = true;
    }
    if (!this.invalidZipcode && validatorOne.test(this.editFullName) && (validatorTwo.test(this.editAddress_line_1) && (this.editAddress_line_1.length > 3)) && (validatorTwo.test(this.editAddress_line_2) && this.editAddress_line_2.length > 3) && validatorThree.test(this.editCity) && validatorThree.test(this.editCountry)) {
      this.enableEdit = null;
      this.invalidname = false;
      this.invalidAddOne = false;
      this.invalidAddTwo = false;
      this.invalidcity = false;
      this.invalidcountry = false;
      this.addressess.forEach((element, i) => {
        if (i === id) {
          element.name = this.editFullName;
          element.city = this.editCity;
          element.country = this.editCountry;
          element.address_line_2 = this.editAddress_line_2;
          element.address_line_1 = this.editAddress_line_1;
          element.zipcode = this.editZipcode;
        }
      })
      if (object.default) {
        this.makeDefault(id)
      } else {
        this.userdata.other_address = JSON.stringify(this.addressess)
        this.updateUserData();
      }
    }
  }

  cancelEditAddress() {
    this.enableEdit = null;
  }

  applyPromocode() {
    this.promocodesList.forEach(element => {
      if (element.code === this.promocode.toLowerCase()) {
        this.discountPercentage = parseFloat(`0.${element.discount}`);
        this.totalPrice()
        this.isNotValidPromoCode = false;
      } else {
        this.isNotValidPromoCode = true;
      }
    });
  }

  async checkout() {
    this.showLoginPopup = false;
    this.page_loader = true;
    let user = this.generalService.getCookie('coc_user');
    let productarr = []
    //for local testing
    // let obj1 = {id: 74}
    //     this.generalService.tempSuccus(obj1).subscribe(resLink => {
    //       console.log(resLink)
    //     })
    if (user) {
      this.generalService.userByEmail(user).subscribe(res => {
        this.userdata = res;
        if (this.addressess && this.addressess.length > 0) {
          this.showInvalidDeliveryAddress = false;
          if (this.card_products.length > 0) {

            //gtag
            this.card_products.forEach((element, i) => {
              let obj = {
                item_id: element.product.id,
                item_name: element.product.name,
                affiliation: "Concepto Wellness Store",
                coupon: "",
                currency: element.product.currency,
                discount: this.cartDiscount,
                index: i,
                item_brand: "Concepto",
                item_category: "Multivitamins",
                item_category2: "",
                item_category3: "",
                item_category4: "",
                item_category5: "",
                item_list_id: element.product.id,
                item_list_name: element.product.name,
                item_variant: "",
                location_id: "",
                price: element.product.price,
                quantity: element.quantity
              }
              productarr.push(obj)
              if (i === this.card_products.length - 1) {
                if (AppConstants.GA4 === true) {
                  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                  window.dataLayer.push({
                    event: "begin_checkout",
                    ecommerce: {
                      items: productarr
                    }
                  });
                }
              }
            });
            //gtag


            let arr = []
            this.card_products.forEach((element, i) => {
              let objProducts = {
                'product_id': element.product_id,
                'cart_id': element.id,
                'quantity': element.quantity
              }
              arr.push(objProducts);
              console.log(this.userdata)
              if (this.card_products.length - 1 === i) {
                // this.total_amount = 0.5
                let obj = {
                  'total_amount': this.total_amount,
                  'vat': this.vat,
                  'delivery_charges': this.delivery_charges.toString(),
                  'discount': this.cartDiscount,
                  'promocode': this.promocode,
                  'sub_total_amout': this.total_price.toString(),
                  'products': JSON.stringify(arr),
                  'status': 'payment pending',
                  'createdDate': new Date(),
                  'user': this.userdata.id,
                  'address': `${this.userdata.address_line_1}, ${this.userdata.address_line_2}, ${this.userdata.city}, ${this.userdata.country}, ${this.userdata.zipcode}`,
                  'oid': '',
                  'txndate_processed': new Date(),
                  'chargetotal': '',
                  'tracking_id': ''
                }
                this.generalService.addOrder(obj).subscribe(res => {
                  if (res['id']) {
                    let customerid = res['id']
                    let storeID = AppConstants.storeId
                    let sharedS = AppConstants.sharedS
                    var today = new Date();
                    let formatedDate = moment().format('YYYY:MM:DD-HH:mm:ss')
                    let totalChanged = this.total_amount.toString();
                    let currencyCode = "826";
                    let stringToBeHashed = `${storeID}${formatedDate}${totalChanged}${currencyCode}${sharedS}`;
                    let stringToHex = this.toHex(stringToBeHashed)
                    let hash = sha256.hex(stringToHex);
                    let obj1 = {
                      "txntype": "sale",
                      "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
                      "txndatetime": formatedDate,
                      "hash_algorithm": "SHA256",
                      "hash": hash,
                      "storename": storeID,
                      "checkoutoption": "combinedpage",
                      "customerid": `order-${customerid}`,
                      "mode": "payonly",
                      "chargetotal": totalChanged,
                      "currency": currencyCode,
                      "authenticateTransaction": 'true'
                    }
                    console.log(obj1)
                    //payment link generator
                    this.generalService.makePayment(obj1).subscribe(resLink => {
                      if (resLink['data']) {
                        document.location.href = resLink['data'];
                        this.page_loader = false
                      } else {
                        //no payment link error
                        this.page_loader = false
                      }
                    })

                  } else {
                    //invalid cart
                    this.page_loader = false
                  }
                })
              }
            });

          } else {
            //nothing in cart
            this.page_loader = false
          }
        } else {
          this.page_loader = false;
          this.showInvalidDeliveryAddress = true;
        }
      })


    } else {
      this.page_loader = false;
      this.showLoginPopup = true;
    }


  }

  get guestsignup(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  checkoutPopUp(type) {
    if (type === 'signup') {
      this.router.navigate(['/sign-up'])
    } else if (type === 'guest') {
      this.showLoginPopup = false;
      this.guestcheckoutPopUp = true;
    }
  }

  completeGuestsignUp() {
    this.submittedGuestCheckout = true;
    if (this.registerForm.status === "INVALID") {
      return;
    }
    else {
      let addresses = [
        {
          "name": this.registerForm.controls.fullname.value,
          "city": this.registerForm.controls.city.value,
          "zipcode": this.registerForm.controls.zipcode.value,
          "country": this.registerForm.controls.country.value,
          "default": true,
          "address_line_2": this.registerForm.controls.addressLineTwo.value,
          "address_line_1": this.registerForm.controls.addressLineOne.value,
        }
      ]
      this.addressess = addresses
      let body = {
        id: 0,
        name: this.registerForm.controls.fullname.value,
        email: this.registerForm.controls.email.value,
        password: this.randomString(10, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'),
        about_me: '',
        phone: JSON.stringify(this.registerForm.controls.phoneNumber.value),
        DOB: moment(new Date()).format('YYYY-MM-DD'),
        address_line_1: this.registerForm.controls.addressLineOne.value,
        address_line_2: this.registerForm.controls.addressLineTwo.value,
        city: this.registerForm.controls.city.value,
        zipcode: this.registerForm.controls.zipcode.value,
        country: this.registerForm.controls.country.value,
        other_address: JSON.stringify(addresses),
        created_at: new Date()
      }
      this.generalService.usersignupGuest(body).subscribe(res => {
        let tempUser = this.generalService.getCookie('coc_temp_user')
        if (res['status'] === 'error') {
          this.emailAlreadyExist = true;
          this.messageService.add({ severity: 'error', detail: 'This email address is already registered. Please login to continue.' });
        } else {
          this.generalService.setCookie('coc_user', this.registerForm.controls.email.value, 2)
          this.generalService.updateCartByTempUser('temp', tempUser, this.registerForm.controls.email.value).subscribe(async (res1) => {
            await this.generalService.ClearCookieByName('coc_user')
            this.guestCheckout(res);
            this.submittedGuestCheckout = false;
          })
        }
      },
        err => {
          this.messageService.add({ severity: 'error', detail: 'Seems like there was an error. Please try again or create an account.' });
        })
    }

  }

  guestCheckout(user) {
    this.showLoginPopup = false;
    this.page_loader = true;
    let productarr = []
    if (user) {
      this.userdata = user;
      if (this.addressess && this.addressess.length > 0) {
        this.showInvalidDeliveryAddress = false;
        if (this.card_products.length > 0) {

          //gtag
          this.card_products.forEach((element, i) => {
            let obj = {
              item_id: element.product.id,
              item_name: element.product.name,
              affiliation: "Concepto Wellness Store",
              coupon: "",
              currency: element.product.currency,
              discount: this.cartDiscount,
              index: i,
              item_brand: "Concepto",
              item_category: "Multivitamins",
              item_category2: "",
              item_category3: "",
              item_category4: "",
              item_category5: "",
              item_list_id: element.product.id,
              item_list_name: element.product.name,
              item_variant: "",
              location_id: "",
              price: element.product.price,
              quantity: element.quantity
            }
            productarr.push(obj)
            if (i === this.card_products.length - 1) {
              if (AppConstants.GA4 === true) {
                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                  event: "begin_checkout",
                  ecommerce: {
                    items: productarr
                  }
                });
              }
            }
          });
          //gtag


          let arr = []
          this.card_products.forEach((element, i) => {
            let objProducts = {
              'product_id': element.product_id,
              'cart_id': element.id,
              'quantity': element.quantity
            }
            arr.push(objProducts);
            if (this.card_products.length - 1 === i) {
              // this.total_amount = 0.5
              let obj = {
                'total_amount': this.total_amount,
                'vat': this.vat,
                'delivery_charges': this.delivery_charges.toString(),
                'discount': this.cartDiscount,
                'promocode': this.promocode,
                'sub_total_amout': this.total_price.toString(),
                'products': JSON.stringify(arr),
                'status': 'payment pending',
                'createdDate': new Date(),
                'user': this.userdata.id,
                'address': `${this.userdata.address_line_1}, ${this.userdata.address_line_2}, ${this.userdata.city}, ${this.userdata.country}, ${this.userdata.zipcode}`,
                'oid': '',
                'txndate_processed': new Date(),
                'chargetotal': '',
                'tracking_id': ''
              }
              this.generalService.addOrder(obj).subscribe(res => {
                if (res['id']) {
                  let customerid = res['id']
                  let storeID = AppConstants.storeId
                  let sharedS = AppConstants.sharedS
                  var today = new Date();
                  let formatedDate = moment().format('YYYY:MM:DD-HH:mm:ss')
                  let totalChanged = this.total_amount.toString();
                  let currencyCode = "826";
                  let stringToBeHashed = `${storeID}${formatedDate}${totalChanged}${currencyCode}${sharedS}`;
                  let stringToHex = this.toHex(stringToBeHashed)
                  let hash = sha256.hex(stringToHex);
                  let obj1 = {
                    "txntype": "sale",
                    "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
                    "txndatetime": formatedDate,
                    "hash_algorithm": "SHA256",
                    "hash": hash,
                    "storename": storeID,
                    "checkoutoption": "combinedpage",
                    "customerid": `order-${customerid}`,
                    "mode": "payonly",
                    "chargetotal": totalChanged,
                    "currency": currencyCode,
                    "authenticateTransaction": 'true'
                  }
                  //payment link generator
                  this.generalService.makePayment(obj1).subscribe(resLink => {
                    if (resLink['data']) {
                      document.location.href = resLink['data'];
                      this.page_loader = false
                    } else {
                      //no payment link error
                      this.page_loader = false
                    }
                  })

                } else {
                  //invalid cart
                  this.page_loader = false
                }
              })
            }
          });

        } else {
          //nothing in cart
          this.page_loader = false
        }
      } else {
        this.page_loader = false;
        this.showInvalidDeliveryAddress = true;
      }


    } else {
      this.page_loader = false;
      this.showLoginPopup = true;
    }


  }

  newuseremail() {
    this.emailAlreadyExist = false;
  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  toHex(str) {
    var result = '';
    for (var i = 0; i < str.length; i++) {
      result += str.charCodeAt(i).toString(16);
    }
    return result;
  }

  zipcodeChecker(zipcode) {
    if (zipcode === '') {
      this.emptyzipcode = true
    }
    else {
      this.emptyzipcode = false
    }
    this.generalService.validateZipCode(zipcode).subscribe(res => {
      if (res && res['status'] === 404) {
        this.messageService.add({ severity: 'error', detail: 'Invalid Zipcode' });
      }
      this.invalidZipcode = false;
    },
      err => {
        this.invalidZipcode = true
      })
  }

  onKeySearch(event: any) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (event.keyCode != 13) {
        this.zipcodeChecker(event.target.value);
      }
    }, 500);
  }

  ngOnDestroy(): void {
    let cartClass = document.getElementsByClassName("cartToggle");
    let cartFlex = document.getElementsByClassName("cartToggleFlex");
    if (cartFlex) {
      cartFlex[0].classList.remove("justify-content-center");
      cartClass[0].classList.remove("d-none");
      cartClass[1].classList.remove("d-none");
    }
  }
}
