import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'navbar-corporate-testing',
  templateUrl: './corporate-testing.component.html',
  styleUrls: ['./corporate-testing.component.scss']
})
export class CorporateTestingComponent implements OnInit {

  constructor(private router: Router,
     private metaService: Meta, private titleService: Title) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/diagnostics/corporate-testing') {
          this.metaService.updateTag({ name: 'description', content: 'Concepto Diagnostics offers comprehensive Corporate covid Testing for corporate clients. Our testing packages are designed to help employers for promoting a healthy workplace.' });
          this.metaService.updateTag({ name: 'keywords', content: 'Corporate covid Testing UK' });
          this.titleService.setTitle('Corporate covid Testing UK - Concepto Diagnostics');
        }
      }
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    let menuitem = document.getElementsByClassName("p-menuitem");
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove("active");
    }

  }

}
