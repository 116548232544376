import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'navbar-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  activeInd: any
  faq: any;
  constructor(@Inject(DOCUMENT) private dom) { 
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    let menuitem = document.getElementsByClassName("p-menuitem");
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove("active");
    }

    this.faq = [
      {
        question: 'How long do I have to wait for my results?',
        answer: 'You can expect to receive your test results within the stipulated time as specified on the website. The turnaround time (TAT) for all our diagnostics tests has been specified on the respective webpages.',
      },
      {
        question: 'Can I use my test results to personalise my vitamins?',
        answer: 'Yes! Your test results will highlight which vitamins you’re likely to have trouble absorbing efficiently. This will indicate which nutrients you may need to supplement with. We will use your test results to personalise vitamins based on your nutritional needs and health goals.',
      },
      {
        question: 'How do I pay?',
        answer: 'You may pay for Products and services online from our website using a debit or credit card.',
      },
      {
        question: 'How can I change my appointment?',
        answer: 'To change an appointment, please send us an email on change@conceptoclinic.co.uk with your name, time, and the date you would like to change your appointment.',
      },
      {
        question: 'How do I cancel my appointment?',
        answer: 'You may cancel your subscription permanently at any time, by emailing us at cancel@conceptoclinic.co.uk or through (website option) and letting us know you would like to cancel. We will send you an email _14_ days before the Monthly Subscription Date to remind you that you have 14 days to cancel, change or skip a month before the cut-off date for that month.',
      },
      {
        question: 'How do I contact Concepto?',
        answer: 'You can get in touch with us with any questions or comments via email (support@conceptoclinic.co.uk)',
      },
      {
        question: 'Where is Concepto available?',
        answer: 'Concepto is a Diverse British Healthcare Company. You can access the Concepto platform from anywhere in the world via your smartphone, laptop or desktop. Or you can visit one of our nationwide clinics and our trained staff will help you with your queries.',
      },
      {
        question: 'Why choose Concepto?',
        answer: 'Concepto is a healthcare company with over a decade of experience in the field of diagnostics. We use state-of-the-art technology to build healthcare solutions to match all the needs of our customers. We have CQC regulated clinics and an in-house UKAS accredited lab for testing. Our Wellness Products are a unique blend of natural ingredients, consciously crafted with care to supplement nutritional needs of the self-aware. ',
      },
      {
        question: 'Is my data safe?',
        answer: 'Keeping our customers data safe is of utmost importance to us here at Concepto. We guarantee that we will not sell or pass your data to any third party outside, unless that third party requires the data to provide you with your order for delivery, or if required to do so by law. We store your data on our secure servers and customers can request a data update, a copy of their information, and that their account or data is deleted permanently, in line with the 2018 General Data Protection Regulation.',
      },
      {
        question: 'How do I track my order?',
        answer: 'All orders are shipped using recorded deliveries.',
      },
      {
        question: 'How can I update my delivery address?',
        answer: 'You can update your delivery address at any time by logging into the My Account section, using the email you signed up with. Under Manage you can change your delivery address for all future deliveries',
      },
      {
        question: 'What is our Return Policy?',
        answer: 'Upon delivery, Users must verify the content of the delivery and report anomalies without undue delay i.e., 14 working days, using the contact details provided in this document or as described in the delivery note. Users may refuse to accept the parcel if visibly damaged.',
      },
      {
        question: ' Are your vitamins vegan and vegetarian?',
        answer: 'Our vitamins are 100% vegetarian. These tablets are not vegan.',
      },
      {
        question: 'What are multivitamins made of?',
        answer: 'Concepto’s multivitamin products – Advanced Immunity, Wellness King and Wellness Queen are a unique blend of natural ingredients consciously crafted with utmost care. Visit wellness.conceptodiagnostics.co.uk for details in our key ingredients.',
      },
      {
        question: 'Expiry date for vitamins & supplements?',
        answer: 'The date of manufacture as well as expiry are clearly displayed on product labels.',
      },
      {
        question: 'Do vitamins & supplements address allergies?',
        answer: 'Please consult a GP/ doctor/ HCA for allergies. Our multivitamins are meant to supplement your nutrition levels only.',
      },
      {
        question: 'What is the difference between your multivitamin products and personalised supplements?',
        answer: 'Our multivitamins are designed to supplement your nutrition levels, boost immunity, energy levels and vitality. For specific gaps in your wellbeing, we provide Vital body tests to ascertain deficiencies and tailor personalised supplements based on these tests.',
      },
      {
        question: 'What blood tests are included in my subscriptions?',
        answer: 'The Vital Body tests include Iron, Vitamin D, Calcium and Vitamin B12 tests. They are priced at 85 pounds but come for free when you subscribe to our Wellness Program.',
      },
      {
        question: 'Are home visits available for blood test?',
        answer: 'All blood samples for tests and profiles are collected in clinic location chosen by the customer. We have a Mobile Phlebotomy service, which can be opted for, if you want a phlebotomist to visit your home/office for sample collection. This is a premium service that come with an extra cost and is subject to accessibility of patient’s location.',
      },
      {
        question: 'Can my test location be changed or rescheduled?',
        answer: 'Yes, customers can reschedule tests and profiles before the selected date of appointment.',
      },
      {
        question: 'How will I receive my results?',
        answer: 'Results will be sent to customers via email. They can be also be accessed from our website using your login credentials.',
      },
      {
        question: 'Can I cancel my appointment and get full refund?',
        answer: 'An admin fee of £20 would be deducted in case of cancellation or no-show by patient. We usually refund any money received from you for the Products or service using the same method originally used by you to pay for your purchase.',
      },
      {
        question: 'Can I book an appointment onsite and pay in cash?',
        answer: 'All appointments are booked online via our website. We do not have arrangements of accepting payments (cash or card) at our clinic, all transactions are carried out online through our secure payment portal.',
      }
    ];
  }

}
