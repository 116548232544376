import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-nipt-home',
  templateUrl: './nipt-home.component.html',
  styleUrls: ['./nipt-home.component.scss']
})
export class NiptHomeComponent implements OnInit {
  accuracy_data: any = []
  accuracy_details: any;
  service_data: any = []
  service_details: any;
  activeInd: any
  faq: any;
  small_accuracy: any = [];
  resourcesdata: any[];
  responsiveOptions: any[];
  counter: number = 0;
  playimage: boolean = true;
  Scientific_Validation_Studies: any;
  Clinical_Experience_Studies: any;
  VeriSeq_NIPT_Solution_V2_Publications: any;
  Genome_Wide_Publications: any;
  page_loader = false;
  constructor(private router: Router, private metaService: Meta, private titleService: Title, private generalService: GeneralService, private sanitizer: DomSanitizer, @Inject(DOCUMENT) private dom) {
    this.metaService.updateTag({ name: 'description', content: "Get accurate NIPT testing in London with our advanced NIPT blood tests. Trust us for a safe, reliable, and compassionate experience during your prenatal journey." });
    this.metaService.updateTag({ name: 'keywords', content: 'NIPT Test UK, Illumina Prenatal Test UK, Genetics Testing UK, Non-Invasive Prenatal screening Test, Non-Invasive screening test UK,Prenatal screening test UK, Illumina blood test uk, Illumina prenatal test UK, Illumina pregnancy test UK, NIPT Illumina Test UK, Noninvasive prenatal testing UK, NIPT Test clinic UK, Prenatal tests clinic uk , NIPT test clinc near me, Illumina blood test near me' });
    this.titleService.setTitle('NIPT Test London | NIPT Blood Test London | Concepto Diagnostics');
    this.Scientific_Validation_Studies = [
      {
        'name': 'DNA Sequencing versus Standard Prenatal Aneuploidy Screening',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Scientific Validation Studies/DNA Sequencing versus Standard Prenatal Aneuploidy Screening.pdf'
      },
      {
        'name': 'Genome_Wide_Fetal_Aneuploidy_Detection_by_Maternal',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Scientific Validation Studies/Genome_Wide_Fetal_Aneuploidy_Detection_by_Maternal.4.pdf'
      },
      {
        'name': 'Number Variation and False Positive Prenatal Screening Results',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Scientific Validation Studies/Number Variation and False Positive Prenatal Screening Results.pdf'
      }
    ]
    this.Clinical_Experience_Studies = [
      {
        'name': 'Prenatal Diagnosis - 2016 - Taneja - Noninvasive prenatal testing in the general obstetric population  clinical performance',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Clinical Experience Studies/Prenatal Diagnosis - 2016 - Taneja - Noninvasive prenatal testing in the general obstetric population  clinical performance.pdf'
      },
      {
        'name': 'Ultrasound in Obstet   Gyne - 2016 - Fosler - Aneuploidy screening by noninvasive prenatal testing in twin pregnancy',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Clinical Experience Studies/Ultrasound in Obstet   Gyne - 2016 - Fosler - Aneuploidy screening by noninvasive prenatal testing in twin pregnancy.pdf'
      }
    ]
    this.VeriSeq_NIPT_Solution_V2_Publications = [
      {
        'name': 'Analysis of cell-free DNA in a consecutive series of 13,607 routine cases for the detection of fetal chromosomal aneuploidies in a single center in Germany',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/VeriSeq NIPT Solution v2 Publications/Analysis of cell-free DNA in a consecutive series of 13,607 routine cases for the detection of fetal chromosomal aneuploidies in a single center in Germany.pdf'
      },
      {
        'name': 'Performance of a Paired-End Sequencing-Based Noninvasive Prenatal Screening Test in the Detection of Genome-Wide Fetal Chromosomal Anomalies',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/VeriSeq NIPT Solution v2 Publications/Performance of a Paired-End Sequencing-Based Noninvasive Prenatal Screening Test in the Detection of Genome-Wide Fetal Chromosomal Anomalies.pdf'
      }
    ]
    this.Genome_Wide_Publications = [
      {
        'name': 'Clinical experience with noninvasive prenatal testing in Germany Analysis of over 500 high-risk cases for trisomy 21, 18, 13 and monosomy X',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Clinical experience with noninvasive prenatal testing in Germany Analysis of over 500 high-risk cases for trisomy 21, 18, 13 and monosomy X.pdf'
      },
      {
        'name': 'Clinical impact of additional findings detected by genome-wide noninvasive prenatal testing- Follow-up results of the TRIDENT-2 study',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Clinical impact of additional findings detected by genome-wide noninvasive prenatal testing- Follow-up results of the TRIDENT-2 study.pdf'
      },
      {
        'name': 'Genome-wide cell-free DNA screening a focus on copy-number variants',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Genome-wide cell-free DNA screening a focus on copy-number variants.pdf'
      },
      {
        'name': 'Initial Clinical Experience with NIPT for Rare Autosomal Aneuploidies and Large Copy Number Variations',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Initial Clinical Experience with NIPT for Rare Autosomal Aneuploidies and Large Copy Number Variations.pdf'
      },
      {
        'name': 'Origin and clinical relevance of chromosomal aberrations other than the common trisomies detected by genome-wide NIPS- results of the TRIDENT study',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Origin and clinical relevance of chromosomal aberrations other than the common trisomies detected by genome-wide NIPS- results of the TRIDENT study.pdf'
      },
      {
        'name': 'Outcome of publicly funded nationwide first-tier noninvasive prenatal screening',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Outcome of publicly funded nationwide first-tier noninvasive prenatal screening.pdf'
      },
      {
        'name': 'Prenatal Diagnosis - 2018 - Scott - Rare autosomal trisomies  Important and not so rare',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Prenatal Diagnosis - 2018 - Scott - Rare autosomal trisomies  Important and not so rare.pdf'
      },
      {
        'name': 'Three years of clinical experience with a genome-wide cfDNA screening test for aneuploidies and copy-number variants',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Three years of clinical experience with a genome-wide cfDNA screening test for aneuploidies and copy-number variants.pdf'
      }
    ]
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Concepto-NIPT"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)

    this.accuracy_data = [
      {
        title: "T21",
        title_one: "98.89%",
        title_two: "99.73%"
      },
      {
        title: "T18",
        title_one: "99.90%",
        title_two: "99.89%"
      },
      {
        title: "T13",
        title_one: "99.90%",
        title_two: "99.76%"
      }
    ]
    this.accuracy_details = this.accuracy_data;

    this.service_data = [
      // {
      //   image: "../../assets/images/nipt/NIPT.png",
      //   title: "Concepto-NIPT",
      //   description: "T21, T18, T13 and gender detection",
      //   button: "BOOK NOW",
      //   link: "nipt",
      //   price: "199.00"
      // },
      // {
      //   image: "../../assets/images/nipt/NIPT.png",
      //   title: "Concepto-NIPT Standard",
      //   description: "T21, T18, T13 and gender detection",
      //   button: "BOOK NOW",
      //   link: "nipt",
      //   price: "199.00"
      // },
      {
        image: "../../assets/images/nipt/NIPT.png",
        title: "Concepto-NIPT Advance",
        description: "T21, T18 and T13, gender detection and sex chromosome anomalies.",
        button: "BOOK NOW",
        link: "nipt-advance",
        price: "299.00"
      },
      // {
      //   image: "../../assets/images/nipt/NIPT.png",
      //   title: "Concepto-NIPT Advance",
      //   description: "T21, T18 and T13, gender detection, sex chromosome anomalies and 1 microdeletion.",
      //   button: "BOOK NOW",
      //   link: "nipt-advance",
      //   price: "249.00"
      // },
      // {
      //   image: "../../assets/images/nipt/NIPT+Ultrasound.png",
      //   title: "Concepto-NIPT Home Service",
      //   description: "Order your Concepto-NIPT Home Service",
      //   button: "UNAVAILABLE",
      //   link: "nipt-home-service",
      //   price: "219.00"
      // }
    ]
    this.service_details = this.service_data;

    this.small_accuracy = [
      {
        title: "What does a low-risk result mean?",
        description: "A low risk means - no anomaly detected for that specific condition which is under study. A LOW-RISK result does not guarantee that a fetus is unaffected by a chromosomal or genetic condition as False Negatives are possible, though the probability is quite low with Concepto-NIPT (VeriSeq NIPT Solution v2)",
      },{
        title: "What does a high-risk result mean?",
        description: "A high risk means - An anomaly is detected for that specific condition which is under study. Some non-aneuploid fetuses may have HIGH PROBABILITY results i.e., false positives may occur, though the probability of this occurrence is very low. In the event of a HIGH-RISK result and/or other clinical indications of a chromosomal condition, confirmatory testing is necessary for diagnosis.",
      },
      {
        title: "What is fetal fraction?",
        description: "Cell-free DNA (cfDNA) in the peripheral blood of pregnant women consists of maternal DNA and a small portion of DNA from the fetus (which is placentally derived). The percentage of DNA in the maternal blood sample that comes from the placenta is known as the fetal fraction (FF). Factors that impact FF include gestational age, multiple gestations, maternal BMI, fetal aneuploidy, maternal disease, and medication. "
      },
      {
        title: "Illumina’s NIPT performance at lower fetal fraction",
        description: "Illumina’s VeriSeq NIPT Solution v2 approach allows samples with low fetal fraction to be reported, reducing NIPT failures. This method uses paired-end sequencing to measure fragment length, adding to FF estimation."
      }
    ]
    this.faq = [
      {
        question: 'What is the Concepto-NIPT?',
        answer: ['A Non-Invasive Prenatal Test – Concepto-NIPT provides accurate likelihoods of common chromosome conditions, such as trisomy 21 (Down syndrome), trisomy 18, and trisomy 13 in pregnancies as early as 10 weeks gestation. '],
      },
      {
        question: 'What do Non-Invasive Prenatal Test results show?',
        answer: ['A Non-Invasive Prenatal Test Concepto-NIPT results show whether there is a high or low chance your baby has trisomy 21, trisomy 18, or trisomy 13.',"Test results that indicate a high likelihood do not mean that your baby definitely has one of the conditions listed above and, the results must be discussed with your medical practitioner."]

      },
      {
        question: 'How accurate are Concepto-NIPT results?',
        answer: ['Concepto-NIPT are extremely accurate. Results identify 99.9 per cent of pregnancies with trisomy 21, trisomy 18, or trisomy 13.']
      },
      {
        question: 'Who should have a Concepto-NIPT? ',
        answer: ['All women who have a confirmed pregnancy can take this test after completing 10 weeks of gestation. ']
      },
      {
        question: 'How is the Concepto-NIPT performed? ',
        answer: ['A venous sample of the mother’s blood is taken. This is then sent to our lab with a result ready in Upto 5 working days.']
      },
      {
        question: 'What if the results come back inconclusive?',
        answer: ['On rare occasions, this can happen and you shouldn’t worry. Should the results come back inconclusive, our team will get in touch with you for the future steps.']
      },
      {
        question: 'I am not 10 weeks pregnant yet, can I still book this test?',
        answer: ['Yes, you can book the appointment for a future date that falls after the completion of 10 weeks of gestation.']
      },
      {
        question: 'How do I pay?',
        answer: ['You may pay using a debit or credit card online for all our services.']
      },
      {
        question: 'How can I change my appointment?',
        answer: ['To change an appointment, please send us an email on support@conceptoclinic.co.uk with your name, time, and the date you would like to change your appointment.']
      },
      {
        question: 'How do I contact Concepto?',
        answer: ['You can get in touch with us with any questions or comments via email (nipt@conceptoclinic.co.uk ).']
      },
      {
        question: 'Where is Concepto available?',
        answer: ['Concepto is a Diverse British Healthcare Company based out of Watford, Hertfordshire and has clinic locations nationwide. ']
      },
      {
        question: 'Is my data safe?',
        answer: ['Keeping our customers data safe is of utmost importance to us here at Concepto-NIPT. We guarantee that we will not sell or pass your data to any third party outside, unless required to do so by law. We store your data on our secure servers and customers can request a data update, a copy of their information, and that their account or data is deleted permanently, in line with the 2018 General Data Protection Regulation.']
      },
      {
        question: 'Are home visits available for blood test?',
        answer: ['No, all blood samples for tests and profiles are collected in clinic location chosen by the customer. ']
      },
      {
        question: 'Can my test location be changed or rescheduled?',
        answer: ['Yes, customers can reschedule tests or change the location, based on availability before the selected date of appointment. There are no charges for rescheduling or change of the location.']
      },
      {
        question: 'Can I cancel my appointment and get full refund?',
        answer: ['To change an appointment, please send us an email on support@conceptoclinic.co.uk with your name, time, and the date you would like to change your appointment. There are no charges for rescheduling.', 'Admin charges of £20 will be applicable in case of cancellation.'] 
      },
      {
        question: 'Can I book an appointment onsite and pay in cash?',
        answer: ['All appointments are booked online via our website. Our employees on site do not handle confidential information or cash payment, all transactions are carried out online through our secure payment portal. You can book appointment from your phone and internet access at any time.']
      },
      {
        question: 'How long do I have to wait for my result?',
        answer: ['You will receive your result on email within 5 working days, once samples reach our laboratory in the UK.']
      },
      {
        question: 'Is Concepto-NIPT valid for twin pregnancies?',
        answer: ['Yes, we can report NIPT on twin pregnancies, but the result will only be able to say the likelihood of an affected pregnancy, it cannot tell which Twin or if both Twins are affected.']
      },
      {
        question: 'What are the limitations of Concepto-NIPT in terms of a vanishing twin?',
        answer: ['Our NIPT test is not advised if you have a vanishing twin pregnancy.']
      },
      // {
      //   question: 'Can Concepto-NIPT determine sex chromosome anomalies and microdeletions for twin pregnancies?',
      //   answer: ['Sex chromosome anomalies and microdeletions cannot be reported for twin pregnancies.']
      // },
      {
        question: 'How does NIPT report the sex of a Twin Pregnancy?',
        answer: ['NIPT will only be able to determine that you have a pregnancy with 2 girls, or a pregnancy with at least 1 male.']
      },
      {
        question: 'When is the best time to have a NIPT test?',
        answer: ['The best time to have a NIPT test is after completion of 10 weeks of pregnancy.']
      },
      {
        question: 'Is there a time limit for NIPT?',
        answer: ['Ideally, NIPT test should be done before week 35, since it provides parents with information on genetic abnormalities early. It allows them enough time for timely and adequate intervention and also to prepare themselves mentally.']
      },
      {
        question: 'How important is the NIPT test?',
        answer: ['Prenatal testing is important. All expectant mothers must take the Non-invasive prenatal test irrespective of age or risk since it accurately provides information on potential genetic disorders.']
      },
      {
        question: 'What are the risks of NIPT testing?',
        answer: ['There is no risk associated with NIPT test as it is non-invasive. Blood sample is drawn from the arm of the expectant mother, with zero risk of miscarriage. ']
      },
      {
        question: 'Is it possible that NIPT is wrong?',
        answer: ["NIPT is highly accurate and the accuracy statistics are provided on our website and our <a href='https://conceptodiagnostics.co.uk/individual-blog/NIPT-test-accuracy-detailed-statistics-and-limitations'>blog</a>."]
      },
      {
        question: 'What abnormalities can NIPT detect?',
        answer: ['NIPT can detect Downs syndrome, Edwards syndrome and Pataus syndrome along with sex chromosome anomalies. It can accurately detect fetal gender too.']
      },
      {
        question: 'What happens if NIPT is positive?',
        answer: ['If your NIPT test detects high risk for a genetic condition, you must consult with you OBG consultant/midwife for further confirmatory testing']
      },
      {
        question: 'How early can NIPT detect gender?',
        answer: ['NIPT can detect fetal sex or gender after week 10 of pregnancy.']
      }
    ];

    this.responsiveOptions =
      [
        { breakpoint: '3000px', numVisible: 5, numScroll: 1 },
        { breakpoint: '1800px', numVisible: 4, numScroll: 1 },
        { breakpoint: '1600px', numVisible: 3, numScroll: 1 },
        { breakpoint: '1300px', numVisible: 3, numScroll: 1 },
        { breakpoint: '1024px', numVisible: 3, numScroll: 1 },
        { breakpoint: '950px', numVisible: 2, numScroll: 1 },
        { breakpoint: '760px', numVisible: 2, numScroll: 1 },
        { breakpoint: '560px', numVisible: 2, numScroll: 1 },
        { breakpoint: '390px', numVisible: 1, numScroll: 1 },
      ];

    this.resourcesdata = [
      {
        id: 1,
        type: 'pdf',
        image: "../../assets/images/nipt/resources-1.png",
        title: "Research",
        description: "Performance of a Paired-End Sequencing-Based Noninvasive Prental Screening Test",
        link: "https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Resource-1.pdf"
      },
      {
        id: 2,
        type: 'pdf',
        image: "../../assets/images/nipt/resources-2.png",
        title: "Research",
        description: "Fetal fraction and its importance in NIPT",
        link: "https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Resource-2.pdf"
      },
      {
        id: 3,
        type: 'pdf',
        image: "../../assets/images/nipt/resources-3.png",
        title: "Research",
        description: "NA sequencing of maternal plasma reliably identifies trisomy 18 and trisomy 13 as well as Down syndrome: an international collaborative study",
        link: "https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Resource-3.pdf"
      },
      {
        id: 4,
        type: 'pdf',
        image: "../../assets/images/nipt/resources-4.png",
        title: "Research",
        description: "NIPT fast facts",
        link: "https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/nipt-fast-facts.pdf"
      },
      {
        id: 5,
        type: 'mp4',
        image: this.sanitizer.bypassSecurityTrustResourceUrl('https://drive.google.com/file/d/1RVoUT0ED96SQzGQ4o29et0mf4YPtlyTO/preview'),
        title: "Understanding options for expanding prenatal genetic screening with NIPT",
        description: "",
        link: ""
      },
      {
        id: 6,
        type: 'mp4',
        image: this.sanitizer.bypassSecurityTrustResourceUrl('https://drive.google.com/file/d/17YXU7mQLnf26DpELvLb978SBhgtd96Xx/preview'),
        title: "An introduction to genetics & prenatal genetic testing",
        description: "",
        link: ""
      },
      {
        id: 7,
        type: 'mp4',
        image: this.sanitizer.bypassSecurityTrustResourceUrl('https://drive.google.com/file/d/1nfz0Dg6bANocvSMsJOVUuE0-wNus5oyk/preview'),
        title: "Understanding NIPT results",
        description: "",
        link: ""
      },
      {
        id: 8,
        type: 'mp4',
        image: this.sanitizer.bypassSecurityTrustResourceUrl('https://drive.google.com/file/d/1ceirwQJcerqot8H96dF3kzFqdgEpB3G8/preview'),
        title: "What is NIPT?",
        description: "",
        link: ""
      },
    ]
  }

  increment(data: any) {
    let temp = window.innerWidth
    if (temp > 1600) {
      if (this.resourcesdata.length - 4 > this.counter) {
        this.counter++;
      }
    } else if (temp < 1600 && temp > 950) {
      if (this.resourcesdata.length - 3 > this.counter) {
        this.counter++;
      }
    }
    else if (temp < 950 && temp > 390) {
      if (this.resourcesdata.length - 2 > this.counter) {
        this.counter++;
      }
    } else {
      if (this.resourcesdata.length - 1 > this.counter) {
        this.counter++;
      }
    }
    // this.counter++;
  }

  decrement() {
    if (this.counter == 0) {
    } else {
      this.counter--;
    }
  }

  individual(data) {
    this.router.navigate([`/nipt/${data.link}`]);
  }

  scroll(input) {
    if(input === 'nipt-home-service'){
      this.router.navigate([`/nipt/${input}`]);
    }else{
      this.router.navigate([`/nipt/${input}`]);
    }
  }

  scrolltoView(el: HTMLElement) {
    el.scrollIntoView();
}

  playPause() {
    let myVideo: any = document.getElementById("my_video");
    if (myVideo.paused) {
      myVideo.play();
      this.playimage = false;
    }
    else {
      myVideo.pause();
      this.playimage = true;

    }
  }

  playPauseResource(input) {
    let myVideo: any = document.getElementById(`my_video_${input}`);
    if (myVideo.paused) {
      myVideo.play();
      // this.playimage = false;
    }
    else {
      myVideo.pause();
      // this.playimage = true;

    }
  }


  priviewFromS3(reportLink) {
    this.page_loader = true;
    let Base64;
    let obj = {
      link: reportLink
    }
    this.generalService.getBase64FromS3Link(obj).subscribe((res) => {
      this.page_loader = false;
      Base64 = res['base64']
      let pdfWindow = window.open("")
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(Base64) + "'></iframe>"
      )
      //pdfWindow.document.title = `${this.selectedDataInClinicSti[0].fullLegalName ? this.selectedDataInClinicSti[0].fullLegalName.toUpperCase() : this.selectedDataInClinicSti[0].patientName.toUpperCase()}`;
      pdfWindow.document.title = 'Research 1'
    })
  }

  viewAllResources() {
    this.router.navigate(['/resources'])
  }
}






