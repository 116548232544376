import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { AppConstants } from '../app.constants';

@Component({
  selector: 'navbar-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  display = false;
  addressess: any[];
  cities: string;
  selectedCategory: any = null;
  diagnostics_table: any[];
  wellness_table: any[];
  imaging_table: any[];
  diagnostics_past: any[];
  wellness_past: any[];
  imaging_past: any[];
  wellness_table_filter: any[];
  userdata: any = []
  pastDiagnosticsAppointmentData = [];
  pastWellnessAppointmentData = [];
  pastImagingAppointmentData = [];
  upcommingDiagnosticsAppointmentData = [];
  upcommingWellnessAppointmentData = [];
  upcommingImagingAppointmentData = [];
  modaldata: any;
  pastDiagnosticsFilter = [];

  newaddress!: FormGroup;
  submitted = false;
  openNewAddresstab = false;
  enableEdit: any;

  editFullName: any;
  editAddress_line_1: any;
  editCity: any;
  editAddress_line_2: any;
  editCountry: any;
  editZipcode: any;
  timeout: any = null;
  page_loader = false;
  invalidZipcode = false;

  pastWellnessFilter = [];
  pastImagingFilter = [];
  upcomingDiagnosticsFilter = [];
  upcomingWellnessFilter = [];
  upcomingImagingFilter = [];

  editPhone = false;
  validPhone = true;
  editUserPhone: any;
  products = [];
  emptyzipcode = true;
  showAppointmentDetailsPopup = false
  invalidname: boolean = false;
  invalidAddOne: boolean = false;
  invalidAddTwo: boolean = false;
  invalidcity: boolean = false;
  invalidcountry: boolean = false;
  upcommingNutritionAppointmentData: any;
  constructor(private generalService: GeneralService, private router: Router, private formBuilder: FormBuilder, private messageService: MessageService) { }

  async ngOnInit(): Promise<void> {
    await this.getProductsList();
    this.getuserdetails();
    this.getDiagnosticsTests();
    this.getNutritionTests();

    let menuitem = document.getElementsByClassName("p-menuitem");
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove("active");
    }
    this.wellness_table_filter = this.wellness_table;
    this.newaddress = this.formBuilder.group({
      fullname: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.pattern("^[a-zA-Z.]+([a-zA-Z. ]+)*$")]],
      address_line_1: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.pattern("^[a-zA-Z0-9\s/!,.@#$%^&*()]+([a-zA-Z0-9\s/!,.@#$%^&*() ]+)*$")]],
      address_line_2: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.pattern("^[a-zA-Z0-9\s/!,.@#$%^&*()]+([a-zA-Z0-9\s/!,.@#$%^&*() ]+)*$")]],
      city: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.pattern("^[a-zA-Z]+([a-zA-Z ]+)*$")]],
      country: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(1), Validators.pattern("^[a-zA-Z]+([a-zA-Z ]+)*$")]],
      zipcode: ['', [Validators.required]],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.newaddress.controls;
  }

  getProductsList() {
    this.generalService.getProducts().subscribe(res => {
      this.products = res;
      this.getPastWellnessProducts();
      this.getUpcommingWellnessProducts();
    })
  }

  getDiagnosticsTests() {
    let data = this.generalService.getCookie('coc_user')
    this.generalService.getDiagnosticsTestsForUser(data).subscribe(res => {
      this.pastDiagnosticsFilter = res[0]
      this.pastDiagnosticsAppointmentData = this.pastDiagnosticsFilter
    })

  }

  getNutritionTests() {
    let data = this.generalService.getCookie('coc_user');
    this.generalService.getNutritionTestsForUser(data).subscribe(res => {
      if (res && res.length > 0) {
        res.forEach((element, i) => {
          element.appointment_date_time = moment(element.appointment_date).format('DD/MM/YYYY HH:mm')
          if (res.length - 1 === i) {
            this.upcommingNutritionAppointmentData = res
          }
        });
      } else {
        this.upcommingNutritionAppointmentData = []
      }
    })

  }

  openHealthQuestions(url) {
    window.open(`${AppConstants.appointmentForms}nutrition-package-questionnaire?type=${url}`, '_blank').focus();

  }

  getPastWellnessProducts() {
    let type = 'delivered';
    let user = this.generalService.getCookie('coc_user')
    this.generalService.getorderDetailsforProfile(type, user).subscribe(res => {
      if (res && res.length > 0) {

        res.forEach((element, i) => {
          if (element['txndate_processed']) {
            element['txndate'] = element['txndate_processed'].split('-')[0].replaceAll(':', '/')
          } else {
            element['txndate'] = ''
          }
          let productArr = ''
          let jsonproducts = JSON.parse(element.products)
          jsonproducts.forEach(element3 => {
            let product = this.products.filter(product => product['id'] === element3.product_id)
            element3.product = product[0].name
            element3.price = product[0].price
          });
          jsonproducts.forEach((element2, j) => {
            let string = `${element2.product}(${element2.quantity}Nos - £${element2.price})`;
            if (productArr.length > 0) {
              productArr = `${productArr}, ${string}`
            } else {
              productArr = `${string}`
            }
            if (jsonproducts.length - 1 === j) {
              element.product = productArr
            }
          });
          if (res.length - 1 === i) {
            this.pastWellnessFilter = res;
            this.pastWellnessAppointmentData = this.pastWellnessFilter;
          }
        });

      }
    })
  }

  getUpcommingWellnessProducts() {
    let type = 'purchased';
    let user = this.generalService.getCookie('coc_user')
    this.generalService.getorderDetailsforProfile(type, user).subscribe(res => {
      if (res && res.length > 0) {
        res.forEach((element, i) => {
          if (element['txndate_processed']) {
            element['txndate'] = element['txndate_processed'].split('-')[0].replaceAll(':', '/')
          } else {
            element['txndate'] = ''
          }
          let productArr = ''
          let jsonproducts = JSON.parse(element.products)
          jsonproducts.forEach(element3 => {
            let product = this.products.filter(product => product['id'] === element3.product_id)
            element3.product = product[0].name
            element3.price = product[0].price
          });
          jsonproducts.forEach((element2, j) => {
            let string = `${element2.product}(${element2.quantity}Nos - £${element2.price})`;
            if (productArr.length > 0) {
              productArr = `${productArr}, ${string}`
            } else {
              productArr = `${string}`
            }
            if (jsonproducts.length - 1 === j) {
              element.product = productArr
            }
          });
          if (res.length - 1 === i) {
            this.upcomingWellnessFilter = res
            this.upcommingWellnessAppointmentData = this.upcomingWellnessFilter;
            this.upcommingWellnessAppointmentData = this.upcommingWellnessAppointmentData.reverse()
          }
        });

      }
    })
  }

  openAddresspanel() {
    this.display = true;
  }

  searchPastDiag(input) {
    if (input !== "") {
      input = input.toLowerCase();
      let array = this.pastDiagnosticsAppointmentData.filter(x => x.requesttest[0].requesttestObj.test_name?.toLowerCase().includes(input));
      this.pastDiagnosticsAppointmentData = array;
    }
    else {
      this.pastDiagnosticsAppointmentData = this.pastDiagnosticsFilter;
    }
  }

  getuserdetails() {
    let data = this.generalService.getCookie('coc_user')
    let loggedIn = this.generalService.getCookie('coc_token')
    if (loggedIn && data) {
      this.generalService.userByEmail(data).subscribe(res => {
        if (res) {
          this.userdata = res;
          this.addressess = JSON.parse(this.userdata.other_address)
          if (this.addressess !== undefined) {
            if (this.userdata.zipcode === "") {
              this.makeDefault(0)
            }
          }
          if (this.addressess === null) {
            this.createNewAddress()
          }
        } else {
          this.router.navigate(['/login'])
        }
      })
    } else {
      this.router.navigate(['/login'])
    }

  }

  downloadDiagnosticsReport(data) {
    let fileName = `${data.customer.fullLegalName ? data.customer.fullLegalName.toUpperCase() : data.customer.patientName.toUpperCase() + ' ' + data.customer.familyName.toUpperCase()} - ${data.sampleDateTime}-REPORT.pdf`;
    this.downloadFromS3(data.reportLink, fileName)
  }

  downloadFromS3(reportLink, fileName) {
    let base64;
    let obj = {
      link: reportLink
    }
    this.generalService.getBase64FromS3Link(obj).subscribe((res) => {
      base64 = res['base64']
      let a = document.createElement("a");
      a.href = "data:application/octet-stream;base64," + base64;
      a.download = fileName
      a.click();
    })
  }

  modal(id) {
    this.pastDiagnosticsAppointmentData.forEach(element => {
      if (id === element.id) {
        this.showAppointmentDetailsPopup = true;
        this.modaldata = element
        this.modaldata.sampleDate = element.sampleDate.split('T')[0];
        this.modaldata.reportPublishDate = element.reportPublishDate?.split('T')[0];
      }
    })
  }

  // Delete Address
  deleteAddress(index, object) {
    if (object.default) {
      this.addressess.splice(index, 1);
      this.makeDefault(0)
    }
    else {
      this.addressess.splice(index, 1);
      this.userdata.other_address = JSON.stringify(this.addressess)
      this.enableEdit = false;
      this.updateUserData();
    }

  }

  // Default Address
  makeDefault(id) {
    this.addressess.forEach((element, i) => {
      if (i === id) {
        element.default = true;
        this.userdata.city = element.city;
        this.userdata.country = element.country;
        this.userdata.address_line_2 = element.address_line_2;
        this.userdata.address_line_1 = element.address_line_1;
        this.userdata.zipcode = element.zipcode;

      } else {
        element.default = false;
      }
    })

    this.userdata.other_address = JSON.stringify(this.addressess)
    this.updateUserData();
  }


  // ADD NEW ADDRESS
  addnewAddress() {
    this.submitted = true;
    let otherAddress = JSON.parse(this.userdata.other_address)
    if (this.newaddress.invalid) {
      return;
    } else {
      if (this.invalidZipcode === false) {
        let defaults = false;
        if (this.userdata.other_address === undefined || this.userdata.other_address === "null") {
          defaults = true
          otherAddress = [
            {
              name: this.newaddress.controls.fullname.value,
              address_line_1: this.newaddress.controls.address_line_1.value,
              city: this.newaddress.controls.city.value,
              zipcode: this.newaddress.controls.zipcode.value,
              address_line_2: this.newaddress.controls.address_line_2.value,
              country: this.newaddress.controls.country.value,
              default: defaults
            }
          ]
          this.userdata.other_address = JSON.stringify(otherAddress)
          this.updateUserData()
          this.makeDefault(0)
        }
        else {
          defaults = false
          otherAddress.push(
            {
              name: this.newaddress.controls.fullname.value,
              address_line_1: this.newaddress.controls.address_line_1.value,
              city: this.newaddress.controls.city.value,
              zipcode: this.newaddress.controls.zipcode.value,
              address_line_2: this.newaddress.controls.address_line_2.value,
              country: this.newaddress.controls.country.value,
              default: defaults
            }
          )
          this.userdata.other_address = JSON.stringify(otherAddress)
          this.updateUserData()
        }


      }

    }
  }

  // Edit Addresssss
  editAddress(index, object) {
    this.enableEdit = index;
    this.editFullName = object.name;
    this.editAddress_line_1 = object.address_line_1;
    this.editCity = object.city;
    this.editAddress_line_2 = object.address_line_2;
    this.editCountry = object.country;
    this.editZipcode = object.zipcode;
  }


  // UPDATE USER DATA API
  updateUserData() {
    this.generalService.updateUser(this.userdata).subscribe(res => {
      this.openNewAddresstab = false;
      this.newaddress.reset()
      this.submitted = false;
      this.getuserdetails()
    })
  }

  openNewAdress() {
    this.openNewAddresstab = true;
    setTimeout(() => {
      document.getElementById(`addNewAddressForm`).scrollIntoView({ behavior: "smooth" })
    }, 500)
  }

  cancelNewAddress() {
    this.openNewAddresstab = false;
    this.newaddress.reset()
  }

  saveEditAddress(id, object) {
    let validatorOne = new RegExp("^[a-zA-Z.]+([a-zA-Z. ]+)*$");
    let validatorTwo = new RegExp("^[a-zA-Z0-9\s/!,.@#$%^&*()]+([a-zA-Z0-9\s/!,.@#$%^&*() ]+)*$");
    let validatorThree = new RegExp("^[a-zA-Z]+([a-zA-Z ]+)*$");
    if (!validatorOne.test(this.editFullName)) {
      this.invalidname = true
    }
    if (!validatorTwo.test(this.editAddress_line_1) || this.editAddress_line_1.length < 3) {
      this.invalidAddOne = true;
    }
    if (!validatorTwo.test(this.editAddress_line_2) || this.editAddress_line_2.length < 3) {
      this.invalidAddTwo = true
    }
    if (!validatorThree.test(this.editCity)) {
      this.invalidcity = true;
    }
    if (!validatorThree.test(this.editCountry)) {
      this.invalidcountry = true;
    }

    if (!this.invalidZipcode && validatorOne.test(this.editFullName) && (validatorTwo.test(this.editAddress_line_1) && (this.editAddress_line_1.length > 3)) && (validatorTwo.test(this.editAddress_line_2) && this.editAddress_line_2.length > 3) && validatorThree.test(this.editCity) && validatorThree.test(this.editCountry)) {
      this.enableEdit = null;
      this.invalidname = false;
      this.invalidAddOne = false;
      this.invalidAddTwo = false;
      this.invalidcity = false;
      this.invalidcountry = false;
      this.addressess.forEach((element, i) => {
        if (i === id) {
          element.name = this.editFullName;
          element.city = this.editCity;
          element.country = this.editCountry;
          element.address_line_2 = this.editAddress_line_2;
          element.address_line_1 = this.editAddress_line_1;
          element.zipcode = this.editZipcode;
        }
      })
      if (object.default) {
        this.makeDefault(id)
      } else {
        this.userdata.other_address = JSON.stringify(this.addressess)
        this.updateUserData();
      }
    }
  }

  cancelEditAddress() {
    this.enableEdit = null;
  }

  zipcodeChecker(zipcode) {
    if (zipcode === '') {
      this.emptyzipcode = true
    }
    else {
      this.emptyzipcode = false
    }
    this.generalService.validateZipCode(zipcode).subscribe(res => {
      if (res && res['status'] === 404) {
        this.messageService.add({ severity: 'error', detail: 'Invalid Zipcode' });
      }
      this.invalidZipcode = false;
    },
      err => {
        this.invalidZipcode = true
      })
  }

  onKeySearch(event: any) {
    if (event.keyCode != 13) {
      this.zipcodeChecker(event.target.value);
    }
  }

  searchPastWellness(input) {
    if (input !== "") {
      input = input.toLowerCase();
      let array = this.pastWellnessFilter.filter(x => x.product?.toLowerCase().includes(input));
      this.pastWellnessAppointmentData = array;
    }
    else {
      this.pastWellnessAppointmentData = this.pastWellnessFilter;
    }
  }

  editPhoneNumber() {
    this.editUserPhone = this.userdata.phone
    this.editPhone = true
  }

  savePhoneNumber() {
    let temp = JSON.stringify(this.editUserPhone)
    if (temp.length > 9 && temp.length < 15) {
      this.userdata.phone = temp
      this.editPhone = false
      this.updateUserData()
      this.validPhone = true
    }
    else {
      this.validPhone = false
    }
  }

  cancelPhoneEdit() {
    this.editPhone = false
  }

  createNewAddress() {
    let defaults = true
    let otherAddress = [
      {
        name: this.userdata.name,
        address_line_1: this.userdata.address_line_1,
        city: this.userdata.city,
        zipcode: this.userdata.zipcode,
        address_line_2: this.userdata.address_line_2,
        country: this.userdata.country,
        default: defaults
      }
    ]
    this.userdata.other_address = JSON.stringify(otherAddress)
    this.updateUserData()
    this.makeDefault(0)
  }

  searchUpcomingWellness(input) {
    if (input !== "") {
      input = input.toLowerCase();
      let array = this.upcomingWellnessFilter.filter(x => x.product?.toLowerCase().includes(input));
      this.upcommingWellnessAppointmentData = array;
      this.upcommingWellnessAppointmentData.reverse()
    }
    else {
      this.upcommingWellnessAppointmentData = this.upcomingWellnessFilter;
      this.upcommingWellnessAppointmentData.reverse()
    }
  }

  checkPhoneValidation(value) {
    if (value.length > 9 && value.length < 15) {
      this.validPhone = true
    } else {
      this.validPhone = false
    }
  }
}
