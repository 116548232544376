import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConstants } from '../app.constants';

@Component({
  selector: 'app-aesthetics-offerings',
  templateUrl: './aesthetics-offerings.component.html',
  styleUrls: ['./aesthetics-offerings.component.scss']
})
export class AestheticsOfferingsComponent implements OnInit {
  sectionBottomData: any = [];
  sectionTopData: any = [];
  checkListData: any = [];
  offringData: any;
  show: boolean;
  image: string;
  activeImage: number = 0;
  totalPrice: number = 0;
  checkedData: number = 0;
  priceCheck: boolean = false;
  selection: any = [];
  userSelectedList: any = [];
  constructor(private router: Router) {
    this.sectionTopData = [
      {
        "title": "Fillers",
        "type": "Fillers",
        "image": ["../../assets/images/aesth/fillers-1.png", "../../assets/images/aesth/fillers-2.png", "../../assets/images/aesth/fillers-3.png", "../../assets/images/aesth/fillers-4.png"],
        "discription": [{
          "start_text": "Our filler treatments adapt to different facial features to fill and reduce wrinkles for regained definition and volume and enhance your appearance. Prices below are for 1ML.",
        }],
        "basic_details": [
          {
            "name": "Lips",
            "price": "195.00",
            "link": "53184492"
          },
          {
            "name": "Cheeks",
            "price": "350.00",
            "link": "53184505"
          },
          {
            "name": "Jawline",
            "price": "300.00",
            "link": "53184516"
          },
          {
            "name": "Smokers' lines",
            "price": "150.00",
            "link": "53184523"
          },
          {
            "name": "Rhinoplasty",
            "price": "300.00",
            "link": "53184536"
          }
        ],
        "area_include": "",
        "btn_name": "Book Now"
      },
      {
        "title": "Anti-wrinkle",
        "type": "Anti-wrinkle",
        "image": ["../../assets/images/aesth/anti-wrinkle-1.png", "../../assets/images/aesth/anti-wrinkle-2.png", "../../assets/images/aesth/anti-wrinkle-3.png", "../../assets/images/aesth/anti-wrinkle-4.png"],
        "discription": [{
          "start_text": "Reduce the fine lines and wrinkles caused by:",
          "list": [{
            "name": "- Sun exposure"
          },
          {
            "name": "- Pollution"
          },
          {
            "name": "- Loss of collagen"
          },
          {
            "name": "- Poor hydration"
          },
          {
            "name": "- Smoking"
          },
          {
            "name": "- Stress"
          }
          ],
          "end_text": "Our range of treatments smoothen your skin, for an even, natural and youthful look."
        }],
        "basic_details": [
          {
            "name": "3 areas (forehead lines, frown lines and crow's feet)",
            "price": "200.00",
            "link": "52998436"
          },
          {
            "name": "Brow lift",
            "price": "100.00",
            "link": "53184389"
          },
          {
            "name": "Slimming of the nose",
            "price": "100.00",
            "link": "53184402"
          },
          {
            "name": "Relaxing Gummy Lines",
            "price": "100.00",
            "link": "53184415"
          },
          {
            "name": "Plasma neckline, Deep necklines",
            "price": "300.00",
            "link": "53184428"
          }, {
            "name": "Face lifting and slimming",
            "price": "350.00",
            "link": "53184441"
          },
          {
            "name": "Excess sweating for armpits",
            "price": "300.00",
            "link": "53184464"
          }
        ],
        "area_include": "",
        "btn_name": "Book Now"
      },
      {
        "title": "Beauty Treatments",
        "type": "Beauty-Treatments",
        "image": ["../../assets/images/aesth/beauty-treatment-1.png", "../../assets/images/aesth/beauty-treatment-2.png", "../../assets/images/aesth/beauty-treatment-3.png", "../../assets/images/aesth/beauty-treatment-4.png"],
        "discription": [{
          "start_text": "Let our experts work their magic for a stunning transformation.",
          "list": [],
          "end_text": ""
        }],
        "basic_details": [
          {
            "name": "Lash Lift and Tint",
            "price": "45.00",
            "link": "55038588"
          },
          {
            "name": "HD Brows",
            "price": "30.00",
            "link": "55038593"
          },
          {
            "name": "Brow Lamination (Brow sculpting)",
            "price": "45.00",
            "link": "55038602"
          },
          {
            "name": "Skin Brightening Facial",
            "price": "30.00",
            "link": "55038608"
          },
          {
            "name": "Skin Brightening Facial with blackhead extraction",
            "price": "45.00",
            "link": "55038615"
          }
        ],
        "area_include": "",
        "btn_name": "Book Now"
      }
    ];

    this.sectionBottomData = [
      {
        "image": "../../assets/images/aesth/elina.png",
        "name": "Eileen Flatley",
        "description": "Registered with the NMC for more than 25 years as a Nurse and Midwife. Extensive experience working in the NHS and private sectors. Registered CQC manager."
      },
      {
        "image": "../../assets/images/aesth/sha.png",
        "name": "Shannaz Wahid",
        "description": "A Senior Mental Health Practitioner (28 years' experience), offering Aesthetic services for the last 3 years."
      },
      {
        "image": "../../assets/images/aesth/Sally.png",
        "name": "Sally",
        "description": "Registered with the NMC for more than 25 years as a Nurse and Midwife. Extensive experience working in the NHS."
      }
    ];

    this.checkListData = [
      {
        "name": "All treatments administered by experienced nurses"
      },
      {
        "name": "Safe and Gentle"
      },
      {
        "name": "Quality Assured"
      }
    ];
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
    let getUrl = this.router.url.split('=')[1];
    console.log(getUrl)
    let temp = this.sectionTopData.filter(item => item.type === getUrl)[0];
    this.offringData = temp;
    if (AppConstants.GA4 === true) {
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [
            {
              item_id: 0,
              item_name: this.offringData.title,
              affiliation: "Concepto Aesthetics",
              coupon: "",
              currency: 'GBP',
              discount: 0,
              index: 0,
              item_brand: "Concepto",
              item_category: 'Aesthetics',
              item_category2: "",
              item_category3: "",
              item_category4: "",
              item_category5: "",
              item_list_id: 0,
              item_list_name: this.offringData.title,
              item_variant: "",
              location_id: "",
              price: this.offringData.basic_details[0].price,
              quantity: 1
            }
          ]
        }
      });
    }
  }

  getPrice(prz: number, r: any, i: any) {
    this.checkedData = i;
    // if (!r.target.checked) {
    // this.userSelectedList = this.userSelectedList.filter(( obj ) => {
    //   return obj.name !== this.checkedData['name'];
    // });
    //   this.checkedData = -1;
    //   this.totalPrice = this.totalPrice - Number(prz);
    // }
    // if (r.target['checked']) {
    this.priceCheck = true;
    // this.userSelectedList.push(this.checkedData)
    this.totalPrice = Number(prz);
    // }
    // if (this.totalPrice === 0) {
    //   this.priceCheck = false;
    // }

  }

  // getSelection(item) {
  //   return this.selection.findIndex(s => s.id === item.id) !== -1;
  // }

  displaySelectedImage(index: any) {
    this.show = true;
    this.image = this.offringData.image[index];
    this.activeImage = index;
  }

  bookNow(input) {
    if (input.basic_details && input.basic_details.length > 0) {
      input.basic_details.forEach(element => {
        if (element.name === this.userSelectedList) {
          if (AppConstants.GA4 === true) {
            window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
            window.dataLayer.push({
              event: "begin_checkout",
              ecommerce: {
                items: [
                  {
                    item_id: 0,
                    item_name: element.name,
                    affiliation: "Concepto Aesthetics",
                    coupon: "",
                    currency: "GBP",
                    discount: 0,
                    index: 0,
                    item_brand: "Concepto",
                    item_category: "Aesthetics",
                    item_category2: "",
                    item_category3: "",
                    item_category4: "",
                    item_category5: "",
                    item_list_id: 0,
                    item_list_name: element.name,
                    item_variant: "",
                    location_id: "",
                    price: element.price,
                    quantity: element.quantity
                  }
                ]
              }
            });
          }
          window.open(`${AppConstants.appointmentForms}location?appointmentTypeID=${element.link}`)
        }
      });
    }
  }

}
