import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { AppConstants } from '../app.constants';
// declare global {
//   interface Window {
//     Trustpilot: any;
//   }
// }
@Component({
  selector: 'diagnostics-covid-test-filtered',
  templateUrl: './covid-test-filtered.component.html',
  styleUrls: ['./covid-test-filtered.component.scss']
})
export class CovidTestFilteredComponent implements OnInit, AfterContentInit {

  test_and_profile: any;
  index_view: any;
  view_more = true;
  searchText: any;
  customerTestArray: any = [];
  pageTitle = 'Covid Tests';
  arr = []
  cartProducts: any[];
  home_Kits = false;
  char = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  page_loader: boolean = false;

  constructor(private router: Router, private generalService: GeneralService,
    private metaService: Meta, private titleService: Title) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/diagnostics/covid-test-filtered?type=fit-to-fly') {
          this.metaService.updateTag({ name: 'description', content: 'Concepto Clinic is a leading government-approved Covid-19 test clinic and the best Fit to Fly Test Clinic in the UK. We  provide RT-PCR tests with a Fit to Fly certificate for traveling purposes' });
          this.metaService.updateTag({ name: 'keywords', content: 'Best Fit to Fly Test Clinic in UK' });
          this.titleService.setTitle('Best Fit to Fly Test Clinic UK | Covid Fit to Fly Testing | Concepto Clinic');
        }else if (event.urlAfterRedirects === '/diagnostics/covid-test-filtered?type=china-travel-test') {
          this.metaService.updateTag({ name: 'description', content: 'You can book your China Covid travel test package in the UK at Concepto Clinic. Get fast & reliable results for all  required COVID-19 tests for traveling. Book Now.' });
          this.metaService.updateTag({ name: 'keywords', content: 'China Covid Travel Test Package UK' });
          this.titleService.setTitle('China Covid Travel Test Package UK | China Travel Testing | Concepto Clinic');
        }
      }
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Covid Tests"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)

    let type = this.router.url.split('=')[1];
    if (type) {
      // this.pageTitle = type.replace(/-/g, ' ')
      if (type === 'china-travel-test') {
        this.pageTitle = 'China Travel Test';
      }
      else if (type === 'same-day-rt-pcr-travel-test') {
        this.pageTitle = 'Same Day RT-PCR Travel Test'
      }
      else if (type === 'book-pcr-travel-test') {
        this.pageTitle = 'Book PCR travel Test'
      }
      else if (type === 'fit-to-fly') {
        this.pageTitle = 'Fit To Fly'
      }
      // else if (type === 'home-kits') {
      //   this.pageTitle = 'Postal Kits'
      // } 
      else if (type === 'covid-tests') {
        this.pageTitle = 'Covid Tests'
      } 
      else if (type === 'flu-tests') {
        this.pageTitle = 'Flu Tests'
      } 
      else{
        this.router.navigate(['/page-not-found'])
      }
    }
    this.page_loader = true
    this.generalService.getProducts().subscribe((res)=>{
      res.forEach((element,i)=>{
        if (type === 'same-day-rt-pcr-travel-test' || type === 'book-pcr-travel-test' || type === 'fit-to-fly'){
          if(element.name === "Ultra Urgent PCR Travel Test - results in 3 hours" 
            || element.name === "Urgent PCR Travel Test - same day results"){
            let object = {
              title: element.name,
              type: 'In Clinic Test',
              eta: JSON.parse(element.extra_description_one)[0].detail,
              price: element.price,
              about: JSON.parse(element.short_description),
              url: element.url
            }
            this.arr.push(object)
          }
        } 
        // else if (type === 'home-kits'){
        //   if(element.productCategory.category === "Home Kit" && element.available === "true"){
        //     let object = {
        //       title: element.name,
        //       // type: 'Home Test',
        //       type: JSON.parse(element.extra_description_one)[0].detail,
        //       price: element.price,
        //       about: JSON.parse(element.short_description),
        //       url: element.url,
        //       id: element.id,
        //       quantity: 1
        //     }
        //     this.arr.push(object)
        //   }
        // } 
        else if (type === 'covid-tests'){
          if(element.productCategory.category !== "Home Kit" && element.available === "true"){
            if(element.name === "Ultra Urgent PCR Travel Test - results in 3 hours" 
            || element.name === "Urgent PCR Travel Test - same day results" || element.name.includes('Antigen')){
            let object = {
              title: element.name,
              type: 'In Clinic Test',
              eta: JSON.parse(element.extra_description_one)[0].detail,
              price: element.price,
              about: JSON.parse(element.short_description),
              url: element.url
            }
            this.arr.push(object)
          }
          }

        }
        else if (type === 'flu-tests'){
          if(element.productCategory.category !== "Home Kit" && element.available === "true"){
            if(element.name.includes('Flu')){
            let object = {
              title: element.name,
              type: 'In Clinic Test',
              eta: JSON.parse(element.extra_description_one)[0].detail,
              price: element.price,
              about: JSON.parse(element.short_description),
              url: element.url
            }
            this.arr.push(object)
          }
          }

        }
      })
      
      this.customerTestArray = this.arr;
      // if (type === 'home-kits'){
      //   this.home_Kits = true
      //   // debugger
      //   this.getCartsByUser()
      // }
    })
    this.page_loader = false
  }

  navigateToCovid(){
    this.router.navigateByUrl('/diagnostics/covid-test');

  }


  ngAfterContentInit() {
    // setTimeout(() => {
    //   const trustboxRef = document.getElementById('trustbox');
    //   if (window.Trustpilot) {
    //     window.Trustpilot.loadFromElement(trustboxRef);
    //   }
    // }, 500)
  }

  viewmore(index) {
    this.index_view = index;
    this.view_more = false;
  }

  viewless(index) {
    this.view_more = true;
    this.index_view = null;
  }


  bookNow(data) {
    this.router.navigate(['diagnostics/profile'], { queryParams: { test: data.url } });
  }

  search(input) {
    if (input !== "") {
      input = input.toLowerCase();
      let array = this.arr.filter(x => x.title.toLowerCase().includes(input));
      this.customerTestArray = array;
    }
    else {
      this.customerTestArray = this.arr;
    }
  }

  buyNow(type) {
    this.page_loader = true
    // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Added to Cart' });
    let obj = {};
    let existing_user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (existing_user) {
      obj = {
        id: 0,
        product_id: type.id,
        quantity: 1,
        sub_total: type.price,
        stage: 'added to cart',
        created_at: new Date(),
        temp_user_id: '',
        user_id: existing_user,
      }
    } else if (!existing_user && temp_user) {
      obj = {
        id: 0,
        product_id: type.id,
        quantity: 1,
        sub_total: type.price,
        stage: 'added to cart',
        created_at: new Date(),
        temp_user_id: temp_user,
        user_id: '',
      }
    } else {
      let temp_id = this.randomString(16, this.char);
      this.generalService.setCookie('coc_temp_user', temp_id, 2)
      obj = {
        id: 0,
        product_id: type.id,
        quantity: 1,
        sub_total: type.price,
        stage: 'added to cart',
        created_at: new Date(),
        temp_user_id: temp_id,
        user_id: '',
      }
    }
    
    this.generalService.addToCart(obj).subscribe((res) => {
      this.getCartsByUser()      
      this.generalService.setCookie('coc_active_cart', true, 2);
      if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
        this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) + 1, 2)
      } else {
        this.generalService.setCookie('coc_cart_details', 1, 2)
      }
      this.page_loader = false
    })
    
    this.page_loader = false
  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  getCartsByUser() {
    this.page_loader = true;
    let user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (user) {
      let type = 'email';
      this.generalService.getCartByUser(type, user).subscribe(res => {
        this.cartProducts = res
        this.cartProducts.forEach(element =>{
          if(element.stage === "added to cart"){
            this.customerTestArray.forEach(el => {
              if(element.product.name === el.title){
                el["cart_details"] = element;
              }
            });
          }
        })
        this.page_loader = false
      })
    } else if (temp_user && !user) {
      let type = 'temp';
      this.generalService.getCartByUser(type, temp_user).subscribe(res => {
        this.cartProducts = res
        this.cartProducts.forEach(element =>{
          if(element.stage === "added to cart"){
            this.customerTestArray.forEach(el => {
              if(element.product.name === el.title){
                el["cart_details"] = element;
                this.page_loader = false;
              }
            });
          }
        })
      })
    }
    this.page_loader = false
  }


  homeKitCheck(index){
    this.customerTestArray[index].cart_details = false
  }

  learnMore(url){
    this.router.navigate(['diagnostics/profile'], { queryParams: { test: url } });
  }
}
