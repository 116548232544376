import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppConstants } from '../app.constants';
import { GeneralService } from '../general.service';

@Component({
  selector: 'app-well-man-profile',
  templateUrl: './well-man-profile.component.html',
  styleUrls: ['./well-man-profile.component.scss'],
  providers: [MessageService]
})
export class WellManProfileComponent implements OnInit {
  profileData: any = {};
  code = true;
  test_and_profile: any;
  char = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  test_details: any;
  biomarkers: boolean = true;
  homeKits: boolean = false;
  nameOfItemAddedToCart = ""
  showTat = true;
  showPostCartAdding = false;
  cartProducts: any;
  page_loader = false;

  constructor(private router: Router, private messageService: MessageService, private generalService: GeneralService,
    private metaService: Meta, private titleService: Title) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/diagnostics/profile?test=Covid-19-Antigen-LFT-Test') {
          this.metaService.updateTag({ name: 'description', content: 'Are you Looking for a Covid-19 Antigen LFT Test in the UK? Get yourself tested and receive your result with a medical  certificate emailed to you in just 2 hrs.' });
          this.metaService.updateTag({ name: 'keywords', content: 'Covid-19 Antigen LFT Test UK' });
          this.titleService.setTitle('Book Covid-19 Antigen LFT Test UK | Concepto Diagnostics');
        } else if (event.urlAfterRedirects === '/diagnostics/profile?test=Ultra-Urgent-PCR-Travel-Test-results-in-3-hours') {
          this.metaService.updateTag({ name: 'description', content: 'If you have to travel urgently and need an urgent RT-PCR Travel Test report within three hours, book a slot at the best  Urgent PCR Travel Test Clinic in the UK.' });
          this.metaService.updateTag({ name: 'keywords', content: 'Urgent PCR Travel Test Clinic UK ' });
          this.titleService.setTitle('Urgent PCR Travel Test Clinic UK | Ultra Urgent PCR Travel Test | Concepto Diagnostics');
        } else if (event.urlAfterRedirects === '/diagnostics/profile?test=Urgent-PCR-Travel-Test-same-day-results') {
          this.metaService.updateTag({ name: 'description', content: 'Concepto Diagnostics is a government-approved Covid-19 test clinic in the UK. We provide an accurate urgent RT-PCR travel  test report with a Fit to Fly certificate in the UK' });
          this.metaService.updateTag({ name: 'keywords', content: ' RT-PCR Travel Test UK' });
          this.titleService.setTitle('RT-PCR Travel Test UK | Covid-19 PCR Travel Test | Concepto Diagnostics');
        }
      }
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    let menuitem = document.getElementsByClassName("p-menuitem");
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove("active");
    }

    let getUrl = this.router.url.split('=')[1]
    this.generalService.getProductByName(getUrl).subscribe((res) => {
      if (res.length === 0) {
        this.router.navigate(['/page-not-found'])
      }
      this.test_details = res[0]
      let cartImages = JSON.parse(res[0]['pictures'])['image'];
      let arr
      cartImages.forEach(images => {
        if (images.split(':')[0] === 'cart') {
          let item = `../../assets/images/test-profile-images/${images.split(`:`)[1]}`;
          arr = item
        }
      });
      this.profileData["title"] = res[0].name;
      this.nameOfItemAddedToCart = this.profileData["title"];
      this.profileData["code"] = res[0].short_description;
      this.profileData["test_id"] = res[0].description;
      this.profileData["contents"] = JSON.parse(res[0].extra_description_three);
      this.profileData["eta"] = JSON.parse(res[0].extra_description_one)[0].detail;
      this.profileData["price"] = res[0].price;
      this.profileData["about"] = JSON.parse(res[0].extra_description_two);
      this.profileData["images"] = arr;
      this.profileData["type"] = res[0].productCategory.category;
      this.profileData["id"] = res[0].id;

      let notToShowArr = ['Ultra-Urgent-PCR-Travel-Test-results-in-3-hours', 'Urgent-PCR-Travel-Test-same-day-results', 'emirates-Urgent-PCR-Travel-Test-same-day-results', 'emirates-Urgent-PCR-Travel-Test', 'Covid-19-Antigen-LFT-Test', 'emirates-Covid-19-Antigen-LFT-Test', 'China-Travel-Package-Regular', 'China-Travel-Package-Urgent', 'Respiratory-Test-Panel-same-day-results']
      notToShowArr.forEach((element, i) => {
        if (element === res[0].url) {
          this.showTat = false
        }
      });
      if (this.test_details.productCategory.category === "Covid Test") {
        this.biomarkers = false;
        setTimeout(() => {
          for (let i = 0; i < menuitem.length; i++) {
            if (menuitem[i]["outerText"] === "Covid Tests") {
              menuitem[i].classList.add("active");
            }
          }
        }, 1000)
      }
      else if (this.test_details.productCategory.category === "Home Kit") {
        this.homeKits = true;
        this.getCartDetailsByUser()
        setTimeout(() => {
          for (let i = 0; i < menuitem.length; i++) {
            if (menuitem[i]["outerText"] === "Covid Tests") {
              menuitem[i].classList.add("active");
            }
          }
        }, 1000)
      }
      else {
        setTimeout(() => {
          for (let i = 0; i < menuitem.length; i++) {
            if (menuitem[i]["outerText"] === "Pathology Tests") {
              menuitem[i].classList.add("active");
            }
          }
        }, 1000)
      }
    })
  }

  booknow() {
    let p = this.profileData.test_id;
    if (p) {
      window.open(`${AppConstants.appointmentForms}location?appointmentTypeID=${p}`)
    }
  }

  addToCart(data) {
    if (data) {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Added to Cart' });
      let obj = {};
      let existing_user = this.generalService.getCookie('coc_user')
      let temp_user = this.generalService.getCookie('coc_temp_user')
      let id = data.id;
      if (existing_user) {
        obj = {
          id: 0,
          product_id: id,
          quantity: 1,
          sub_total: data.price,
          stage: 'added to cart',
          created_at: new Date(),
          temp_user_id: '',
          user_id: existing_user,
        }
      } else if (!existing_user && temp_user) {
        obj = {
          id: 0,
          product_id: id,
          quantity: 1,
          sub_total: data.price,
          stage: 'added to cart',
          created_at: new Date(),
          temp_user_id: temp_user,
          user_id: '',
        }
      } else {
        let temp_id = this.randomString(16, this.char);
        this.generalService.setCookie('coc_temp_user', temp_id, 2)
        obj = {
          id: 0,
          product_id: id,
          quantity: 1,
          sub_total: data.price,
          stage: 'added to cart',
          created_at: new Date(),
          temp_user_id: temp_id,
          user_id: '',
        }
      }
      this.generalService.addToCart(obj).subscribe((res) => {
        this.generalService.setCookie('coc_active_cart', true, 2);
        this.showPostCartAdding = true;
        //this.nameOfItemAddedToCart = type.name
        // window.location.reload();
        this.getCartDetailsByUser()
        if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
          this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) + 1, 2)
        } else {
          this.generalService.setCookie('coc_cart_details', 1, 2)
        }
      })
    }
  }

  increaseCartItem() {
    if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
      this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) + 1, 2)
    } else {
      this.generalService.setCookie('coc_cart_details', 0, 2)
    }
  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  getdata() {
    this.showPostCartAdding = false;
  }

  getCartDetailsByUser() {
    this.page_loader = true;
    let user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (user) {
      let type = 'email';
      this.generalService.getCartByUser(type, user).subscribe(res => {
        this.cartProducts = res
        let count = 0
        this.cartProducts.forEach(element => {
          if (element.stage === "added to cart") {
            if (element.product.name === this.profileData.title) {
              count = count + 1
              this.profileData.cart_details = element
              this.page_loader = false;
            }
          }
        })
        if (count === 0) {
          this.profileData.cart_details = false
          this.page_loader = false;
        }
        this.page_loader = false
      })
    } else if (temp_user && !user) {
      let type = 'temp';
      this.generalService.getCartByUser(type, temp_user).subscribe(res => {
        this.cartProducts = res
        let count = 0
        this.cartProducts.forEach(element => {
          if (element.stage === "added to cart") {
            if (element.product.name === this.profileData.title) {
              count = count + 1
              this.profileData.cart_details = element
              this.page_loader = false;
            }
          }
        })
        if (count === 0) {
          this.profileData.cart_details = false
          this.page_loader = false;
        }
        this.page_loader = false
      })
    }
    this.page_loader = false
  }

  goToCart() {
    this.router.navigateByUrl(`cart`);
  }
}
