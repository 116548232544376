import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { HomeComponent } from './home/home.component';
import { CarouselModule } from 'primeng/carousel';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';

import { MenubarModule } from 'primeng/menubar';
import { GMapModule } from 'primeng/gmap';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HeaderComponent } from './header/header.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { BlogComponent } from './blog/blog.component';
import { FooterComponent } from './footer/footer.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { GeneralService } from './general.service';
import { PressComponent } from './press/press.component';
import {TableModule} from 'primeng/table';
import {MatTabsModule} from '@angular/material/tabs';
import { CartbuttonComponent } from './cartbutton/cartbutton.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NiptHomeComponent } from './nipt-home/nipt-home.component';
import { IndividualNiptComponent } from './individual-nipt/individual-nipt.component';
import { BluePinkComponent } from './blue-pink/blue-pink.component';
// import { TrustboxComponent } from './trustpilot/trustpilot.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { LoaderComponent } from './loader/loader.component';
import { ResourcesComponent } from './resources/resources.component';
import { PartnerComponent } from './partner/partner.component';
import { MobilePhlebotomistComponent } from './mobile-phlebotomist/mobile-phlebotomist.component';
import { confirmationComponent } from './confirmation/confirmation.component';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import { ShareModule } from 'ngx-sharebuttons';
import { DialogModule } from 'primeng/dialog';
import { BannerComponent } from './banner/banner.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CartHandlerComponent } from './cart-handler/cart-handler.component';
import { CartPopverComponent } from './cart-popver/cart-popver.component';
import { ClinicFinderComponent } from './clinic-finder/clinic-finder.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CorporateTestingComponent } from './corporate-testing/corporate-testing.component';
import { CovidorFluComponent } from './covid-or-flu/covid-or-flu';
import { CovidTestFilteredComponent } from './covid-test-filtered/covid-test-filtered.component';
import { CovidTestComponent } from './covid-test/covid-test.component';
import { DiagnosticsHomeComponent } from './diagnostics-home/diagnostics-home.component';
import { EmiratesTestComponent } from './emirates-test/emirates-test.component';
import { FaqComponent } from './faq/faq.component';
import { GenderDetailsPageComponent } from './gender-details-page/gender-details-page.component';
import { IndividualBlogComponent } from './individual-blog/individual-blog.component';
import { PathologyTestProfilesComponent } from './pathology-test-profiles/pathology-test-profiles.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SexuallyTransmittedInfectionsComponent } from './sexually-transmitted-infections/sexually-transmitted-infections.component';
import { StiDetailsPageComponent } from './sti-details-page/sti-details-page.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { WellManProfileComponent } from './well-man-profile/well-man-profile.component';
import { BoldPipe } from '../assets/pipes/bold.pipe';
import { CartComponent } from './cart/cart.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MessageService } from 'primeng/api';
import { MultivitaminDetailsComponent } from './multivitamin-details/multivitamin-details.component';
import { WellnessHomeComponent } from './wellness-home/wellness-home.component';
import { WellnessProductsComponent } from './wellness-products/wellness-products.component';
import { GbsComponent } from './gbs/gbs.component';
import { IndividualGbsComponent } from './individual-gbs/individual-gbs.component';
import { AestheticsPageComponent } from './aesthetics-page/aesthetics-page.component';
import { AestheticsOfferingsComponent } from './aesthetics-offerings/aesthetics-offerings.component';
import { UnsubscribeCrossPromotionalEmailsComponent } from './unsubscribe-cross-promotional-emails/unsubscribe-cross-promotional-emails.component';
import { TestCodesComponent } from './test-codes/test-codes.component';
import { ArchivedBlogComponent } from './archived-blogs/archived-blogs.component';


declare global {
  interface Window { dataLayer: any[]; }
}
@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,
    HomeComponent,
    HeaderComponent,
    AboutusComponent,
    BlogComponent,
    FooterComponent,
    ComingSoonComponent,
    PressComponent,
    CartbuttonComponent,
    PageNotFoundComponent,
    NiptHomeComponent,
    IndividualNiptComponent,
    BluePinkComponent,
    // TrustboxComponent,
    MobilePhlebotomistComponent,
    PartnerComponent,
    LoaderComponent,
    ResourcesComponent,
    confirmationComponent,
    GbsComponent,
    ClinicFinderComponent,
    DiagnosticsHomeComponent,
    PathologyTestProfilesComponent,
    WellManProfileComponent,
    CovidTestComponent,
    CovidTestFilteredComponent,
    TermsAndConditionsComponent,
    FaqComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    CorporateTestingComponent,
    EmiratesTestComponent,
    IndividualBlogComponent,
    SexuallyTransmittedInfectionsComponent,
    StiDetailsPageComponent,
    BannerComponent,
    BoldPipe,
    BreadcrumbsComponent,
    CartPopverComponent,
    CartHandlerComponent,
    CovidorFluComponent,
    GenderDetailsPageComponent,
    UserProfileComponent,
    SignUpComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    CartComponent,
    AestheticsPageComponent,
    WellnessHomeComponent,
    MultivitaminDetailsComponent,
    WellnessProductsComponent,
    IndividualGbsComponent,
    AestheticsOfferingsComponent,
    UnsubscribeCrossPromotionalEmailsComponent,
    TestCodesComponent,
    ArchivedBlogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CarouselModule,
    TabViewModule,
    InputTextModule,
    MenubarModule,
    GMapModule,
    AccordionModule,
    SidebarModule,
    TooltipModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatTabsModule,
    TableModule,
    MultiSelectModule,
    RadioButtonModule,
    FileUploadModule,
    ToastModule,
    DialogModule,
    ShareModule,
    BreadcrumbModule,  
    CalendarModule,
    DropdownModule,
  ],
  providers: [GeneralService, MessageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
