import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { GeneralService } from '../general.service';
import * as bcrypt from 'bcryptjs';
import * as moment from 'moment';
const salt = bcrypt.genSaltSync(10);
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [MessageService]
})
export class SignUpComponent implements OnInit {

  registerForm!: FormGroup;
  submitted = false;
  hashedOTP: any;
  showOTP = false;
  OTPconfirmed = false;

  disableFullName = false;
  disableEmail = false;
  disableOTP = false;
  disablePassword = false
  emailAlreadyExist: boolean;
  otpNotValid = false;

  invalidZipcode = false;
  emptyzipcode = true;
  nextPAge = false;
  genders: any = [];
  todaydateTime = new Date();

  constructor(private formBuilder: FormBuilder, private router: Router, private generalService: GeneralService,
    private messageService: MessageService) {
    this.genders = [
      { name: 'Male' },
      { name: 'Female' },
      { name: 'Others' },
    ];
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.registerForm = this.formBuilder.group({
      email: [{ value: '', disabled: false }, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: [{ value: '', disabled: false }, [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$")]],
      fullname: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(4), Validators.pattern("^[a-zA-Z.]+([a-zA-Z. ]+)*$")]],
      phoneNumber: [{ value: '', disabled: false }, [Validators.required, Validators.pattern("^[0-9]{10,13}$")]],
      // gender: [{ value: '', disabled: false }, [Validators.required]],
      // dob: [{ value: '', disabled: false }, [Validators.required]],
      addressLineOne: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(4), Validators.pattern("^[a-zA-Z0-9\s/!,.@#$%^&*()]+([a-zA-Z0-9\s/!,.@#$%^&*() ]+)*$")]],
      addressLineTwo: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(4), Validators.pattern("^[a-zA-Z0-9\s/!,.@#$%^&*()]+([a-zA-Z0-9\s/!,.@#$%^&*() ]+)*$")]],
      city: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(4), Validators.pattern("^[a-zA-Z]+([a-zA-Z ]+)*$")]],
      country: [{ value: '', disabled: false }, [Validators.required, Validators.minLength(4), Validators.pattern("^[a-zA-Z]+([a-zA-Z ]+)*$")]],
      zipcode: [{ value: '', disabled: false }, [Validators.required]],
      otp: [{ value: '', disabled: false }, [Validators.required]],

    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.invalidZipcode || this.registerForm.controls.email.status === "INVALID" || this.registerForm.controls.fullname.status === "INVALID" || this.registerForm.controls.phoneNumber.status === "INVALID" || this.registerForm.controls.addressLineOne.status === 'INVALID' || this.registerForm.controls.addressLineTwo.status === 'INVALID' || this.registerForm.controls.city.status === 'INVALID' || this.registerForm.controls.country.status === 'INVALID' || this.registerForm.controls.zipcode.status === 'INVALID') {
      return;
    }
    else {
      let obj = {
        email: this.registerForm.controls.email.value
      }
      this.generalService.otpRequest(obj).subscribe(res => {
        if (res['status'] === "email address already exists") {
          this.emailAlreadyExist = true
          this.nextPAge = false;
          this.messageService.add({ severity: 'error', detail: 'Email address already exist.' });
        } else {
          this.registerForm.get('email').disable()
          this.registerForm.get('fullname').disable()
          this.registerForm.get('phoneNumber').disable()
          // this.registerForm.get('gender').disable()
          // this.registerForm.get('dob').disable()
          this.registerForm.get('addressLineOne').disable()
          this.registerForm.get('addressLineTwo').disable()
          this.registerForm.get('city').disable()
          this.registerForm.get('country').disable()
          this.registerForm.get('zipcode').disable()
          this.emailAlreadyExist = false
          this.hashedOTP = res[0];
          this.showOTP = true;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'OTP has been sent your email address' });
        }

      },
        err => {
          this.messageService.add({ severity: 'error', detail: 'Error sending OTP' });

        })
    }
  }


  submitOTP() {
    if (this.registerForm.controls.otp.status === "INVALID") {
      return;
    }
    else {
      const salt = bcrypt.genSaltSync(10);
      let compare = bcrypt.compareSync(this.registerForm.controls.otp.value, this.hashedOTP)
      if (compare) {
        this.OTPconfirmed = true
        this.registerForm.get('otp').disable()
        this.otpNotValid = false
      }
      else {
        this.otpNotValid = true
      }
    }
  }

  completesignUp() {
    this.submitted = true;
    if (this.registerForm.controls.password.status === "INVALID") {
      return;
    }
    else {
      let body = {
        id: 0,
        name: this.registerForm.controls.fullname.value,
        email: this.registerForm.controls.email.value,
        password: this.registerForm.controls.password.value,
        //about_me: this.registerForm.controls.gender.value.name,
        about_me: '',
        phone: JSON.stringify(this.registerForm.controls.phoneNumber.value),
        //DOB: moment(this.registerForm.controls.dob.value).format('YYYY-MM-DD'),
        DOB: '',
        address_line_1: this.registerForm.controls.addressLineOne.value,
        address_line_2: this.registerForm.controls.addressLineTwo.value,
        city: this.registerForm.controls.city.value,
        zipcode: this.registerForm.controls.zipcode.value,
        country: this.registerForm.controls.country.value,
        created_at: new Date()
      }

      this.generalService.usersignup(body).subscribe(res => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Account Created Successfully' });
        setTimeout(() => { this.router.navigate(['login']) }, 500);
      },
        err => {
          this.messageService.add({ severity: 'error', detail: 'Sign-In Unsuccessfull' });
        })
    }
  }

  login() {
    this.router.navigate(['login'])
  }

  zipcodeChecker(zipcode) {
    console.log(zipcode)
    if (zipcode === '') {
      this.emptyzipcode = true
    }
    else {
      this.emptyzipcode = false
    }
    this.generalService.validateZipCode(zipcode).subscribe(res => {
      if (res && res['status'] === 404) {
        this.messageService.add({ severity: 'error', detail: 'Invalid Zipcode' });
      }
      this.invalidZipcode = false;
    },
      err => {
        this.invalidZipcode = true
      })
  }

  onKeySearch(event: any) {
    // this.timeout = setTimeout(() => {
    if (event.keyCode != 13) {
      this.zipcodeChecker(event.target.value);
    }
    // }, 1000);
  }

  newuseremail() {
    this.emailAlreadyExist = false;
  }

  shownextpage() {
    if (this.registerForm.controls.email.status === "INVALID" || this.registerForm.controls.fullname.status === "INVALID" || this.registerForm.controls.phoneNumber.status === "INVALID") {
      this.submitted = true;
      return;
    }
    else {
      this.submitted = false;
      this.nextPAge = true;
    }
  }
}
