import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Carousel } from 'primeng/carousel';
@Component({
  selector: 'app-diagnostics-home',
  templateUrl: './diagnostics-home.component.html',
  styleUrls: ['./diagnostics-home.component.scss']
})
export class DiagnosticsHomeComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  diagnostics_details: any = [];
  constructor(private router: Router, private metaService: Meta, private titleService: Title) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/diagnostics') {
          this.metaService.updateTag({ name: 'description', content: 'Concepto Diagnostics is a leading diagnostics clinic in the UK for medical testing. We are a leading healthcare clinic providing private blood tests, Covid tests, STI Tests, and more pathology tests' });
          this.titleService.setTitle('Diagnostic Centre UK | Diagnostic Labs Test UK | Concepto Diagnostics');
        }
      }
    }) 
    Carousel.prototype.onTouchMove = () => { };
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Diagnostics"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    this.diagnostics_details = [
      // {
      //   title: 'Covid Tests',
      //   description:['Choose from our Coronavirus tests, Antigen test and the all new special China travel test packages.', 'Nationwide Clinic Locations.', 'UKAS accredited laboratory.', 'We work 7 days a week.'],
      //   button: 'BOOK NOW',
      //   image: '../../../../assets/images/contents/covid-banner.png'
      // },
      {
        title: 'Pathology Tests',
        description: ['Complete range of pathology tests.', 'Zero waiting time.', 'Zero delays.'],
        button: 'BOOK NOW',
        image: '../../../../assets/images/contents/diagnosticbanner.png'
      },
      // {
      //   title: 'China Travel Tests',
      //   description: ['Travel certificates for the 2 tests from two different institutions', 'Book the suitable test package and pay for both tests at a discounted prices'],
      //   button: 'BOOK NOW',
      //   image: '../../../../assets/images/contents/china-travel-test.png'
      // },
      // {
      //   title: 'Sexually Transmitted Infections',
      //   description: ['Concepto Diagnostics brings to you a quick and discreet way of keeping track of your sexual health. Our home testing kits are easy to use and contain clear, step-by-step procedures. Order from the convenience and privacy of your home, collect the sample and send it back to us. Our in-house lab will run the test and provide results in 24 hours once the samples are received.'],
      //   button: 'BOOK NOW',
      //   image: '../../assets/images/sti/Mask Group 100.png'
      // },
    ];
  }

  navigate(){
  }

  booknow(type){
    if(type === 'Covid Tests'){
      this.router.navigate(['diagnostics/covid-test'])
    }
    if(type === 'Private Blood Tests'){
      this.router.navigate(['diagnostics/pathology-test-profiles'])
    }
    if(type === 'China Travel Tests'){
      this.router.navigate(['diagnostics/china-travel-test'])
    }
    if(type === 'Sexually Transmitted Infections'){
      this.router.navigate(['diagnostics/sti'])
    }
    if(type === 'Postal Tests'){
      this.router.navigate(['diagnostics/antigen-home-kits'])
    }

  }
}
