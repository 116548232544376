import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blue-pink',
  templateUrl: './blue-pink.component.html',
  styleUrls: ['./blue-pink.component.scss']
})
export class BluePinkComponent implements OnInit {

  constructor(private router: Router,  private metaService: Meta, private titleService: Title,) { 
    this.metaService.updateTag({ name: 'description', content: "Concepto Diagnostics offers the gender blood tests and gender prediction tests in the UK. Trust us for accurate results and the best pregnancy test experience." });
    this.metaService.updateTag({ name: 'keywords', content: 'Gender Blood Test UK, Gender Prediction Test UK, Best Pregnancy test UK' });
    this.titleService.setTitle('Gender Blood Test UK | Gender Prediction Test UK | Concepto Diagnostics');
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Genomics"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
  }

  imagingprofile(input?) {
    if (input === 'home-kit') {
      window.open('https://buy.stripe.com/3cscQ71Hg4423HqeUU', '_blank');
      // window.open('https://amzn.to/46BCmAC', '_blank');
    } else if (input === 'in-clinic') {
      window.open('https://conceptodiagnostics.as.me/?appointmentType=39660965', '_blank');
    }else if (input === 'home-service') {
      window.open('https://buy.stripe.com/14k3fxeu2586gucbIJ', '_blank');
    } 
  }
}
