import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  toggle: boolean = true;
  registerForm!: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder, private router: Router) { }


  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.registerForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$")]],
      confirmPassword: ['', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$")]],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }


  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      console.log("invalid password")
      return;
    }
    else {
      console.log("Password generated", this.registerForm.controls)
      if (this.registerForm.controls.password.value === this.registerForm.controls.confirmPassword.value) {
        console.log("password are same")
        this.login();
      } else {
        console.log("password are different")
      }
    }
  }


  login() {
    this.toggle = false;

  }

  reset() {
    this.toggle = false;
    // this.fun = true;
  }

  confirm() {
    this.router.navigate(['login'])
    // this.hide = true;
    this.toggle = false;
    // this.fun = false;
  }


}
