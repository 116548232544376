import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppConstants } from '../app.constants';
import { GeneralService } from '../general.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
  password: any;
  email: any;
  loginForm!: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder, private router: Router, private generalService: GeneralService,
    private messageService: MessageService, @Inject(DOCUMENT) private dom) {
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$")]],
    });
  }
  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  signup() {
    this.router.navigate(['sign-up'])
  }

  login() {
    //pass your formData here 
    this.submitted = true;
    if (this.loginForm.invalid) {
      console.log(this.loginForm)
      return;
    }
    else {
      let body = {
        email: this.loginForm.controls.email.value,
        password: this.loginForm.controls.password.value,
        remember: true
      }
      this.generalService.submitLogin(body).subscribe(async (res) => {
        if (res['token'] && res['token']['access_token']) {
          this.generalService.setCookie('coc_user', this.loginForm.controls.email.value, 2)
          this.generalService.setCookie('coc_token', res['token']['access_token'], 2)
          let tempUser = this.generalService.getCookie('coc_temp_user')
          if (tempUser) {
            this.generalService.updateCartByTempUser('temp', tempUser, this.loginForm.controls.email.value).subscribe(async (res) => {
              // console.log(res)
            })
          }
          //redirect to profile is user has come from menu
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Successfully Logged In' });
          setTimeout(() => {
            this.router.navigateByUrl(`profile`);
          }, 500);
          //redirect back to same url user had come from if user has come from cart
        } else {
          //display error
          this.messageService.add({ severity: 'error', detail: 'Login Unsuccessfull' });
        }
      },
        err => {
          this.messageService.add({ severity: 'error', detail: 'Login Unsuccessfull' });
        })
    }
  }

  reset() {
    this.router.navigate(['forgot-password'])
  }
}
