import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GeneralService } from './general.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Meta, Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'landing-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('scroll', [
      state('on', style({right: '100%'})),
      transition('* => *', [
        style({right: '-1150px'}),
        animate(40000, style({right: '100%'}))
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  title = 'landing';
  showBookNow = false;
  showAesthetics: boolean = false;
  state = 0;
  constructor(private router: Router, private generalService: GeneralService, private meta: Meta,
    private titleService: Title) {
    if (this.generalService.getCookie('coc_user') && this.generalService.getCookie('coc_token')) {
    } else {
      this.generalService.clearCookie()
    }
    if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
      this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')), 2)
    } else {
      this.generalService.setCookie('coc_cart_details', 0, 2)
    }

    

  }

  ngOnInit(): void {
    this.router.events.subscribe(value => {
      if (this.router.url.toString().includes('/aesthetics')) {
        this.showAesthetics = true;
      } else {
        this.showAesthetics = false;
      }
    })

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.setCanonical();
    });
  }

  setCanonical(): void {
    const currentUrl = this.router.url;

    // Get the current hostname (e.g., www.example.com or example.com)
    const hostname = window.location.hostname;

    // Check if 'www' is in the hostname
    const isWWW = hostname.startsWith('www.');

    // Define the base URL (without the protocol)
    let canonicalUrl = `${window.location.protocol}//${hostname}${currentUrl}`;
    // If you want to ensure 'www' is included in the canonical URL
    if (!isWWW) {
      // Add 'www' to the hostname if it's missing
      canonicalUrl = canonicalUrl.replace('//www.', '//');
    }

    // Remove any existing canonical tag and add the new one
    this.meta.removeTag('rel="canonical"');
    this.meta.addTag({
      rel: 'canonical',
      href: canonicalUrl
    });
  }

  scrollDone() {
    this.state++;
  }
}
