import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../general.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-unsubscribe-cross-promotional-emails',
  templateUrl: './unsubscribe-cross-promotional-emails.component.html',
  styleUrls: ['./unsubscribe-cross-promotional-emails.component.scss']
})
export class UnsubscribeCrossPromotionalEmailsComponent implements OnInit {
  email: string;
  token: string;
  type: string;
  isUnsubscribed: boolean = false; 
  isInvalid: boolean = false;

  constructor(private http: HttpClient, private generalService: GeneralService, private route: ActivatedRoute) {}
  

  ngOnInit(): void {
    // Retrieve route parameters (email, token, type) from the URL
    this.route.queryParams.subscribe((params) => {
        this.email = params['email'];
        this.token = params['token'];
        this.type = params['type'].replace(/"$/, ''); 
      });

      console.log("this.token==",this.token)
    this.generalService.checkIfEmailUnsubscribed(this.email, this.type, this.token).subscribe(
      (res) => {
        // Handle the response, e.g., display a success mes sage
        console.log('Unsubscribe successful', res);
        if(res['isUnsubscribed']){
          this.isUnsubscribed = true
        }else if(res['isInvalid']){
          this.isInvalid = true
        }else{
          this.isUnsubscribed = false
        }
      },
      (error) => {
        this.isInvalid = true
        // Handle any errors, e.g., display an error message
        console.error('Unsubscribe failed', error);
      }
    );

    
  }

  unsubscribe() {
    let obj ={
      email: this.email,
      type: this.type,
      token: this.token
    }
    this.generalService.unsubscribCrossePromotionalEmails(obj).subscribe(res => {
      if(res['isInvalid']){
        this.isInvalid = true
      }else{
        this.isUnsubscribed = true
      }
      console.log('Unsubscribe successful', res);
    },
      err => {
        this.isInvalid = true
        console.error('Unsubscribe failed', err);

      })
  }

 

}

