import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../app.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class confirmationComponent implements OnInit {
  status = ''
  constructor(private router: Router) {
    this.status = this.router.url.split("/")[this.router.url.split("/").length - 1]
  }

  ngOnInit(): void {
  }

  homepage() {
    document.location.href = `${AppConstants.BASE_URL_HOME}`
  }

}
