import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { GeneralService } from '../general.service';
// declare global {
//   interface Window {
//     Trustpilot: any;
//   }
// }
@Component({
  selector: 'app-pathology-test-profiles',
  templateUrl: './pathology-test-profiles.component.html',
  styleUrls: ['./pathology-test-profiles.component.scss']
})
export class PathologyTestProfilesComponent implements OnInit {

  test_and_profile: any;
  index_view: any;
  view_more = true;
  customerTestArray: any = [];

  constructor(private router: Router, private generalService: GeneralService,
    private metaService: Meta, private titleService: Title) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/diagnostics/pathology-test-profiles') {
          this.metaService.updateTag({ name: 'description', content: 'Get accurate and reliable private blood test results at Concepto Diagnostics, a trusted private blood test clinic in the UK. Book your appointment today and experience our expert medical care. ' });
          this.metaService.updateTag({ name: 'keywords', content: 'Private blood test UK' });
          this.titleService.setTitle('Private Blood Test Clinic in UK| Private Blood Test UK |Concepto Diagnostics');
        }
      }
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Pathology Tests"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)


    this.generalService.getProducts().subscribe((res) => {
      this.test_and_profile = []
      res.forEach((element, i) => {
        if (element.productCategory.category === "Blood Test") {
          let biomarkers = JSON.parse(element.extra_description_three).length;
          let object = {
            title: element.name,
            code: element.short_description,
            contents: JSON.parse(element.extra_description_three),
            biomarkers: biomarkers,
            type: 'In Clinic Test',
            eta: JSON.parse(element.extra_description_one)[0].detail,
            price: element.price,
            about: JSON.parse(element.extra_description_two)[0].detail,
            url: element.url
          }
          this.test_and_profile.push(object)
        }
      })
      this.customerTestArray = this.test_and_profile;
    })


  }

  ngAfterContentInit() {
    // setTimeout(() => {
    //   const trustboxRef = document.getElementById('trustbox');
    //   if (window.Trustpilot) {
    //     window.Trustpilot.loadFromElement(trustboxRef);
    //   }
    // }, 500)
  }

  viewmore(index) {
    this.index_view = index;
    this.view_more = false;
  }

  viewless(index) {
    this.view_more = true;
    this.index_view = null;
  }

  bookNow(data) {
    this.router.navigate(['diagnostics/profile'], { queryParams: { test: data.url } });
  }

  search(input) {
    if (input !== "") {
      input = input.toLowerCase();
      let array = this.test_and_profile.filter(x => x.title.toLowerCase().includes(input));
      this.customerTestArray = array;
    }
    else {
      this.customerTestArray = this.test_and_profile;
    }
  }

}
