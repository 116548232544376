import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-archived-blogs',
  templateUrl: './archived-blogs.component.html',
  styleUrls: ['./archived-blogs.component.scss']
})
export class ArchivedBlogComponent implements OnInit {

  carddetails = []
  trendingarticles = []
  customerTestArray: any;
  constructor(private router: Router, @Inject(DOCUMENT) private dom) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if (menuitem[i]["outerText"] === "Blog") {
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    this.carddetails = [
      {
        title: "What is functional testing in nutrition and medicine?",
        image: "What-is-functional-testing-in-nutrition-and-medicine.png",
        shortdescription: "In some cases ailments are easy to identify and address, allowing you to quickly uncover the root cause and adapt your diet or lifestyle accordingly. Often, however, there is greater complexity and there does not appear to be a rhyme or reason in these situations. This is where functional testing can be extremely beneficial.",
        link: "What-is-functional-testing-in-nutrition-and-medicine"
      }, 
      {
        title: "Nourishing Your Heart: A Guide to Eating for Optimal Cardiovascular Health",
        image: "Nourishing-Your-Heart.png",
        shortdescription: "Cardiovascular disease is a major global health concern and the leading cause of death worldwide, accounting for a quarter of all deaths in the UK every year. The good news is that it's largely preventable with lifestyle changes and a heart-healthy diet. This article will help you understand the key components of a heart-healthy diet and how to implement it to reduce your risk of developing cardiovascular disease and improve your overall heart health.",
        link: "Nourishing-Your-Heart"
      }, 
      {
        title: "Vaginal Health for Fertility",
        image: "Vaginal-Health-for-Fertility.png",
        shortdescription: "We believe that conceiving during reproductive years should be one of the simplest things, however modern-day life throws in many hurdles such as environmental pollution, ultra-processed foods, chronic stress, nutrient-depleted soil and other factors which influence fertility. These factors can influence overall health, including the vaginal microbiome, which increasing research shows plays an important role in fertility and a healthy pregnancy.",
        link: "Vaginal-Health-for-Fertility"
      },
      {
        title: "Nutritional Management of Hashimoto's Thyroiditis",
        image: "Nutritional-Management-of-Hashimotos-Thyroiditis.png",
        shortdescription: "Hashimoto's Thyroiditis is an autoimmune condition which affects the thyroid gland. In an autoimmune disorder, the immune system attacks the body's own tissues and organs and in the case of Hashimoto's thyroiditis, the thyroid gland is attacked, causing inflammation and damage to the gland.",
        link: "Nutritional-Management-of-Hashimotos-Thyroiditis"
      },
      {
        title: "How to create a healthy workplace - Nutrition and Hydration Week 2023",
        image: "How-to-create-a-healthy-workplace-Nutrition-and-Hydration-Week-2023.png",
        shortdescription: "We consider our life in the workplace and our life outside it as two separate entities. Many of us who love to keep active, eat nourishing foods and practice stress management fail to incorporate these into the 9-5.",
        link: "How-to-create-a-healthy-workplace-Nutrition-and-Hydration-Week-2023"
      },
      {
        title: "Nutrition MOT: Everything you need to know",
        image: "Nutrition-MOT-Everything-you-need-to-know.png",
        shortdescription: "The Nutrition MOT is one of the most comprehensive functional tests we offer and is one of the most popular tests among nutritionists and other practitioners as it provides a detailed view of the physiological functions of the body.",
        link: "Nutrition-MOT-Everything-you-need-to-know"
      },
      {
        title: "The fundamentals of a vegan diet - by a nutritionist",
        image: "The-fundamentals-of-a-vegan-diet.png",
        shortdescription: "The advances we have seen in medicine over the last century have been incredible; however, most of us would likely agree that prevention of disease is better than a cure. This is the driver for preventative medicine. ",
        link: "The-fundamentals-of-a-vegan-diet"
      },
      {
        title: "NEW YEAR, NEW HABITS",
        image: "NEW-YEAR-NEW-HABITS.png",
        shortdescription: "An estimated 58% of the UK will be making New Year's resolutions this month, but only 28% will stick to all of them! Often the problem is, the change is too drastic and unrealistic, creating a sense of failure when it can't be followed perfectly.",
        link: "NEW-YEAR-NEW-HABITS"
      },
      {
        title: "Ashwagandha: All of your questions answered",
        image: "Ashwagandha-All-of-your-questions-answered.png",
        shortdescription: "Ashwagandha is the adaptogenic herb that's gaining increasing popularity for supporting those with high stress levels. Commonly known as Indian Ginseng, ashwagandha has been in use for thousands of years in ayurvedic medicine as a nervous system support.",
        link: "Ashwagandha-All-of-your-questions-answered"
      },
      {
        title: "What is preventative medicine?",
        image: "What-is-preventative-medicine.png",
        shortdescription: "The advances we have seen in medicine over the last century have been incredible; however, most of us would likely agree that prevention of disease is better than a cure. This is the driver for preventative medicine. ",
        link: "What-is-preventative-medicine"
      },
      {
        title: "5 immune system myths debunked",
        image: "5-immune-system-myths-debunked.png",
        shortdescription: 'A quick internet search for "how to boost the immune system" will yield millions of results, but very few are research-based and the majority of them conflict with one another. Keeping your body strong and healthy is at the fore-front of of our minds, for obvious reasons, but how can we achieve this? What can we actually believe about supporting the immune system? What will help us and what will just hurt our wallets? We are here to bust the five most common myths.',
        link: "5-immune-system-myths-debunked"
      },
      {
        title: "How to stay healthy over the festive period",
        image: "How-to-stay-healthy-over-the-festive-period.png",
        shortdescription: "The festive period brings with it, time with loved ones, hectic social plans and, a change in food and drinks enjoyed. With these changes, we might notice a drop in energy levels, sluggish digestion and trouble with weight management. This doesn't have to be the case and you can enjoy all the tasty food and drink you love while incorporating a few practices that can help to keep you healthy!",
        link: "How-to-stay-healthy-over-the-festive-period"
      },
      {
        title: "How can I improve my gut health?",
        image: "How-can-I-improve-my-gut-health.png",
        shortdescription: "Gut health encompasses everything involved in the digestion and absorption of nutrients, all the way from our mouth to our large intestine and onward. The gut is involved in almost every aspect of our health, including immune function, mental wellbeing, skin health, weight management and lungs.",
        link: "How-can-I-improve-my-gut-health"
      },
      {
        title: "Can you cure IBS?",
        image: "Can-you-cure-IBS.png",
        shortdescription: "Irritable bowel syndrome (IBS) is a functional intestinal disorder that affects your digestive system and results in symptoms of bloating, gas, indigestion, abdominal pain, constipation, diarrhoea, etc. It affects approximately one in five people and it is twice as common in women than men.",
        link: "Can-you-cure-IBS"
      },
      {
        title: "Expert explains: do multivitamins really work",
        image: "Expert-explains-do-multivitamins-really-work.png",
        shortdescription: "Multivitamin and mineral formulas are some of the most popular food supplements in the world. These supplements typically cover a wide range of vitamins and minerals, sometimes adding in herbs or friendly bacteria for an additional boost.",
        link: "Expert-explains-do-multivitamins-really-work"
      },
      {
        title: "Should you go for a cholesterol check-up?",
        image: "Should-you-go-for-a-cholesterol-check-up-1.png",
        shortdescription: "Cholesterol is a type of lipid, i.e., fat, created by the liver and found in our blood. We often only hear about cholesterol when referring to the negative effects of its elevated levels.",
        link: "Should-you-go-for-a-cholesterol-check-up"
      },
      {
        title: "What are the symptoms of hormonal imbalance in women?",
        image: "What-are-the-symptoms-of-hormonal-imbalance-in-women.png",
        shortdescription: "Discussions around female hormones usually centre around fertility and their reproductive function. While this is of huge importance, hormonal balance plays far more roles within the body and influences every aspect of women's health.",
        link: "What-are-the-symptoms-of-hormonal-imbalance-in-women"
      },
      {
        title: "Five energy boosting micronutrients and where to find them",
        image: "Five-energy-boosting-micronutrients-and-where-to-find-them.png",
        shortdescription: "Typically, when you're tired and in need of some focus it's common to reach for a coffee or an energy drink to get that instant energy boost from the caffeine in them. Have you ever wondered - What if you didn't feel sluggish in the first place? Good sleep, exercise and hydration can support good energy levels throughout the day, but diet plays a big role in this too. Nutrient deficiencies are a common cause of low energy.",
        link: "Five-energy-boosting-micronutrients-and-where-to-find-them"
      },
      {
        title: "How does the immune system actually work?",
        image: "how-does-the-immune-system-actually-work.png",
        shortdescription: "A weak immune system isn't something any of us want, but the internet's advice on boosting your immune system can't always be trusted. That is why we have compiled a list of the ways you can support your immune system that are actually backed by science!",
        link: "how-does-the-immune-system-actually-work"
      },
      {
        title: "Scientifically proven ways to boost your immune system",
        image: "scientifically-proven-ways-to-boost-your-immune-system-1.png",
        shortdescription: "A weak immune system isn't something any of us want, but the internet's advice on boosting your immune system can't always be trusted. That is why we have compiled a list of the ways you can support your immune system that are actually backed by science!",
        link: "scientifically-proven-ways-to-boost-your-immune-system"
      },
      {
        title: "5 easy ways to instantly improve your well-being",
        image: "5-easy-ways-to-instantly-improve-your-well-being.png",
        shortdescription: "Being well is not the absence of illness or stress. It is a common misconception that if you are not suffering from a disorder you don’t have to take care of your well-being and mental health. Wellness is actually, an ongoing process where you are aware of living a healthy and energetic life. ",
        link: "5-easy-ways-to-instantly-improve-your-well-being"
      },
      {
        title: "Concepto acquires Amelia Amour",
        image: "acquisition.png",
        shortdescription: "Concepto Diagnostics is an award winning diverse British healthcare company with a decade of experience in serving the preventative healthcare needs of customers. We have served thousands of patients and several reputed corporate clients at our CQC regulated clinics backed by an in-house UKAS accredited laboratory. ",
        link: "Concepto-acquires-Amelia-Amour"
      },
      {
        title: "10 tips to reduce stress now",
        image: "stress-blog.png",
        shortdescription: "Do you feel overwhelmed with the pace of your life or situations around you? Or maybe inundated with technology and the pressure to constantly be connected with your work?",
        link: "10-tips-to-reduce-stress-now"
      },
      {
        title: "What are the travel rules for returning to the UK in this Covid-19 pandemic?",
        image: "travel rules for returning to the UK-2.png",
        shortdescription: "Summary: Travelling abroad during this pandemic entails taking Covid-19 tests. Returning from a holiday or business trip could require even more tests!",
        link: "What-are-the-travel-rules-for-returning-to-the-UK-in-this-Covid-19-pandemic"
      },
      {
        title: "Returning to the UK from abroad? Testing and quarantine rules you should know",
        image: "returning to UK from abroad-2.png",
        shortdescription: "Summary: To safeguard public health and to contain the spread of Covid-19, the UK Government has implemented some Covid testing and quarantine rules for international arrivals to the UK. Want to know what they are?",
        link: "Returning-to-the-UK-from-abroad-Testing-and-quarantine-rules-you-should-know"
      },
      {
        title: "Covid-19 antigen test: Everything you need to know",
        image: "Covid-19 antigen test-2.png",
        shortdescription: "The Rapid Antigen test for detecting the Covid-19 virus is commonly known as Antigen test and is gaining popularity for giving quick results that are reliable and accurate. The most well-known Covid-19 test clinic in the UK explains everything you ever wanted to know about Antigen tests.",
        link: "Covid-19-antigen-test-Everything-you-need-to-know"
      },
      {
        title: "What should a Covid infected patient eat during self-isolation?",
        image: "food during self-isolation-2.png",
        shortdescription: "Covid-19 is a serious disease that has caused uncountable deaths ever since it emerged two years ago. The adverse impact on survivors' health post Covid has been documented in detail by experts.",
        link: "What-should-a-Covid-infected-patient-eat-during-self-isolation"
      },
      {
        title: "Dos and dont's in the times of Coronavirus",
        image: "dos and dont-2.png",
        shortdescription: "The Covid pandemic has had a deep and long-term impact on the ways we serve, socialize, exalt, grieve, and most essentially live. After the decrease of Covid-19 infection in the UK, we, the citizens are facing a common dilemma of what to do and what to avoid doing in order to cope with the new normal.",
        link: "Dos-and-donts-in-the-times-of-Coronavirus",
      },
      {
        title: "Long term effects of Covid-19 post recovery",
        image: "effects of Covid-19-2.png",
        shortdescription: "Recent studies on Covid-19 have found that people with the mildest symptoms for Covid-19 may suffer from its long-term effects even after several months of recovery. There are high possibilities that coronavirus will leave some long-term bodily complications if someone has been infected by it.",
        link: "Long-term-effects-of-Covid-19-post-recovery"
      },
      {
        title: "Be aware of Covid-19 as the pandemic is not over yet",
        image: "Be_aware_of_covid_2.png",
        shortdescription: "As 2021 is coming to an end, the entire world is wondering if the pandemic finally ended after two long years? Just like at the end of 2020, a new variant emerged and made the situation in the UK worse, people are wary of a new wave of infections.",
        link: "Be-aware-of-Covid-19-as-the-pandemic-is-not-over-yet"
      },
      {
        title: "Brighter Sides of the Pandemic in Our Life",
        image: "Brighter Sides of the Pandemic-2.png",
        shortdescription: "With something as deadly as the coronavirus, it is difficult to see the positive impact that it may have had in our life. When nature's fury was witnessed at its peak with the spread of this virus, it became quite depressing to see your dear and loved ones suffer and lose them.",
        link: "Brighter-Sides-of-the-Pandemic-in-Our-Life"
      },
      {
        title: "Is there a chance of getting affected after being fully vaccinated?",
        image: "fully vaccinated-2.png",
        shortdescription: "One of the most frequently asked questions by people nowadays is that whether it is still possible to get an infection after being fully vaccinated. There has been a steady decrease in the number of people coming to hospitals and COVID-19 test clinics in the UK, as more and more people have been fully vaccinated.",
        link: "Is-there-a-chance-of-getting-affected-after-being-fully-vaccinated"
      },
      {
        title: "Why Covid-19 testing is important at workplaces during the Pandemic?",
        image: "Covid-19 testing at workplaces-2.png",
        shortdescription: "It's been almost two years since the pandemic struck. Ever since it started, there has been a drastic change in our lifestyles. Although going out and gathering in crowded places has not stopped completely but people are wary while stepping out and are forced to take several precautions.",
        link: "Why-Covid-19-testing-is-important-at-workplaces-during-the-Pandemic"
      },
      {
        title: "What are the precautions to undertake while waiting for a Covid-19 test report?",
        image: "covid-19 precautions-2.png",
        shortdescription: "There remains some confusion regarding what to do after getting tested for Covid-19 until the test result comes. Nobody knows whether the report would come positive or negative after getting tested.",
        link: "What-are-the-precautions-to-undertake-while-waiting-for-a-Covid-19-test-report"
      },
      {
        title: "6 Things to do if you test positive for Covid-19",
        image: "Things to do if you test positive-2.png",
        shortdescription: "Though the spread of Coronavirus is on the decline in the UK, people are still getting affected if they do not take necessary precautions for preventing infection from the virus. In such cases, if you test positive for Covid-19, do not panic.",
        link: "things-to-do-if-you-test-positive-for-Covid-19"
      },
      {
        title: "Statistics and Facts about Coronavirus in the UK",
        image: "Coronavirus in the UK-2.png",
        shortdescription: "Summary: There are more than 7,168,806 cases of Coronavirus in the UK. The disease has caused deaths of over 133,988 people in the country.",
        link: "Statistics-and-Facts-about-Coronavirus-in-the-UK"
      },
      {
        title: "6 Expert safety tips for safe travel during Covid-19",
        image: "safety tips for safe travel-2.png",
        shortdescription: "Traveling during Covid-19 is way different from how it was, back in pre-Covid times. Some additional steps have been added to the journey of traveling as part of the safety measures for the coronavirus.",
        link: "Expert-safety-tips-for-safe-travel-during-Covid-19"
      },
      {
        title: "5 Tips to slowly resume normal life post Covid-19",
        image: "resume normal life-2.png",
        shortdescription: "With the passage of time, people living in UK are trying to get back to a normal lifestyle. Trades, small businesses, and other institutions are reopening following all the necessary guidelines of Covid-19.",
        link: "Tips-to-slowly-resume-normal-life-post-Covid-19"
      },
      {
        title: "4 Ways to Manage Your Mental Health During Pandemic",
        image: "Manage Your Mental Health-2.png",
        shortdescription: "Two years and multiple variants and waves of infections of Covid have started affecting our normal lives and mental health.",
        link: "Ways-to-Manage-Your-Mental-Health-During-Pandemic"
      },
      {
        title: "How to Understand the Difference Between COVID-19 and Flu?",
        image: "Difference Between COVID-19 and Flu-2.png",
        shortdescription: "As newer variants of Covid-19 emerge, it is becoming more difficult to tell the difference between Covid19 and the flu. The most telling sign of Covid which is the loss of smell and taste is not at all significant for Omicron.",
        link: "How-to-Understand-the-Difference-Between-COVID-19-and-Flu"
      },
      {
        title: "Tips On How to Care for Your Child as School Reopens",
        image: "Care for Your Child-2.png",
        shortdescription: "All over the world, as the number of infected patients recedes, and the number of people including children getting vaccinated increases, schools are being opened.",
        link: "Tips-On-How-to-Care-for-Your-Child-as-School-Reopens"
      },
      {
        title: "4 Lessons Learned from COVID-19 for Improving Health Care Delivery",
        image: "Lessons Learned from COVID-19-2.png",
        shortdescription: "The United Kingdom was facing the peak of the COVID-19 pandemic in March 2020. Through the pandemic, we all have learnt a number of lessons.",
        link: "Lessons-Learned-from-COVID-for-Improving-Health-Care-Delivery"
      },
      {
        title: "Debunking Coronavirus prevention and treatment myths",
        image: "Coronavirus prevention and treatment myths-2.png",
        shortdescription: "There is widespread debate about the treatment and prevention of Covid-19. Therefore, a lot of misinformation continues to circulate among the masses about ways to prevent infection of the novel coronavirus and how to treat it.",
        link: "Debunking-Coronavirus-prevention-and-treatment-myths"
      },
      {
        title: "Tips for handling Covid-19 infected patients at home",
        image: "Covid-19 infected patients at home-2.png",
        shortdescription: "The spread of Covid-19 transmission has fallen worldwide. Hospitalizations have reduced as new infections have reduced as well as severity of symptoms, in most cases.",
        link: "Tips-for-handling-Covid-19-infected-patients-at-home",
      },
      {
        title: "5 must- do post Covid activities you should know",
        image: "Covid activities you should know-2.png",
        shortdescription: "Recovering from COVID usually takes around 2 weeks for mild cases. But it’s important to note that even after testing negative, affected persons should be cautious about what they do in the next few days, or during the post-Covid phase.",
        link: "must-do-post-Covid-activities-you-should-know"
      },
      {
        title: "How does Covid-19 antibody testing benefit you?",
        image: "Covid-19 antibody testing-2.png",
        shortdescription: "Antibody testing for Covid-19 or the Serology test has been the subject of intense discussion since the Coronavirus has emerged worldwide. A large number of people still have some doubts or queries regarding antibody testing and its benefits.",
        link: "How-does-Covid-19-antibody-testing-benefit-you"
      },
      {
        title: "What to do if your child is down with Covid-19?",
        image: "child is down with Covid-19-2.png",
        shortdescription: "Did you know fewer cases of Covid-19 have been reported in children than in adults? Many medical practitioners have opined that, children are less likely to get infected with Covid-19.",
        link: "What-to-do-if-your-child-is-down-with-Covid"
      },
      {
        title: "After-effects in Covid-19 infected patients",
        image: "After-effects in Covid-19 patients-2.png",
        shortdescription: "Recent data says, a person infected with coronavirus may face mild symptoms or sometimes even be completely asymptomatic. How long the virus lasts in someone’s body varies from person to person, or upon the exposure to the virus, and the severity of the viral infection",
        link: "After-effects-in-Covid-19-infected-patients"
      },
      {
        title: "Understanding how the coronavirus vaccines work",
        image: "how the coronavirus vaccines work-2.png",
        shortdescription: "Since the vaccines for Covid-19 have been made available to common folks, people have one common question about the vaccines. Do the vaccines really work? And, if they do, how do they work against Covid-19?",
        link: "Understanding-how-the-coronavirus-vaccines-work"
      },
      {
        title: "Covid-19 pandemic and public mental health",
        image: "Covid-19 pandemic and public mental health-2.png",
        shortdescription: "The worldwide rapid rise of the coronavirus pandemic has already introduced a considerable degree of fear, worry, and concern in the population at large and among certain groups of people in particular, such as older adults, care providers, and people with certain underlying health conditions.",
        link: "Covid-19-pandemic-and-public-mental-health"
      },
      {
        title: "5 highly-discussed myths and facts about Covid-19",
        image: "myths and facts about Covid-19-2.png",
        shortdescription: "In the last 2 years, we have spent copious amounts of time-consuming information about the novel coronavirus, its spread, its symptoms and side-effects. One of the top searches on the internet by netizens is the ways and means to prevent this virus from getting transmitted.",
        link: "highly-discussed-myths-and-facts-about-Covid"
      },
      {
        title: "What are the long-term implications of Covid-19?",
        image: "long-term implications of Covid-19-2.png",
        shortdescription: "For most people, symptoms of mild or moderate cases of Covid-19 last about two weeks while others experience some lingering health problems even after recovering from the illness.",
        link: "What-are-the-long-term-implications-of-Covid-19"
      },
      {
        title: "Important guidelines of Covid-19 for pregnant women",
        image: "Covid-19 for pregnant women-2.png",
        shortdescription: "The Covid-19 pandemic is the biggest global health crisis of the century and the greatest challenge we have faced so far. In such a dire situation, a bunch of questions often comes to our minds regarding our own safety.",
        link: "Important-guidelines-of-Covid-19-for-pregnant-women"
      },
      {
        title: "Is it important to sanitize your mobile phones?",
        image: "sanitize your mobile phones-2.png",
        shortdescription: "Did you know that on an average people remain busy on their mobile phones for more than five hours a day? It is not surprising that this device has become a lifeline to most of us in our daily packed schedules.",
        link: "Is-it-important-to-sanitize-your-mobile-phones",
      },
      {
        title: "4 tips that help you remain focused when you are working from home",
        image: "focused when working from home-2.png",
        shortdescription: "With the sudden onset of Covid-19, almost every business in the United Kingdom overnight asked employees to work from home. Though after multiple waves of Covid-19 over two years, companies have cautiously gotten employees back to the workplace.",
        link: "tips-that-help-you-remain-focused-when-you-are-working-from-home"
      },
      {
        title: "How to Create Healthy Lifestyles for Teens During the Age of Covid-19?",
        image: "Healthy Lifestyles for Teens-2.png",
        shortdescription: "It is an undeniable fact that the Covid-19 pandemic has turned the entire world on its head. From the sudden suspension of schools to families scattered in different places, teenagers are struggling to have a regular and normal life during the time of Covid-19.",
        link: "How-to-Create-Healthy-Lifestyles-for-Teens-During-the-Age-of-Covid-19"
      },
    ]
    this.customerTestArray = this.carddetails;

    this.trendingarticles = [ {
      shortdescription: "Non invasive screening test for Downs syndrome in UK",
      image: "Downs-Syndrome-test-in-UK.png",
      link: "Downs-Syndrome-test-in-UK"
    },
    {
      shortdescription: "NIPT test accuracy - detailed statistics and limitations",
      image: "NIPT-test-accuracy-detailed-statistics-and-limitations.png",
      link: "NIPT-test-accuracy-detailed-statistics-and-limitations"
    },
    {
      shortdescription: "Most advanced NIPT test during pregnancy in the UK",
      image: "Most-advanced-NIPT-test-during-pregnancy-in-the-UK.png",
      link: "Most-advanced-NIPT-test-during-pregnancy-in-the-UK"
    }
    ]
  }


  search(input) {
    if (input !== "") {
      input = input.toLowerCase();
      let array = this.carddetails.filter(x => x.title.toLowerCase().includes(input));
      this.customerTestArray = array;
    }
    else {
      this.customerTestArray = this.carddetails;
    }
  }


  individualblog(data) {
    this.router.navigateByUrl(`/individual-blog/${data.link}`);
  }

}
