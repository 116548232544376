import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '../app.constants';

@Component({
  selector: 'banner-component',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  displayModal: boolean;
  constructor(private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.displayModal = true;
    }, 700);
  }

  navigateTo() {
    this.router.navigate(['profile'], {queryParams: { test: 'Urgent-PCR-Travel-Test-same-day-results' }});
    this.displayModal = false;
  }

}
