import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../app.constants';
import { GeneralService } from '../general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cartbutton',
  templateUrl: './cartbutton.component.html',
  styleUrls: ['./cartbutton.component.scss']
})
export class CartbuttonComponent implements OnInit {
  card_products: any[];
  removedProducts: any[];
  no_of_items: any;
  cartDiscount = '0.00';
  total_amount: any;
  total_price: any;
  vat: any;
  total_price_display = '0.00';
  page_loader = false;
  discountPercentage = 0;
  vatpercent = '0';
  showcard: boolean = false;


  constructor(private generalService: GeneralService, private router: Router) {
    this.generalService.listenCookieChange(({ oldValue, newValue }) => {
      if (newValue && newValue.length > 0) {
        let value = newValue.split(';')
        if (value && value.length > 0) {
          value.forEach(element => {
            if (element.includes('coc_cart_details')) {
              let cartstatus = element.split('=')[1];
              let Oldvalue = oldValue.split(';')
              if (Oldvalue && Oldvalue.length > 0) {
                Oldvalue.forEach(element1 => {
                  if (element1.includes('coc_cart_details')) {
                    let cartstatusOld = element1.split('=')[1];
                    if (cartstatus !== cartstatusOld) {
                      this.ngOnInit();
                    }
                  }
                })


              }
            }
          });
        }
      }
    }, 1000);
  }


  ngOnChange() {
    this.getCartDetailsByUser()
    this.displayproceed()
  }

  ngOnInit(): void {
    this.getCartDetailsByUser()
    this.displayproceed()
  }

  cart() {
    this.router.navigateByUrl(`cart`);
  }

  getCartDetailsByUser() {
    let user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (user) {
      let type = 'email';
      this.generalService.getCartByUser(type, user).subscribe(res => {
        let response = res
        this.card_products = [];
        this.removedProducts = []
        let items_no = 0;
        response.forEach(element => {
          element.product.extra_description_one = JSON.parse(element.product.extra_description_one)[0].detail;
          if (element.stage === "added to cart") {
            items_no = items_no + element.quantity;
            element.product.pictures = JSON.parse(element.product.pictures).image[0].split(':')[1]
            let temp = this.card_products.find(x => x.product_id === element.product_id);
            let indexs = this.card_products.findIndex(x => x.product_id === element.product_id)
            if (temp === undefined) {
              this.card_products.push(element)
            }
            else {
              if (indexs >= 0)
                this.card_products[indexs].quantity = this.card_products[indexs].quantity + element.quantity;
              element.stage = "removed";
              this.removedProducts.push(element)
            }
          }
        })
        if (this.removedProducts.length > 0) {
          this.card_products.forEach(element => {
            this.checkforDuplicate(element)
          })
          this.removedProducts.forEach(element => {
            this.checkforDuplicate(element)
          })
          this.getCartDetailsByUser()
        }

        this.no_of_items = items_no;
        this.totalPrice()
      },
        err => {
          this.totalPrice()
        })
    } else if (temp_user && !user) {
      let type = 'temp';
      this.generalService.getCartByUser(type, temp_user).subscribe(res => {
        this.card_products = [];
        this.removedProducts = []
        let items_no = 0;
        res.forEach(element => {
          element.product.extra_description_one = JSON.parse(element.product.extra_description_one)[0].detail;
          if (element.stage === "added to cart") {
            items_no = items_no + element.quantity;
            element.product.pictures = JSON.parse(element.product.pictures).image[0].split(':')[1]
            let temp = this.card_products.find(x => x.product_id === element.product_id);
            let indexs = this.card_products.findIndex(x => x.product_id === element.product_id)
            if (temp === undefined) {
              this.card_products.push(element)
            }
            else {
              if (indexs >= 0)
                this.card_products[indexs].quantity = this.card_products[indexs].quantity + element.quantity;
              element.stage = "removed";
              this.removedProducts.push(element)
            }
          }
        })
        if (this.removedProducts.length > 0) {
          this.card_products.forEach(element => {
            this.checkforDuplicate(element)
          })
          this.removedProducts.forEach(element => {
            this.checkforDuplicate(element)
          })
          this.getCartDetailsByUser()
        }
        this.no_of_items = items_no;
        this.totalPrice()
      },
        err => {
          this.totalPrice()
        })
    } else {
      this.totalPrice()
    }

    setTimeout(() => {
      if ((user || temp_user) && this.card_products.length > 0) {

        let productarr = []
        this.card_products.forEach((element, i) => {
          let obj = {
            item_id: element.product.id,
            item_name: element.product.name,
            affiliation: "Concepto Wellness Store",
            coupon: "",
            currency: element.product.currency,
            discount: this.cartDiscount,
            index: i,
            item_brand: "Concepto",
            item_category: "Multivitamins",
            item_category2: "",
            item_category3: "",
            item_category4: "",
            item_category5: "",
            item_list_id: element.product.id,
            item_list_name: element.product.name,
            item_variant: "",
            location_id: "",
            price: element.product.price,
            quantity: element.quantity
          }
          productarr.push(obj)
          if (i === this.card_products.length - 1) {
            if (AppConstants.GA4 === true) {
              window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
              window.dataLayer.push({
                event: "view_cart",
                ecommerce: {
                  currency: "GBP",
                  value: this.total_amount,
                  items: productarr
                }
              });
            }
          }
        });
      }
      this.totalPrice()
    }, 3000);
    this.displayproceed()
  }

  totalPrice() {
    this.page_loader = true;
    if (this.no_of_items > 0) {
      this.total_price = 0;
      this.total_amount = 0;
      this.vat = 0;
      this.card_products.forEach(element => {
        this.total_price = this.total_price + (element.product.price * element.quantity);
      })
      this.total_price_display = this.total_price.toFixed(2)
      let tempVat = parseFloat(`0.${this.vatpercent}`);
      this.vat = (this.total_price * tempVat).toFixed(2)
      let vatValue = parseFloat(this.vat)
      this.total_amount = this.total_price + vatValue;
      let totalBill = this.total_amount
      this.total_amount = totalBill - (this.total_amount * this.discountPercentage);
      let discountedAmount = ((totalBill - this.total_amount)).toFixed(2)
      this.cartDiscount = parseFloat(discountedAmount).toFixed(2);
      this.total_amount = this.total_amount.toFixed(2)
      console.log('price',this.total_amount)
    } else {
      this.vat = '0.00';
      this.total_price = '0.00';
      this.total_amount = '0.00';
      this.total_price_display = '0.00',
        this.cartDiscount = '0.00'

    }
    this.displayproceed()
    this.page_loader = false;
  }

  checkforDuplicate(element) {
    let body = {
      id: element.id,
      product_id: element.product_id,
      user_id: element.user_id,
      quantity: element.quantity,
      sub_total: element.sub_total,
      stage: element.stage,
      temp_user_id: element.temp_user_id,
      created_at: element.created_at
    }
    this.generalService.cartUpdate(body).subscribe(res => {
      this.page_loader = false
    })
  }


  displayproceed() {
    setTimeout(() => {
      if (this.no_of_items > 0) {
        this.showcard = true;
      }
      else {
        this.showcard = false;
      }
    }, 1000);
  }

  // updateCart(element) {
  //   this.page_loader = true;
  //   let body = {
  //     id: element.id,
  //     product_id: element.product_id,
  //     user_id: element.user_id,
  //     quantity: element.quantity,
  //     sub_total: element.sub_total,
  //     stage: element.stage,
  //     temp_user_id: element.temp_user_id,
  //     created_at: element.created_at
  //   }
  //   this.generalService.cartUpdate(body).subscribe(res => {
  //     this.getCartDetailsByUser()
  //     this.page_loader = false
  //   })
  // }
}

