import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { MessageService } from 'primeng/api';
import * as bcrypt from 'bcryptjs';
const salt = bcrypt.genSaltSync(10);

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  providers: [MessageService]

})
export class ForgotPasswordComponent implements OnInit {

  toggle: boolean = true;
  hide: boolean = false;
  fun: boolean = false;
  forgotForm: FormGroup;
  submitted = false;
  emailDoesnotExist = false;
  hashedOTP: any;
  showOTP = false;
  OTPconfirmed = false;

  disableFullName = false;
  disableEmail = false;
  disableOTP = false;
  disablePassword = false
  emailAlreadyExist: boolean;
  otpNotValid = false;
  constructor(private formBuilder: FormBuilder, private router: Router, private generalService: GeneralService, private messageService: MessageService) { }





  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: [{ value: '', disabled: false }, [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$")]],
      otp: [{ value: '', disabled: false }, [Validators.required]],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.forgotForm.controls;
  }


  onSubmit() {
    // this.submitted = true;
    if (this.forgotForm.controls.email.status === "INVALID") {
      return;
    }
    else {
      let obj = {
        email: this.forgotForm.controls.email.value
      }
      this.generalService.otpRequestPasswordReset(obj).subscribe(res => {
        if (res['status'] === "email address does not exists") {
          this.emailDoesnotExist = true
        } else {
          this.forgotForm.get('email').disable()
          this.emailDoesnotExist = false
          this.hashedOTP = res[0];
          this.showOTP = true;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'OTP has been sent your email address' });
        }
      })
      // this.reset();
    }
  }

  submitOTP() {
    if (this.forgotForm.controls.otp.status === "INVALID") {
      return;
    }
    else {
      const salt = bcrypt.genSaltSync(10);
      let compare = bcrypt.compareSync(this.forgotForm.controls.otp.value, this.hashedOTP)
      if (compare) {
        this.OTPconfirmed = true
        this.forgotForm.get('otp').disable()
        this.otpNotValid = false
      }
      else {
        this.otpNotValid = true
      }
    }
  }

  completeReset() {
    this.submitted = true;
    if (this.forgotForm.controls.password.status === "INVALID") {
      return;
    }
    else {
      let obj = {
        email: this.forgotForm.controls.email.value,
        password: this.forgotForm.controls.password.value
      }
      this.generalService.updateUserPassword(obj).subscribe(res => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Password Reset Successfully' });
        setTimeout(() => { this.router.navigate(['login']) }, 1500);
      },
        err => {
          this.messageService.add({ severity: 'error', detail: 'Password Reset Unsuccessfull' });
        })
    }
  }

  login() {
    this.router.navigate(['login'])
  }

  reset() {
    this.toggle = false;
    this.fun = true;
  }

  confirm() {
    this.router.navigate(['reset-password'])
    this.hide = true;
    this.toggle = false;
    this.fun = false;
  }


}
