import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
declare var google: any;

@Component({
  selector: 'navbar-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  options: any;
  overlays: any;
  clinicsLocation: any;
  infoWindow: any;
  zoom: number;
  activeInd: any;
  fromURL: any;
  activeState: boolean[]
  constructor(private router: Router, @Inject(DOCUMENT) private dom) {
    this.clinicsLocation = [
      {
        "multiple": {
          "locations": [
            // {
            //   id: 0.1,
            //   label: 'Soho',
            //   name: 'Concepto Clinic Soho',
            //   address: '11 Warwick Street, Soho, London, W1B 5NA',
            //   phone: '02033718814',
            //   timing: 'Monday to Sunday: 10 am to 5 pm',
            //   parking: '',
            //   position: { lat: 51.511108172491035, lng: -0.13793029276726476 }
            // },
            {
              id: 0.2,
              label: 'Wimpole',
              name: 'Concepto Clinic Wimpole Street',
              address: '75 Wimpole Street London W1G 9RS',
              phone: '02033718814',
              timing: 'Monday to Sunday: 10 am to 6.30 pm',
              parking: '',
              position: { lat: 51.53801494853935, lng: -0.15222887298070817 }
            }
          ]
        },
        label: 'Central London',
        id: 0
      },
      {
        id: 1,
        label: 'Aylesbury',
        name: 'Concepto Clinic Aylesbury',
        address: 'Window to the Womb Aylesbury, Unit 12 Station Way, Aylesbury HP20 2SQ',
        phone: '02033718814',
        timing: 'Monday to Sunday: 10 am to 5:30 pm',
        parking: '',
        position: { lat: 51.814410, lng: -0.816630 }
      },
      {
        id: 2,
        label: 'Watford',
        name: 'Concepto Clinic Watford',
        address: '94 The Parade, High Street, Watford, WD17 1AW',
        phone: '02033718814',
        timing: 'Monday to Sunday: 10 am to 6.30 pm',
        parking: '',
        position: { lat: 51.658560, lng: -0.401070 }
      },
      {
        id: 3,
        label: 'Hitchin',
        name: 'Concepto Clinic Hitchin',
        address: 'Window to the Womb, 66 Hermitage Road, Hitchin SG5 1DB',
        phone: '02033718814',
        timing: 'Monday to Sunday: 8 am to 5 pm',
        parking: '',
        position: { lat: 51.949160, lng: -0.275030 }
      },
      // {
      //   id: 4,
      //   label: 'Cambridge',
      //   name: 'Concepto Clinic Cambridge',
      //   address: '65 Regent Street Cambridge CB2 1AB',
      //   phone: '02033718814',
      //   timing: 'Monday to Sunday: 10 am to 5 pm',
      //   parking: 'Queen Anne Terrace Car Park Gonville Place Cambridge CB1 1ND',
      //   position: { lat: 52.20117514340953, lng: 0.1260373386258038 }
      // },
      // {
      //   id: 5,
      //   label: 'Milton Keynes',
      //   name: 'Concepto Clinic Milton Keynes',
      //   address: 'Suite 22, BizSpace, Linford Forum Rockingham Drive Linford Wood Milton Keynes MK14 6LY',
      //   phone: '02033718814',
      //   timing: 'Monday to Sunday: 10 am to 4 pm',
      //   parking: 'Onsite parking is available.',
      //   position: { lat: 52.05674431213121, lng: -0.7577326772516076 }
      // },

      {
        id: 6,
        label: 'West London',
        name: 'Concepto Clinic West London',
        address: 'Saunders House 52-53 The Mall Ealing W5 3TA',
        phone: '02033718814',
        timing: 'Monday to Sunday: 10 am to 5 pm',
        parking: 'Springbridge Multi-Storey Car Park, 12 Spring Bridge Rd, Ealing, W5 2AB',
        position: { lat: 51.51440897208349, lng: -0.29996540740484334 }
      },
      {
        id: 7,
        label: 'Essex',
        name: 'Concepto Clinic Essex',
        address: '3 St Thomas Road, Brentwood, Essex CM14 4DB',
        phone: '02033718814',
        timing: 'Monday to Sunday: 8:30 am to 5 pm',
        parking: '30 minutes free car park on St Thomas Road.',
        parkingLink: 'https://maps.app.goo.gl/zzyR3zG4oyc1Ci1X8',
        position: { lat: 51.620050, lng: 0.304080 }
      },
      // {
      //   id: 8,
      //   label: 'North London',
      //   name: 'Concepto Clinic North London',
      //   address: '51 Colney Hatch Lane, London N10 1LJ',
      //   phone: '02033718814',
      //   timing: 'Monday to Sunday: 8:30 am to 5 pm',
      //   parking: 'Free road side parking is available near our clinic.',
      //   position: { lat: 51.59907951897268, lng: -0.146805 }
      // },
      {
        id: 8,
        label: 'Bristol',
        name: 'Concepto Clinic Bristol',
        address: 'Eastgate Office Centre (Unit 6&7) Bristol, BS5 6XX.',
        phone: '02033718814',
        timing: 'Monday to Sunday: 10 am to 3 pm',
        parking: 'Park right outside Unit 6 & Unit7',
        position: { lat: 51.47378057779861, lng: -2.5676144576717745 }
      },
    ];
    if (this.router.url && this.router.url.includes('?')) {
      this.fromURL = this.router.url.split('=')[1];
      this.fromURL = this.fromURL.replace('-', ' ')
      this.activeState = []
      this.clinicsLocation.forEach((element, i) => {
        if (this.fromURL === element.label) {
          this.activeState.push(true)
        } else {
          this.activeState.push(false)
        }
      });
    } else {
      this.activeState = [false, false, false, false, false, false, false, false, false, false, false, false];

    }
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    let menuitem = document.getElementsByClassName("p-menuitem");
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove("active");
    }


    this.options = {
      center: { lat: 52.04695102698926, lng: -1.2299562857934063 },
      zoom: 7
    };
    this.overlays = [
      // new google.maps.Marker({
      //   position: { lat: 51.511108172491035, lng: -0.13793029276726476 }, title: "Concepto Clinic Soho",
      //   address: this.clinicsLocation[0].multiple.locations[0], index: 0
      // }),
      new google.maps.Marker({
        position: { lat: 51.53801494853935, lng: -0.15222887298070817 }, title: "Concepto Clinic Central London",
        address: this.clinicsLocation[0].multiple.locations[1], index: 0
      }),
      new google.maps.Marker({
        position: { lat: 51.658560, lng: -0.401070 }, title: "Concepto Clinic Watford",
        address: this.clinicsLocation[1], index: 2
      }),
      // new google.maps.Marker({
      //   position: { lat: 52.20117514340953, lng: 0.1260373386258038 }, title: "Concepto Clinic Cambridge",
      //   address: this.clinicsLocation[4], index: 4
      // }),
      new google.maps.Marker({
        position: { lat: 51.51440897208349, lng: -0.29996540740484334 }, title: "Concepto Clinic West London",
        address: this.clinicsLocation[6], index: 6
      }),
      new google.maps.Marker({
        position: { lat: 51.814410, lng: -0.816630 }, title: "Concepto Clinic Aylesbury",
        address: this.clinicsLocation[1], index: 1
      }),
      new google.maps.Marker({
        position: { lat: 51.47378057779861, lng: -2.5676144576717745 }, title: "Concepto Clinic Bristol",
        address: this.clinicsLocation[8], index: 8
      }),
      // new google.maps.Marker({
      //   position: { lat: 52.05674431213121, lng: -0.7577326772516076 }, title: "Concepto Clinic Milton Keynes",
      //   address: this.clinicsLocation[5], index: 5
      // }),
      new google.maps.Marker({
        position: { lat: 51.620050, lng: 0.304080 }, title: "Concepto Clinic Essex",
        address: this.clinicsLocation[7], index: 7
      }),
      // new google.maps.Marker({
      //   position: { lat: 51.59907951897268, lng: -0.146805 }, title: "Concepto Clinic North London",
      //   address: this.clinicsLocation[8], index: 8
      // }),
      new google.maps.Marker({
        position: { lat: 51.949160, lng: -0.275030 }, title: "Concepto Clinic Hitchin",
        address: this.clinicsLocation[3], index: 3
      })
    ];

    this.infoWindow = new google.maps.InfoWindow();
  }

  bookNow(input) {
    if (input === 'pcr') {
      this.router.navigateByUrl('/diagnostics/covid-test-filtered?type=same-day-rt-pcr-travel-test')
    } else if (input === 'antigen') {
      this.router.navigateByUrl('/diagnostics/profile?test=Covid-19-Antigen-LFT-Test')

    }
  }

  handleOverlayClick(event) {
    let p = event.overlay.address.position.lat;
    let d = event.overlay.address.position.lng;
    this.activeInd = event.overlay.index;
    let temp = event.overlay.address.name + "<br/>" + event.overlay.address.address + "<br/>" + event.overlay.address.phone +
      `<br/> <a id='marker'>Get direction</a>`;
    //  <a href="https://www.google.com/maps/dir/?api=1&destination='+p+' '+d+'&travelmode=driving_blank">get direction</a>

    this.infoWindow.setContent(temp);
    this.infoWindow.open(event.map, event.overlay);
    event.map.setCenter(event.overlay.getPosition());

    google.maps.event.addListenerOnce(this.infoWindow, 'domready', () => {
      document.getElementById(`marker`).addEventListener('click', () => {
        window.open('https://www.google.com/maps/dir/?api=1&destination=' + p + ' ' + d + '&travelmode=driving', '_blank');
      });
    });
  }

  showLocation(event, index, multiindex?) {
    if (this.clinicsLocation[index].multiple) {
      event.panTo(new google.maps.LatLng(this.clinicsLocation[index].multiple.locations[multiindex].position.lat, this.clinicsLocation[index].multiple.locations[multiindex].position.lng));
    } else {
      event.panTo(new google.maps.LatLng(this.clinicsLocation[index].position.lat, this.clinicsLocation[index].position.lng));
    }
    event.setZoom(12);
  }

  getDirection(index, multiindex?) {
    let p;
    let d;
    if (this.clinicsLocation[index].multiple) {
      p = this.clinicsLocation[index].multiple.locations[multiindex].position.lat;
      d = this.clinicsLocation[index].multiple.locations[multiindex].position.lng;
    } else {
      p = this.clinicsLocation[index].position.lat;
      d = this.clinicsLocation[index].position.lng;
    }
    window.open('https://www.google.com/maps/dir/?api=1&destination=' + p + ' ' + d + '&travelmode=driving', '_blank');
  }

}
