import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { GeneralService } from '../general.service';

@Component({
  selector: 'app-cart-handler',
  templateUrl: './cart-handler.component.html',
  styleUrls: ['./cart-handler.component.scss']
})
export class CartHandlerComponent implements OnInit {

  cartProducts: any[];

  constructor(private generalService: GeneralService, private messageService: MessageService) { }

  @Input() cartItem:any
  @Output() removeProduct:EventEmitter <boolean>=new EventEmitter();
  @Output() homeKit:EventEmitter <boolean>=new EventEmitter();
  output=false;

  ngOnInit(): void {
    this.getCartDetailsByUser()
  }

  test(){
    this.removeProduct.emit(this.output);
  }
  getCartDetailsByUser() {
    let user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (user) {
      let type = 'email';
      this.generalService.getCartByUser(type, user).subscribe(res => {
        this.cartProducts = res
        // this.getAllProducts();
      })
    } else if (temp_user && !user) {
      let type = 'temp';
      this.generalService.getCartByUser(type, temp_user).subscribe(res => {
        this.cartProducts = res
        // this.getAllProducts();
      })
    }
  }

  addItem(id) {
    this.cartProducts.forEach(element => {
      if (id === element.id) {
        if (element.quantity > 9) {
          this.messageService.add({ severity: 'error', detail: 'Maximum order limit reached for this item' });
        } else {
          element.quantity = element.quantity + 1
          this.updateCart(element)
          if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
            this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) + 1, 2)
          } else {
            this.generalService.setCookie('coc_cart_details', 1, 2)
          }
        }
      }
    })
  }

  deleteItem(id) {
    this.cartProducts.forEach(element => {
      if (id === element.id && element.quantity > 1) {
        element.quantity = element.quantity - 1
        this.updateCart(element)
        if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
          this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) - 1, 2)
        } else {
          this.generalService.setCookie('coc_cart_details', 1, 2)
        } }
    })
  }

  removeItem(product) {
    product.stage = "removed";
    this.updateCart(product)
    this.homeKit.emit(this.output);
    if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
      this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) - 1, 2)
    } else {
      this.generalService.setCookie('coc_cart_details', 1, 2)
    } }

  updateCart(element) {
    // this.page_loader = true;
    let body = {
      id: element.id,
      product_id: element.product_id,
      user_id: element.user_id,
      quantity: element.quantity,
      sub_total: element.sub_total,
      stage: element.stage,
      temp_user_id: element.temp_user_id,
      created_at: element.created_at
    }
    this.generalService.cartUpdate(body).subscribe(res => {
      // this.getCartDetailsByUser()
      // setTimeout(() => {
        this.removeProduct.emit(this.output);        
      // }, 2000);
      // this.page_loader = false
    })
  }


}
