import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sexually-transmitted-infections',
  templateUrl: './sexually-transmitted-infections.component.html',
  styleUrls: ['./sexually-transmitted-infections.component.scss']
})
export class SexuallyTransmittedInfectionsComponent implements OnInit {
  sti_all_tests_home_order: any = [];
  sti_all_tests_in_clinic: any = [];
  constructor(private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Diagnostics"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    this.sti_all_tests_home_order = [
      {
        title: 'Routine STI check',
        type: 'home order',
        url: 'Routine-STI-check',
        picture: '../../assets/images/sti/urine_sample_1.png',
        price: '99',
        short_description: 'This test kit helps detect the infections. ',
        infections: ['Syphilis', 'HSV 1 & 2 ', 'VZN', 'CMV'],
        turnaround_time: '24 hours',
        contents: [
          {
            name: 'Sample Type: Urine',
            details: ''
          },
          {
            name: 'a. Syphilis',
            details: 'Syphilis is a sexually transmitted infection (STI). If left undiagnosed and untreated, syphilis can eventually cause damage to the brain, nerves, eyes, heart, blood vessels, liver, bones, and joints. '
          },
          {
            name: 'b. HSV 1 & 2',
            details: 'The herpes simplex virus (HSV) is the most common sexually transmitted pathogen that causes ulcers of the genital, anal and oropharyngeal region.'
          },
          {
            name: 'c. Varicella zoster virus (VZN)',
            details: 'Herpes zoster (HZ) is a clinical manifestation of the reactivation of the varicella zoster virus (VZV). VZV may be an under-reported cause of viral genital infections, especially in patients 16~50 years of age. '
          },
          {
            name: 'd. Cytomegalovirus (CMV)',
            details: 'Cytomegalovirus (CMV) can cause life-threatening disease in immunocompromised patients, such as those with human immunodeficiency virus (HIV). It is a rare but important cause of ulceration in the female genital tract. '
          },
        ]
      },
      {
        title: 'Comprehensive annual STI tests',
        type: 'home order',
        url: 'Comprehensive-annual-STI-tests',
        picture: '../../assets/images/sti/urine_sample_2.png',
        price: '99',
        short_description: 'Our most comprehensive STI test for complete peace of mind. ',
        infections: ['Chlamydia', 'Gonorrhea', 'Trichomoniasis', 'M. genitalium'],
        turnaround_time: '24 hours',
        contents: [
          {
            name: 'Sample Type: Urine',
            details: ''
          },
          {
            name: 'a. Chlamydia trachomatis',
            details: 'Chlamydia is a common STI that affects both males and females and generally shows no symptoms. If left untreated, it can result in serious health complications and can easily be spread to partners. '
          },
          {
            name: 'b. Neisseria gonorrhoeae ',
            details: 'Gonorrhea is a very common infectious disease. Especially common among young adults, it can cause infections in the genitals, rectum, and throat. Some men, and most women, experience no symptoms so early detection is essential.'
          },
          {
            name: 'c. Trichomonas vaginalis',
            details: 'Trichomoniasis is the most common curable STI. Only 30% of people with this infection develop symptoms, so regular testing is important. If left untreated, the infection can last for months or even years. '
          },
          {
            name: 'd. Mycoplasma genitalium ',
            details: 'M. genitallium is a very small parasitic bacterium that can infect both men and women. The Mycoplasma Genitalium bacteria can be passed through sexual contact.'
          },
        ]
      },
      {
        title: 'Chlamydia/Gonorrhea screening test',
        type: 'home order',
        url: 'Chlamydia/Gonorrhea-screening-test',
        picture: '../../assets/images/sti/urine_sample_3.png',
        price: '99',
        short_description: 'Includes tests for Chlamydia and Gonorrhoea when patients do not have any symptoms. ',
        infections: ['Chlamydia trachomatis', 'Neisseria gonorrhoeae'],
        turnaround_time: '24 hours',
        contents: [
          {
            name: 'Sample Type: Urine',
            details: ''
          },
          {
            name: 'a. Chlamydia trachomatis ',
            details: 'Chlamydia is a common STI that affects both males and females and generally shows no symptoms. If left untreated, it can result in serious health complications and can easily be spread to partners. '
          },
          {
            name: 'b. Neisseria gonorrhoeae',
            details: 'Gonorrhea is a very common infectious disease. Gonorrhoea can cause infections in the genitals, rectum, and throat. It is especially common among young adults. Some men, and most women, experience no symptoms so early detection is essential.'
          },
        ]
      },
      {
        title: 'Syphilis, HSV 1&2 STI test',
        type: 'home order',
        url: 'Syphilis-HSV-1and2-STI-test',
        picture: '../../assets/images/sti/urine_sample_4.png',
        price: '99',
        short_description: ' Three key STI tests are included in this package. ',
        infections: ['Syphilis', 'HSV 1 & 2 '],
        turnaround_time: '24 hours',
        contents: [
          {
            name: 'Sample Type: Urine',
            details: ''
          },
          {
            name: 'a. Syphilis',
            details: 'Syphilis is a sexually transmitted infection (STI). If left undiagnosed and untreated, syphilis can eventually cause damage to the brain, nerves, eyes, heart, blood vessels, liver, bones, and joints. '
          },
          {
            name: 'HSV 1 & 2',
            details: 'The herpes simplex virus (HSV) is the most common sexually transmitted pathogen that causes ulcers of the genital, anal and oropharyngeal region. '
          }
        ]
      }]

    this.sti_all_tests_in_clinic = [
      {
        title: 'CT/NG (Ultra Urgent)',
        type: 'in clinic',
        url: 'CT/NG-(Ultra-Urgent)',
        picture: '../../assets/images/sti/sti-test.png',
        price: '99',
        short_description: 'STI test with quick turnaround time (2hours) only available at our Watford Clinic.',
        infections: ['CT/NG'],
        turnaround_time: '3 hours',
        contents: [
          {
            name: 'Sample Type: Urine',
            details: ''
          },
          {
            name: 'CT/NG',
            details: 'CT/NG is an on-demand test to detect DNA from C. trachomatis and N. gonorrhoeae from urine specimens for female and male.',
            extra_details: 'The test results for these urgent tests will be available 2 hours after samples reach our lab. '
          }
        ]
      },
      {
        title: 'HIV-1 Qualitative STI Test',
        type: 'in clinic',
        url: 'HIV-1-Qualitative-STI-Test',
        picture: '../../assets/images/sti/urgent STI.png',
        price: '99',
        short_description: 'STI test with quick turnaround time (2hours) only available at our Watford Clinic.',
        infections: ['HIV-1 Qualitative STI Test'],
        turnaround_time: '3 hours',
        contents: [
          {
            name: 'Sample Type: Blood',
            details: ''
          },
          {
            name: 'HIV-1 Qualitative STI Test',
            details: 'It is estimated that there are around 30 million people worldwide living with HIV and almost half of those people have never been diagnosed and are completely unaware that they are carrying HIV. ',
            extra_details: 'The test results for these urgent tests will be available 2 hours after samples reach our lab. '
          }
        ]
      },
      {
        title: 'TV STI Test',
        type: 'in clinic',
        url: 'TV-STI-Test',
        picture: '../../assets/images/sti/sti-test.png',
        price: '99',
        short_description: 'STI test with quick turnaround time (2hours) only available at our Watford Clinic.',
        infections: ['TV STI test'],
        turnaround_time: '3 hours',
        contents: [
          {
            name: 'Sample Type: Urine',
            details: ''
          },
          {
            name: 'TV STI test',
            details: 'TV is a qualitative real-time PCR test for automated detection of T. vaginalis intended for both symptomatic and asymptomatic patients so clinicians can screen individuals at higher risk of infection.',
            extra_details: 'The test results for these urgent tests will be available 2 hours after samples reach our lab. '
          }
        ]
      }
    ]
  }

  bookNow(url) {
    this.router.navigate(['diagnostics/sti-details'], { queryParams: { test: url } })
  }

  exploreSTItests(){
    document.getElementById(`sti-view`).scrollIntoView({ behavior: "smooth" })
  }
}
