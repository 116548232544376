import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'wellness-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      if(this.router.url === '/wellness'){
        menuitem[5].classList.add("active");
      }
      if(this.router.url === '/imaging'){
        // menuitem[4].classList.add("active");
      }
      if(this.router.url === '/blog'){
        // menuitem[5].classList.add("active");
      }
    }, 1000)
  }

}
