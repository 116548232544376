import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  carddetails = []
  trendingarticles = []
  customerTestArray: any;
  constructor(private router: Router, @Inject(DOCUMENT) private dom) {  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if (menuitem[i]["outerText"] === "Blog") {
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    this.carddetails = [
      {
        title: "What is NIPT and how does it work?",
        image: "What-is-NIPT-and-how-does-it-work.png",
        shortdescription: "Explaining the science behind non-invasive prenatal test and why you should get a NIPT test done.",
        link: "What-is-NIPT-and-how-does-it-work"
      },
      {
        title: "Non invasive screening test for Downs syndrome in UK",
        image: "Downs-Syndrome-test-in-UK.png",
        shortdescription: "Down’s syndrome is among the most common chromosomal conditions in the UK, affecting 1 in every 700 births. Down’s syndrome tests in UK are offered by NHS and private clinics. Let us look at what is Down’s syndrome, its causes, and how Down’s Syndrome screening tests during pregnancy help in early detection.",
        link: "Downs-Syndrome-test-in-UK"
      },
      {
        title: "NIPT test accuracy - detailed statistics and limitations",
        image: "NIPT-test-accuracy-detailed-statistics-and-limitations.png",
        shortdescription: "Pregnancy can be hard. It makes you anxious, worried and tired - and, the cycle keeps repeating. Well, the only way we can offer peace of mind to you all anxious parents-to-be, is through our NIPT test , that accurately screens for three of the most common chromosomal anomalies: Down syndrome, Edwards syndrome, and Patau syndrome",
        link: "NIPT-test-accuracy-detailed-statistics-and-limitations"
      },
      {
        title: "Most advanced NIPT test during pregnancy in the UK",
        image: "Most-advanced-NIPT-test-during-pregnancy-in-the-UK.png",
        shortdescription: "Worried? Confused? At your wit's end after reading complex journals on NIPT? Don't worry, we have you covered. Let us walk you through some basics of NIPT test during pregnancy.",
        link: "Most-advanced-NIPT-test-during-pregnancy-in-the-UK"
      }
    ]
    this.customerTestArray = this.carddetails;

    this.trendingarticles = [
      {
        shortdescription: "What is NIPT and how does it work?",
        image: "What-is-NIPT-and-how-does-it-work.png",
        link: "What-is-NIPT-and-how-does-it-work"
      },

      {
        shortdescription: "Non invasive screening test for Downs syndrome in UK",
        image: "Downs-Syndrome-test-in-UK.png",
        link: "Downs-Syndrome-test-in-UK"
      },
      {
        shortdescription: "NIPT test accuracy - detailed statistics and limitations",
        image: "NIPT-test-accuracy-detailed-statistics-and-limitations.png",
        link: "NIPT-test-accuracy-detailed-statistics-and-limitations"
      },
      {
        shortdescription: "Most advanced NIPT test during pregnancy in the UK",
        image: "Most-advanced-NIPT-test-during-pregnancy-in-the-UK.png",
        link: "Most-advanced-NIPT-test-during-pregnancy-in-the-UK"
      }
    ]
  }


  search(input) {
    if (input !== "") {
      input = input.toLowerCase();
      let array = this.carddetails.filter(x => x.title.toLowerCase().includes(input));
      this.customerTestArray = array;
    }
    else {
      this.customerTestArray = this.carddetails;
    }
  }


  individualblog(data) {
    this.router.navigateByUrl(`/individual-blog/${data.link}`);
  }

  archived() {
    this.router.navigateByUrl(`/archived-blog`);
  }

}
