import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { PressComponent } from './press/press.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NiptHomeComponent } from './nipt-home/nipt-home.component';
import { IndividualNiptComponent } from './individual-nipt/individual-nipt.component';
import { BluePinkComponent } from './blue-pink/blue-pink.component';
import { MobilePhlebotomistComponent } from './mobile-phlebotomist/mobile-phlebotomist.component';
import { PartnerComponent } from './partner/partner.component';
import { ResourcesComponent } from './resources/resources.component';
import { confirmationComponent } from './confirmation/confirmation.component';
import { GenderDetailsPageComponent } from './gender-details-page/gender-details-page.component';
import { BlogComponent } from './blog/blog.component';
import { ClinicFinderComponent } from './clinic-finder/clinic-finder.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CorporateTestingComponent } from './corporate-testing/corporate-testing.component';
import { CovidTestFilteredComponent } from './covid-test-filtered/covid-test-filtered.component';
import { CovidTestComponent } from './covid-test/covid-test.component';
import { DiagnosticsHomeComponent } from './diagnostics-home/diagnostics-home.component';
import { FaqComponent } from './faq/faq.component';
import { IndividualBlogComponent } from './individual-blog/individual-blog.component';
import { PathologyTestProfilesComponent } from './pathology-test-profiles/pathology-test-profiles.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SexuallyTransmittedInfectionsComponent } from './sexually-transmitted-infections/sexually-transmitted-infections.component';
import { StiDetailsPageComponent } from './sti-details-page/sti-details-page.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { WellManProfileComponent } from './well-man-profile/well-man-profile.component';
import { EmiratesTestComponent } from './emirates-test/emirates-test.component';
import { LoginComponent } from './login/login.component';
import { CartComponent } from './cart/cart.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { MultivitaminDetailsComponent } from './multivitamin-details/multivitamin-details.component';
import { WellnessHomeComponent } from './wellness-home/wellness-home.component';
import { WellnessProductsComponent } from './wellness-products/wellness-products.component';
import { GbsComponent } from './gbs/gbs.component';
import { IndividualGbsComponent } from './individual-gbs/individual-gbs.component';
import { AestheticsPageComponent } from './aesthetics-page/aesthetics-page.component';
import { AestheticsOfferingsComponent } from './aesthetics-offerings/aesthetics-offerings.component';
import { UnsubscribeCrossPromotionalEmailsComponent } from './unsubscribe-cross-promotional-emails/unsubscribe-cross-promotional-emails.component';
import { TestCodesComponent } from './test-codes/test-codes.component';
import { ArchivedBlogComponent } from './archived-blogs/archived-blogs.component';



const routes: Routes = [
  
  { path: '', component: HomeComponent },
  { path: 'aboutus', component: AboutusComponent, pathMatch: 'full' },
  { path: 'press', component: PressComponent, pathMatch: 'full' },
  { path: 'nipt', component: NiptHomeComponent, pathMatch: 'full' },
  // { path: 'aesthetics', component:AestheticsPageComponent, pathMatch: 'full' },
  // { path: 'aesthetics/offerings', component:AestheticsOfferingsComponent, pathMatch: 'full'},
  // { path: 'group-b-strep', component: GbsComponent, pathMatch: 'full' },
  { path: 'nipt/:details', component: IndividualNiptComponent, pathMatch: 'full' },
  // { path: 'group-b-strep/individual', component: IndividualGbsComponent, pathMatch: 'full' },
  // { path: 'blue-pink', component: BluePinkComponent, pathMatch: 'full' },
  { path: 'partners', component: PartnerComponent, pathMatch: 'full' },
  { path: 'mobile-phlebotomist', component: MobilePhlebotomistComponent, pathMatch: 'full' },
  { path: 'resources', component: ResourcesComponent, pathMatch: 'full' },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'confirmation/:status', component: confirmationComponent },
  // { path: 'diagnostics', component: DiagnosticsHomeComponent },
  { path: 'clinic-finder', component: ClinicFinderComponent, pathMatch: 'full' },
  { path: 'blog', component: BlogComponent, pathMatch: 'full' },
  { path: 'individual-blog/:name', component: IndividualBlogComponent, pathMatch: 'full' },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent, pathMatch: 'full' },
  { path: 'frequently-asked-questions', component: FaqComponent, pathMatch: 'full' },
  { path: 'contact-us', component: ContactUsComponent, pathMatch: 'full' },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, pathMatch: 'full' },
  { path: 'testcodes', component: TestCodesComponent, pathMatch: 'full' },
  { path: 'archived-blog', component: ArchivedBlogComponent, pathMatch: 'full' },

  
  // { path: 'diagnostics/corporate-testing', component: CorporateTestingComponent, pathMatch: 'full' },
  // { path: 'diagnostics/covid-test', component: CovidTestComponent, pathMatch: 'full' },
  // { path: 'diagnostics/covid-test-filtered', component: CovidTestFilteredComponent, pathMatch: 'full' },
  // { path: 'diagnostics/pathology-test-profiles', component: PathologyTestProfilesComponent },
  // { path: 'diagnostics/profile', component: WellManProfileComponent },
  // { path: 'diagnostics/sti', component: SexuallyTransmittedInfectionsComponent },
  // { path: 'diagnostics/sti-details', component: StiDetailsPageComponent },
  // { path: 'diagnostics/gender-details', component: GenderDetailsPageComponent, pathMatch: 'full' },
  // { path: 'diagnostics/covid-or-flu', redirectTo: '/covid-test', pathMatch: 'full' },
  // { path: 'wellness', component: WellnessHomeComponent },
  // { path: 'wellness/multivitamin', component: MultivitaminDetailsComponent },
  // { path: 'wellness/products', component: WellnessProductsComponent },

  // { path: 'diagnostics/covid-test-filtered?type=covid-tests', component: CovidTestFilteredComponent, pathMatch: 'full' },
  // { path: 'diagnostics/covid-test-filtered?type=flu-tests', component: CovidTestFilteredComponent, pathMatch: 'full' },

  // { path: 'emirates-covid-test-page/', redirectTo: '/diagnostics/emirates-covid-test-page', pathMatch: 'full' },
  // { path: 'diagnostics/emirates-covid-test-page', component: EmiratesTestComponent, pathMatch: 'full' },

  // { path: 'concepto-test-profiles/', redirectTo: '/diagnostics/concepto-test-profiles', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles', redirectTo: '/diagnostics/pathology-test-profiles', pathMatch: 'full' },

  { path: 'faqs/', redirectTo: '/faqs' },
  { path: 'faqs', redirectTo: 'frequently-asked-questions' },


  // clinics
  { path: 'clinics/essex/', redirectTo: '/clinics/essex', pathMatch: 'full' },
  { path: 'clinics/essex', redirectTo: '/clinic-finder?clinic=Essex', pathMatch: 'full' },

  { path: 'clinics/milton-keynes/', redirectTo: '/clinics/milton-keynes', pathMatch: 'full' },
  { path: 'clinics/milton-keynes', redirectTo: '/clinic-finder?clinic=Milton-Keynes', pathMatch: 'full' },

  { path: 'clinics/oxford/', redirectTo: '/clinics/oxford', pathMatch: 'full' },
  { path: 'clinics/oxford', redirectTo: '/clinic-finder?clinic=Oxford', pathMatch: 'full' },

  { path: 'clinics/north-london/', redirectTo: '/clinics/north-london', pathMatch: 'full' },
  { path: 'clinics/north-london', redirectTo: '/clinic-finder?clinic=North-London', pathMatch: 'full' },

  { path: 'clinics/bristol/', redirectTo: '/clinics/bristol', pathMatch: 'full' },
  { path: 'clinics/bristol', redirectTo: '/clinic-finder?clinic=Bristol', pathMatch: 'full' },

  { path: 'clinics/Hitchin/', redirectTo: '/clinics/Hitchin', pathMatch: 'full' },
  { path: 'clinics/Hitchin', redirectTo: '/clinic-finder?clinic=Hitchin', pathMatch: 'full' },


  { path: 'clinics/Aylesbury/', redirectTo: '/clinics/Aylesbury', pathMatch: 'full' },
  { path: 'clinics/Aylesbury', redirectTo: '/clinic-finder?clinic=Aylesbury', pathMatch: 'full' },

  { path: 'clinics/heathrow-airport/', redirectTo: '/clinics/heathrow-airport', pathMatch: 'full' },
  { path: 'clinics/heathrow-airport', redirectTo: '/clinic-finder?clinic=Heathrow-Airport', pathMatch: 'full' },

  { path: 'clinics/london/', redirectTo: '/clinics/london', pathMatch: 'full' },
  { path: 'clinics/london', redirectTo: '/clinic-finder?clinic=Central-London', pathMatch: 'full' },

  { path: 'clinics/cambridge/', redirectTo: '/clinics/cambridge', pathMatch: 'full' },
  { path: 'clinics/cambridge', redirectTo: '/clinic-finder?clinic=Cambridge', pathMatch: 'full' },

  { path: 'clinics/watford/', redirectTo: '/clinics/watford', pathMatch: 'full' },
  { path: 'clinics/watford', redirectTo: '/clinic-finder?clinic=Watford', pathMatch: 'full' },

  { path: 'clinics/west-london/', redirectTo: '/clinics/west-london', pathMatch: 'full' },
  { path: 'clinics/west-london', redirectTo: '/clinic-finder?clinic=West-London', pathMatch: 'full' },

  { path: 'clinics/south-london/', redirectTo: '/clinics/south-london', pathMatch: 'full' },
  { path: 'clinics/south-london', redirectTo: '/clinic-finder?clinic=South-London', pathMatch: 'full' },

  { path: 'clinics/gatwick-airport/', redirectTo: '/clinics/gatwick-airport', pathMatch: 'full' },
  { path: 'clinics/gatwick-airport', redirectTo: '/clinic-finder?clinic=gatwick-Airport', pathMatch: 'full' },


  // pathology
  // { path: 'concepto-test-profiles/psa/', redirectTo: '/diagnostics/concepto-test-profiles/psa', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/psa', redirectTo: '/diagnostics/profile?test=PSA', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/psa/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/psa', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/psa', redirectTo: '/diagnostics/profile?test=PSA', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/b12/', redirectTo: '/diagnostics/concepto-test-profiles/b12', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/b12', redirectTo: '/diagnostics/profile?test=B12', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/b12/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/b12', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/b12', redirectTo: '/diagnostics/profile?test=B12', pathMatch: 'full' },

  // { path: 'concepto-pathology-tests-profiles/total-testosterone/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/total-testosterone', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/total-testosterone', redirectTo: '/diagnostics/profile?test=Total-Testosterone', pathMatch: 'full' },

  // { path: 'concepto-pathology-tests-profiles/kidney-function-test/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/kidney-function-test', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/kidney-function-test', redirectTo: '/diagnostics/profile?test=Kidney-Function-test', pathMatch: 'full' },

  // { path: 'concepto-pathology-tests-profiles/biochemistry/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/biochemistry', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/biochemistry', redirectTo: '/diagnostics/profile?test=Biochemistry-Profile', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/progesterone/', redirectTo: '/diagnostics/concepto-test-profiles/progesterone', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/progesterone', redirectTo: '/diagnostics/profile?test=Progesterone', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/progesterone/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/progesterone', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/progesterone', redirectTo: '/diagnostics/profile?test=Progesterone', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/haematology-profile/', redirectTo: '/diagnostics/concepto-test-profiles/haematology-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/haematology-profile', redirectTo: '/diagnostics/profile?test=Haematology-profile', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/haematology-profile/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/haematology-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/haematology-profile', redirectTo: '/diagnostics/profile?test=Haematology-profile', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/well-woman-profile/', redirectTo: '/diagnostics/concepto-test-profiles/well-woman-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/well-woman-profile', redirectTo: '/diagnostics/profile?test=Well-Woman-profile', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/well-woman-profile/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/well-woman-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/well-woman-profile', redirectTo: '/diagnostics/profile?test=Well-Woman-profile', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/hiv/', redirectTo: '/diagnostics/concepto-test-profiles/hiv', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/hiv', redirectTo: '/diagnostics/profile?test=HIV', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/hiv/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/hiv', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/hiv', redirectTo: '/diagnostics/profile?test=HIV', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/kidney-function-test/', redirectTo: '/diagnostics/concepto-test-profiles/kidney-function-test', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/kidney-function-test', redirectTo: '/diagnostics/profile?test=Kidney-Function-test', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/well-man-profile/', redirectTo: '/diagnostics/concepto-test-profiles/well-man-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/well-man-profile', redirectTo: '/diagnostics/profile?test=Well-Man-Profile', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/well-man-profile/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/well-man-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/well-man-profile', redirectTo: '/diagnostics/profile?test=Well-Man-Profile', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/biochemistry/', redirectTo: '/diagnostics/concepto-test-profiles/biochemistry', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/biochemistry', redirectTo: '/diagnostics/profile?test=Biochemistry-Profile', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/fbc/', redirectTo: '/diagnostics/concepto-test-profiles/fbc', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/fbc', redirectTo: '/diagnostics/profile?test=FBC', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/fbc/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/fbc', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/fbc', redirectTo: '/diagnostics/profile?test=FBC', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/thyroid-profile/', redirectTo: '/diagnostics/concepto-test-profiles/thyroid-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/thyroid-profile', redirectTo: '/diagnostics/profile?test=Thyroid-profile', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/thyroid-profile/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/thyroid-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/thyroid-profile', redirectTo: '/diagnostics/profile?test=Thyroid-profile', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/total-testosterone/', redirectTo: '/diagnostics/concepto-test-profiles/total-testosterone', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/total-testosterone', redirectTo: '/diagnostics/profile?test=Total-Testosterone', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/general-well-person-profile/', redirectTo: '/diagnostics/concepto-test-profiles/general-well-person-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/general-well-person-profile', redirectTo: '/diagnostics/profile?test=General-Well-Person-Profile', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/general-well-person-profile/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/general-well-person-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/general-well-person-profile', redirectTo: '/diagnostics/profile?test=General-Well-Person-Profile', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/lft/', redirectTo: '/diagnostics/concepto-test-profiles/lft', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/lft', redirectTo: '/diagnostics/profile?test=LFT', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/lft/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/lft', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/lft', redirectTo: '/diagnostics/profile?test=LFT', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/hba1c/', redirectTo: '/diagnostics/concepto-test-profiles/hba1c', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/hba1c', redirectTo: '/diagnostics/profile?test=HbA1c', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/hba1c/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/hba1c', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/hba1c', redirectTo: '/diagnostics/profile?test=HbA1c', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/d-dimer/', redirectTo: '/diagnostics/concepto-test-profiles/d-dimer', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/d-dimer', redirectTo: '/diagnostics/profile?test=D-Dimer', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/d-dimer/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/d-dimer', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/d-dimer', redirectTo: '/diagnostics/profile?test=D-Dimer', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/biochemistry-and-haematology-profile/', redirectTo: '/diagnostics/concepto-test-profiles/biochemistry-and-haematology-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/biochemistry-and-haematology-profile', redirectTo: '/diagnostics/profile?test=Biochemistry-and-Haematology-Profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/biochemistry-and-haematology-profile/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/biochemistry-and-haematology-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/biochemistry-and-haematology-profile', redirectTo: '/diagnostics/profile?test=Biochemistry-and-Haematology-Profile', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/lipid-profile/', redirectTo: '/diagnostics/concepto-test-profiles/lipid-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/lipid-profile', redirectTo: '/diagnostics/profile?test=Lipid-Profile', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/lipid-profile/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/lipid-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/lipid-profile', redirectTo: '/diagnostics/profile?test=Lipid-Profile', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/oestradiol/', redirectTo: '/diagnostics/concepto-test-profiles/oestradiol', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/oestradiol', redirectTo: '/diagnostics/profile?test=Oestradiol', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/oestradiol/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/oestradiol/', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/oestradiol', redirectTo: '/diagnostics/profile?test=Oestradiol', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/polycystic-ovary-syndrome/', redirectTo: '/diagnostics/concepto-test-profiles/polycystic-ovary-syndrome', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/polycystic-ovary-syndrome', redirectTo: '/diagnostics/profile?test=Polycystic-Ovary-Syndrome', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/polycystic-ovary-syndrome/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/polycystic-ovary-syndrome', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/polycystic-ovary-syndrome', redirectTo: '/diagnostics/profile?test=Polycystic-Ovary-Syndrome', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/fsh/', redirectTo: '/diagnostics/concepto-test-profiles/fsh', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/fsh', redirectTo: '/diagnostics/profile?test=FSH', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/fsh/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/fsh', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/fsh', redirectTo: '/diagnostics/profile?test=FSH', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/cardiovascular-risk-profile-1/', redirectTo: '/diagnostics/concepto-test-profiles/cardiovascular-risk-profile-1', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/cardiovascular-risk-profile-1', redirectTo: '/diagnostics/profile?test=Cardiovascular-Risk-Profile-1', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/cardiovascular-risk-profile-1/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/cardiovascular-risk-profile-1', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/cardiovascular-risk-profile-1', redirectTo: '/diagnostics/profile?test=Cardiovascular-Risk-Profile-1', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/vitamin-d/', redirectTo: '/diagnostics/concepto-test-profiles/vitamin-d', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/vitamin-d', redirectTo: '/diagnostics/profile?test=Vitamin-D', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/vitamin-d/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/vitamin-d', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/vitamin-d', redirectTo: '/diagnostics/profile?test=Vitamin-D', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/diabetes-profile/', redirectTo: '/diagnostics/concepto-test-profiles/diabetes-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/diabetes-profile', redirectTo: '/diagnostics/profile?test=Diabetes-Profile', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/diabetes-profile/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/diabetes-profile', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/diabetes-profile', redirectTo: '/diagnostics/profile?test=Diabetes-Profile', pathMatch: 'full' },

  // { path: 'concepto-test-profiles/ferritin/', redirectTo: '/diagnostics/concepto-test-profiles/ferritin', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-test-profiles/ferritin', redirectTo: '/diagnostics/profile?test=Ferritin', pathMatch: 'full' },
  // { path: 'concepto-pathology-tests-profiles/ferritin/', redirectTo: '/diagnostics/concepto-pathology-tests-profiles/ferritin', pathMatch: 'full' },
  // { path: 'diagnostics/concepto-pathology-tests-profiles/ferritin', redirectTo: '/diagnostics/profile?test=Ferritin', pathMatch: 'full' },

  //profile
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'profile', component: UserProfileComponent },
  { path: 'cart', component: CartComponent },
  {path: 'unsubscribe', component: UnsubscribeCrossPromotionalEmailsComponent},


  // { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    }

  ],
})
export class AppRoutingModule { }
