import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from '../general.service';
import { MessageService } from 'primeng/api';
import { AppConstants } from '../app.constants';
import { CartPopverComponent } from '../cart-popver/cart-popver.component';


@Component({
  selector: 'wellness-multivitamin-details',
  templateUrl: './multivitamin-details.component.html',
  styleUrls: ['./multivitamin-details.component.scss'],
  providers: [MessageService]

})



export class MultivitaminDetailsComponent implements OnInit {
  wellnessData: any;
  multivitamin_details: any = [];
  show: boolean;
  image: string;
  responsiveOptions: any[];
  activeImage: number = 0;
  char = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  page_loader = false;
  showPostCartAdding = false;
  nameOfItemAddedToCart = ''
  cartProducts: any = [];
  constructor(private router: Router, private generalService: GeneralService, private messageService: MessageService) {
    // this.getCartDetailsByUser()
   }

  // @ViewChild(CartPopverComponent) refCmpB;
  
  ngOnInit(): void {
    this.page_loader = true;
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Wellness"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    let getUrl = this.router.url.split('=')[1]

    let nameArr = getUrl.split('-')
    nameArr.forEach(element => {
      element = element.charAt(0).toUpperCase() + element.slice(1)
    });
    this.generalService.getProductByName(getUrl).subscribe((res) => {
      if(res.length === 0){
        this.router.navigate(['/page-not-found'])
      }
      this.multivitamin_details = res;
      console.log(res)
      let cartImages = JSON.parse(this.multivitamin_details[0]['pictures'])['image'];
      let arr = []
      cartImages.forEach(images => {
        if (images.split(':')[0] === 'cart') {
          let item = `../../assets/images/wellness/products/${images.split(`:`)[1]}`;
          arr.push(item)
        }
      });
      

      this.multivitamin_details[0]['title'] = this.multivitamin_details[0].name;
      this.multivitamin_details[0]['url'] = getUrl;
      this.multivitamin_details[0]['box'] = JSON.parse(this.multivitamin_details[0].extra_description_one)[0].detail;
      this.multivitamin_details[0]['about'] = this.multivitamin_details[0].description;
      this.multivitamin_details[0]['ingredients'] = JSON.parse(this.multivitamin_details[0].extra_description_two);
      this.multivitamin_details[0]['price'] = this.multivitamin_details[0].price;
      this.multivitamin_details[0]['contents'] = JSON.parse(this.multivitamin_details[0].extra_description_three);
      this.multivitamin_details[0]['images'] = arr;
      
      let temp = this.multivitamin_details.filter(item => item.url === getUrl)[0];
      this.wellnessData = temp;
      this.getCartDetailsByUser()
    })
    setTimeout(() => {
      this.page_loader = false;
      if (AppConstants.GA4 === true) {
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
          event: "view_item",
          ecommerce: {
            items: [
              {
                item_id: this.wellnessData.id,
                item_name: this.wellnessData.title,
                affiliation: "Concepto Wellness Store",
                coupon: "",
                currency: this.wellnessData.currency,
                discount: 0,
                index: 0,
                item_brand: "Concepto",
                item_category: this.wellnessData.productCategory.category,
                item_category2: "",
                item_category3: "",
                item_category4: "",
                item_category5: "",
                item_list_id: this.wellnessData.id,
                item_list_name: this.wellnessData.title,
                item_variant: "",
                location_id: "",
                price: this.wellnessData.price,
                quantity: 1
              }
            ]
          }
        });
      }
    }, 1000);
  }

  increaseCartItem(){
    if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
      this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details'))+1, 2)
    } else {
      this.generalService.setCookie('coc_cart_details', 0, 2)
    }
  }

  buyNow(type) {
    // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Added to Cart' });
    let obj = {};
    let existing_user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (existing_user) {
      obj = {
        id: 0,
        product_id: type.id,
        quantity: 1,
        sub_total: type.price,
        stage: 'added to cart',
        created_at: new Date(),
        temp_user_id: '',
        user_id: existing_user,
      }
    } else if (!existing_user && temp_user) {
      obj = {
        id: 0,
        product_id: type.id,
        quantity: 1,
        sub_total: type.price,
        stage: 'added to cart',
        created_at: new Date(),
        temp_user_id: temp_user,
        user_id: '',
      }
    } else {
      let temp_id = this.randomString(16, this.char);
      this.generalService.setCookie('coc_temp_user', temp_id, 2)
      obj = {
        id: 0,
        product_id: type.id,
        quantity: 1,
        sub_total: type.price,
        stage: 'added to cart',
        created_at: new Date(),
        temp_user_id: temp_id,
        user_id: '',
      }
    }
    if (AppConstants.GA4 === true) {
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_id: type.id,
              item_name: type.title,
              affiliation: "Concepto Wellness Store",
              coupon: "",
              currency: type.currency,
              discount: 0,
              index: 0,
              item_brand: "Concepto",
              item_category: type.productCategory.category,
              item_category2: "",
              item_category3: "",
              item_category4: "",
              item_category5: "",
              item_list_id: type.id,
              item_list_name: type.title,
              item_variant: "",
              location_id: "",
              price: type.price,
              quantity: 1
            }
          ]
        }
      });
    }
    this.generalService.addToCart(obj).subscribe((res) => {
      this.generalService.setCookie('coc_active_cart', true, 2);
      // window.location.reload();
      this.showPostCartAdding = true;
      this.nameOfItemAddedToCart = type.name
      this.getCartDetailsByUser()
      if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
        this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) + 1, 2)
      } else {
        this.generalService.setCookie('coc_cart_details', 1, 2)
      }
      this.increaseCartItem()
    })

  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  displaySelectedImage(index) {
    this.show = true;
    this.image = this.wellnessData.images[index];
    this.activeImage = index;
  }

  // checkout(type) {
  //   if (type === 'checkout') {
  //     document.location.href = `${AppConstants.BASE_URL_PROFILE}cart`;
  //   } else if (type === 'continue') {
  //     this.showPostCartAdding = false;
  //     this.nameOfItemAddedToCart = '';
  //   }
  // }

  getdata(){
    this.showPostCartAdding = false;
      }

  getCartDetailsByUser() {
    this.page_loader = true;
    let user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (user) {
      let type = 'email';
      this.generalService.getCartByUser(type, user).subscribe(res => {
        this.cartProducts = res
        let count = 0
        this.cartProducts.forEach(element =>{
          if(element.stage === "added to cart"){
            if(element.product.name === this.wellnessData.title){
              count = count + 1
              this.wellnessData.cart_details = element
              console.log(this.wellnessData, "wellness dsta")
              this.page_loader = false;
            }
          }          
        })
        if(count === 0){
          this.wellnessData.cart_details = false
          this.page_loader = false;
        }
      })
    } else if (temp_user && !user) {
      let type = 'temp';
      this.generalService.getCartByUser(type, temp_user).subscribe(res => {
        this.cartProducts = res
        let count = 0
        this.cartProducts.forEach(element =>{
          if(element.stage === "added to cart"){
            if(element.product.name === this.wellnessData.title){
              count = count + 1
              this.wellnessData.cart_details = element
              console.log(this.wellnessData, "wellness dsta")
              this.page_loader = false;
            } 
          }
        })
        if(count === 0){
          this.wellnessData.cart_details = false
          this.page_loader = false;
        }
      })
    }
  }

  addItem(id) {
    this.cartProducts.forEach(element => {
      if (id === element.id) {
        if (element.quantity > 9) {
          this.messageService.add({ severity: 'error', detail: 'Maximum order limit reached for this item' });
        } else {
          element.quantity = element.quantity + 1
          this.updateCart(element)
        }
      }
    })
  }

  deleteItem(id) {
    this.cartProducts.forEach(element => {
      if (id === element.id && element.quantity > 1) {
        element.quantity = element.quantity - 1
        this.updateCart(element)
      }
    })
  }

  removeItem(product) {
    product.stage = "removed";
    this.updateCart(product)
    this.wellnessData.cart_details = false
    // setTimeout(() => {
    //   if (this.cartProducts.length === 0) {
    //     this.generalService.clearCartCookie()
    //     window.location.reload()
    //   }
    // }, 1000)
  }

  updateCart(element) {
    this.page_loader = true;
    let body = {
      id: element.id,
      product_id: element.product_id,
      user_id: element.user_id,
      quantity: element.quantity,
      sub_total: element.sub_total,
      stage: element.stage,
      temp_user_id: element.temp_user_id,
      created_at: element.created_at
    }
    this.generalService.cartUpdate(body).subscribe(res => {
      this.getCartDetailsByUser()
      this.page_loader = false
    })
  }

  goToCart(){
    this.router.navigateByUrl(`cart`);
  }
}
