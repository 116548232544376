import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from '../app.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-popver',
  templateUrl: './cart-popver.component.html',
  styleUrls: ['./cart-popver.component.scss']
})
export class CartPopverComponent implements OnInit {
  showPostCartAdding = false;
  nameOfItemAddedToCart = ''
  constructor(private router: Router) { }

  @Input() item: boolean
  @Input() item2: string
  @Output() myoutput: EventEmitter<boolean> = new EventEmitter();
  output = false;

  ngOnInit(): void {
  }

  checkout(type) {
    if (type === 'checkout') {
      this.router.navigateByUrl(`cart`);
    } else if (type === 'continue') {
      this.showPostCartAdding = false;
      this.myoutput.emit(this.output);
      this.nameOfItemAddedToCart = '';
    }
  }

  closebtn() {
    this.myoutput.emit(this.output);
  }

}
