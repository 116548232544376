import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../general.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  Scientific_Validation_Studies: any;
  Clinical_Experience_Studies: any;
  VeriSeq_NIPT_Solution_V2_Publications: any;
  Genome_Wide_Publications: any;
  page_loader = false;
  constructor(private generalService: GeneralService,) {
    this.Scientific_Validation_Studies = [
      {
        'name': 'DNA Sequencing versus Standard Prenatal Aneuploidy Screening',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Scientific Validation Studies/DNA Sequencing versus Standard Prenatal Aneuploidy Screening.pdf'
      },
      {
        'name': 'Genome_Wide_Fetal_Aneuploidy_Detection_by_Maternal',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Scientific Validation Studies/Genome_Wide_Fetal_Aneuploidy_Detection_by_Maternal.4.pdf'
      },
      {
        'name': 'Number Variation and False Positive Prenatal Screening Results',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Scientific Validation Studies/Number Variation and False Positive Prenatal Screening Results.pdf'
      }
    ]
    this.Clinical_Experience_Studies = [
      {
        'name': 'Prenatal Diagnosis - 2016 - Taneja - Noninvasive prenatal testing in the general obstetric population  clinical performance',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Clinical Experience Studies/Prenatal Diagnosis - 2016 - Taneja - Noninvasive prenatal testing in the general obstetric population  clinical performance.pdf'
      },
      {
        'name': 'Ultrasound in Obstet   Gyne - 2016 - Fosler - Aneuploidy screening by noninvasive prenatal testing in twin pregnancy',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Clinical Experience Studies/Ultrasound in Obstet   Gyne - 2016 - Fosler - Aneuploidy screening by noninvasive prenatal testing in twin pregnancy.pdf'
      }
    ]
    this.VeriSeq_NIPT_Solution_V2_Publications = [
      {
        'name': 'Analysis of cell-free DNA in a consecutive series of 13,607 routine cases for the detection of fetal chromosomal aneuploidies in a single center in Germany',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/VeriSeq NIPT Solution v2 Publications/Analysis of cell-free DNA in a consecutive series of 13,607 routine cases for the detection of fetal chromosomal aneuploidies in a single center in Germany.pdf'
      },
      {
        'name': 'Performance of a Paired-End Sequencing-Based Noninvasive Prenatal Screening Test in the Detection of Genome-Wide Fetal Chromosomal Anomalies',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/VeriSeq NIPT Solution v2 Publications/Performance of a Paired-End Sequencing-Based Noninvasive Prenatal Screening Test in the Detection of Genome-Wide Fetal Chromosomal Anomalies.pdf'
      }
    ]
    this.Genome_Wide_Publications = [
      {
        'name': 'Clinical experience with noninvasive prenatal testing in Germany Analysis of over 500 high-risk cases for trisomy 21, 18, 13 and monosomy X',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Clinical experience with noninvasive prenatal testing in Germany Analysis of over 500 high-risk cases for trisomy 21, 18, 13 and monosomy X.pdf'
      },
      {
        'name': 'Clinical impact of additional findings detected by genome-wide noninvasive prenatal testing- Follow-up results of the TRIDENT-2 study',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Clinical impact of additional findings detected by genome-wide noninvasive prenatal testing- Follow-up results of the TRIDENT-2 study.pdf'
      },
      {
        'name': 'Genome-wide cell-free DNA screening a focus on copy-number variants',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Genome-wide cell-free DNA screening a focus on copy-number variants.pdf'
      },
      {
        'name': 'Initial Clinical Experience with NIPT for Rare Autosomal Aneuploidies and Large Copy Number Variations',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Initial Clinical Experience with NIPT for Rare Autosomal Aneuploidies and Large Copy Number Variations.pdf'
      },
      {
        'name': 'Origin and clinical relevance of chromosomal aberrations other than the common trisomies detected by genome-wide NIPS- results of the TRIDENT study',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Origin and clinical relevance of chromosomal aberrations other than the common trisomies detected by genome-wide NIPS- results of the TRIDENT study.pdf'
      },
      {
        'name': 'Outcome of publicly funded nationwide first-tier noninvasive prenatal screening',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Outcome of publicly funded nationwide first-tier noninvasive prenatal screening.pdf'
      },
      {
        'name': 'Prenatal Diagnosis - 2018 - Scott - Rare autosomal trisomies  Important and not so rare',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Prenatal Diagnosis - 2018 - Scott - Rare autosomal trisomies  Important and not so rare.pdf'
      },
      {
        'name': 'Three years of clinical experience with a genome-wide cfDNA screening test for aneuploidies and copy-number variants',
        'url': 'https://conceptostorage.s3.eu-west-2.amazonaws.com/Concepto-Website-Assets/NIPT-Resources/Genome-Wide Publications/Three years of clinical experience with a genome-wide cfDNA screening test for aneuploidies and copy-number variants.pdf'
      }
    ]
  }

  ngOnInit(): void {
  }

  priviewFromS3(reportLink) {
    this.page_loader = true;
    let Base64;
    let obj = {
      link: reportLink
    }
    this.generalService.getBase64FromS3Link(obj).subscribe((res) => {
      this.page_loader = false;
      Base64 = res['base64']
      let pdfWindow = window.open("")
      pdfWindow.document.write(
        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(Base64) + "'></iframe>"
      )
      //pdfWindow.document.title = `${this.selectedDataInClinicSti[0].fullLegalName ? this.selectedDataInClinicSti[0].fullLegalName.toUpperCase() : this.selectedDataInClinicSti[0].patientName.toUpperCase()}`;
      pdfWindow.document.title = 'Research 1'
    })
  }
}
