import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// declare global {
//   interface Window {
//     Trustpilot: any;
//   }
// }
@Component({
  selector: 'covid-or-flu',
  templateUrl: './covid-or-flu.html',
  styleUrls: ['./covid-or-flu.scss']
})
export class CovidorFluComponent implements OnInit, AfterContentInit {

  test_and_profile: any;
  index_view: any;
  view_more = true;
  searchText: any;
  customerTestArray: any;
  pageTitle = 'Covid or Flu?'
  constructor(private router: Router) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Covid Tests"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)

  }


  ngAfterContentInit() {
    // setTimeout(() => {
    //   const trustboxRef = document.getElementById('trustbox');
    //   if (window.Trustpilot) {
    //     window.Trustpilot.loadFromElement(trustboxRef);
    //   }
    // }, 500)
  }

  bookNow(data) {
    if(data === 'antigen-5'){
      this.router.navigateByUrl('/profile?test=5-X-COVID-19-Antigen-Self-Test-(Pack-of-5-kits)')
    } else if(data === 'antigen-10'){
      this.router.navigateByUrl('/profile?test=10-X-COVID-19-Antigen-Self-Test-(Pack-of-10-kits)')
    } else if(data === 'flu'){
      this.router.navigateByUrl('/covid-test-filtered?type=flu-tests')
    }
  }
}
