import { Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppConstants } from '../app.constants';
import { GeneralService } from '../general.service';

@Component({
  selector: 'app-wellness-products',
  templateUrl: './wellness-products.component.html',
  styleUrls: ['./wellness-products.component.scss'],
  providers: [MessageService]

})
export class WellnessProductsComponent implements OnInit {

  products = []
  nameOfItemAddedToCart:any;
  char = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  cartProducts: any = [];
  page_loader: boolean = false;
  
  showPostCartAdding = false;
  
  constructor(private router: Router, private generalService: GeneralService, private messageService: MessageService,
    private metaService: Meta, private titleService: Title) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/wellness/products') {
          this.metaService.updateTag({ name: 'description', content: 'Buy the Best Multivitamins for Women and the Best Vitamin Supplement for Men in the UK for a balanced and active  lifestyle. Find the products you need for overall health and wellness!' });
          this.metaService.updateTag({ name: 'keywords', content: 'Best Multivitamins for Women in UK, Best Vitamin Supplement for Men UK' });
          this.titleService.setTitle('Best Vitamin Supplement for Men UK | Multivitamins for Women | Concepto Diagnostics');
        }
      }
    })
    this.getCartDetailsByUser()
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    setTimeout(() => {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      for (let i = 0; i < menuitem.length; i++) {
        if(menuitem[i]["outerText"] === "Wellness"){
          menuitem[i].classList.add("active");
        }
      }
    }, 1000)
    this.getAllProducts();
  }

  learnMore(type) {
    if (type === 'Wellness Queen') {
      this.router.navigate(['wellness/multivitamin'], { queryParams: { multivitamin: 'Wellness-Queen' } });
    }
    if (type === 'Wellness King') {
      this.router.navigate(['wellness/multivitamin'], { queryParams: { multivitamin: 'Wellness-King' } });
    }
    if (type === 'Advanced Immunity') {
      this.router.navigate(['wellness/multivitamin'], { queryParams: { multivitamin: 'Advanced-Immunity' } });
    }
  }

  buyNow(type) {
    // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Added to Cart' });
    let obj = {};
    let existing_user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (existing_user) {
      obj = {
        id: 0,
        product_id: type.id,
        quantity: 1,
        sub_total: type.price,
        stage: 'added to cart',
        created_at: new Date(),
        temp_user_id: '',
        user_id: existing_user,
      }
    } else if (!existing_user && temp_user) {
      obj = {
        id: 0,
        product_id: type.id,
        quantity: 1,
        sub_total: type.price,
        stage: 'added to cart',
        created_at: new Date(),
        temp_user_id: temp_user,
        user_id: '',
      }
    } else {
      let temp_id = this.randomString(16, this.char);
      this.generalService.setCookie('coc_temp_user', temp_id, 2)
      obj = {
        id: 0,
        product_id: type.id,
        quantity: 1,
        sub_total: type.price,
        stage: 'added to cart',
        created_at: new Date(),
        temp_user_id: temp_id,
        user_id: '',
      }
    }
    if (AppConstants.GA4 === true) {
      window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_id: type.id,
              item_name: type.title,
              affiliation: "Concepto Wellness Store",
              coupon: "",
              currency: type.currency,
              discount: 0,
              index: 0,
              item_brand: "Concepto",
              item_category: type.productCategory.category,
              item_category2: "",
              item_category3: "",
              item_category4: "",
              item_category5: "",
              item_list_id: type.id,
              item_list_name: type.title,
              item_variant: "",
              location_id: "",
              price: type.price,
              quantity: 1
            }
          ]
        }
      });
    }
    this.generalService.addToCart(obj).subscribe((res) => {
      this.generalService.setCookie('coc_active_cart', true, 2);
     this.showPostCartAdding = true;
     this.nameOfItemAddedToCart=type.name;
     console.log(this.nameOfItemAddedToCart,"product name")
      // window.location.reload()
      this.getCartDetailsByUser()
      if (this.generalService.getCookie('coc_cart_details') && parseInt(this.generalService.getCookie('coc_cart_details')) > 0) {
        this.generalService.setCookie('coc_cart_details', parseInt(this.generalService.getCookie('coc_cart_details')) + 1, 2)
      } else {
        this.generalService.setCookie('coc_cart_details', 1, 2)
      }
    })

  }

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  getAllProducts() {
    console.log("cart products", this.cartProducts)
    let type = 'Multivitamins'
    this.generalService.getProductsByType(type).subscribe(async (res) => {
      let initRes = res; 
      this.page_loader = false;
      // this.nameOfItemAddedToCart=res.name
      initRes.forEach((element, i) => {
        let arr = []
        if (element.pictures) {
          let pictures_json = JSON.parse(element.pictures)
          pictures_json.image.forEach(element1 => {
            let value = element1.split(':')[1]
            let key = element1.split(':')[0]
            let obj = { [key]: value }
            arr.push(obj)
          });
        }
        element.pictures_json = arr;
        if (i === initRes.length - 1) {
          this.products = initRes
         
        }

        this.cartProducts.forEach(el => {
          if(el.stage === "added to cart"){
            if(el.product.name === element.name){
              element["cart_details"] = el;
            }
          }
        });
      });
      console.log("final products", this.products)
    })
  }

  getCartDetailsByUser() {
    this.page_loader = true;
    let user = this.generalService.getCookie('coc_user')
    let temp_user = this.generalService.getCookie('coc_temp_user')
    if (user) {
      let type = 'email';
      this.generalService.getCartByUser(type, user).subscribe(res => {
        this.cartProducts = res
        this.getAllProducts();
      })
    } else if (temp_user && !user) {
      let type = 'temp';
      this.generalService.getCartByUser(type, temp_user).subscribe(res => {
        this.cartProducts = res
        this.getAllProducts();
      })
    }
  }

  // addItem(id) {
  //   this.cartProducts.forEach(element => {
  //     if (id === element.id) {
  //       if (element.quantity > 9) {
  //         this.messageService.add({ severity: 'error', detail: 'Maximum order limit reached for this item' });
  //       } else {
  //         element.quantity = element.quantity + 1
  //         this.updateCart(element)
  //       }
  //     }
  //   })
  // }

  // deleteItem(id) {
  //   this.cartProducts.forEach(element => {
  //     if (id === element.id && element.quantity > 1) {
  //       element.quantity = element.quantity - 1
  //       this.updateCart(element)
  //     }
  //   })
  // }

  // removeItem(product) {
  //   product.stage = "removed";
  //   this.updateCart(product)
    
  // }

  updateCart(element) {
    this.page_loader = true;
    let body = {
      id: element.id,
      product_id: element.product_id,
      user_id: element.user_id,
      quantity: element.quantity,
      sub_total: element.sub_total,
      stage: element.stage,
      temp_user_id: element.temp_user_id,
      created_at: element.created_at
    }
    this.generalService.cartUpdate(body).subscribe(res => {
      this.getCartDetailsByUser()
      this.page_loader = false
    })
  }

  getdata(){
    this.showPostCartAdding = false;
      }

}
